import { Text } from "@chakra-ui/react";

export const findFirstName = (firebaseUserId, organizationMembers, user) => {
  if (firebaseUserId) {
    const memberData = organizationMembers.find(
      (member) => member.firebaseUserId === firebaseUserId
    );
    if (memberData?.id === user?.id) {
      return <Text fontWeight={600}>Me</Text>;
    } else {
      return <Text>{memberData?.firstName?.toUpperCase()}</Text>;
    }
  }
  return "";
};

export const findName = (firebaseUserId, members) => {
  if (members && !Array.isArray(members)) {
    members = [members];
  }

  if (firebaseUserId) {
    const memberData = members.find(
      (member) => member.firebaseUserId === firebaseUserId
    );

    if (memberData?.firebaseUserId) {
      const name = memberData?.firstName + " " + memberData?.lastName;
      return name?.toUpperCase();
    }
  }
  return "";
};

export const findProjectName = (projectId, projects) => {
  if (projectId) {
    const projectData = projects.find((project) => project.id === projectId);

    return projectData?.projectName;
  }
  return "";
};

export const isSelected = (item, selectedItems) => {
  let isPresent = selectedItems?.find(
    (selectedItem) => selectedItem?.id === item?.id
  );
  return isPresent;
};
