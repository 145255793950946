import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import { UserState } from "../Context/UserProvider";
import { AuthState } from "../Context/AuthProvider";
import { LayoutState } from "../Context/LayoutProvider";
import Header from "../Components/DashboardComponents/Header";
import SideBar from "../Components/DashboardComponents/SideBar";
import Main from "../Components/OrganizationPageComponents/Main";

const Organization = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { organizations, selectedOrganization, selectOrganization } =
    UserState();
  const { user } = AuthState();
  const { screenWidth } = LayoutState();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [viewingOrganization, setViewingOrganization] = useState(null);
  const [isVerified, setIsVerified] = useState(true);

  useEffect(() => {
    if (orgId !== selectedOrganization?.id) {
      selectOrganization(orgId);
    }

    if (
      organizations &&
      Array.isArray(organizations) &&
      organizations.length > 0
    ) {
      const currentOrg = organizations.find((org) => org.id === orgId);
      if (currentOrg) {
        setViewingOrganization(currentOrg);
        setIsVerified(currentOrg.isVerified);
      } else {
        toast({
          title: "Unauthorized",
          description: `Only the organization admin can access this section.`,
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        navigate("/dashboard");
        return;
      }

      if (user?.orgs[currentOrg.id] !== "Admin") {
        toast({
          title: "Unauthorized",
          description: `Only the organization admin can access this section.`,
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        navigate("/dashboard");
        return;
      }
    }
  }, [selectedOrganization, orgId]);

  return (
    <Box
      style={{ all: "unset" }}
      position={"fixed"}
      top={0}
      left={0}
      width={"100vw"}
    >
      {organizations && selectedOrganization && (
        <Box
          display="flex"
          maxWidth={"100vw"}
          minHeight={"100vh"}
          width={"100vw"}
          flexDirection={"column"}
        >
          <Header isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          <Box
            className="post-header"
            maxHeight={`100vh`}
            overflow={"none"}
            flex={1}
            margin={0}
            padding={0}
            display={"flex"}
            flexDirection={"row"}
          >
            <SideBar onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
            <Main
              isOpen={isOpen}
              onClose={onClose}
              viewingOrganization={viewingOrganization}
              setViewingOrganization={setViewingOrganization}
              isVerified={isVerified}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Organization;
