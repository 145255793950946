import React from "react";
import MetricSection from "../Components/Miscellaneous/Metrics/MetricSection";

const Metrics = () => {
  return (
    <>
      <MetricSection />
    </>
  );
};

export default Metrics;
