import { Box, Button, Heading, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthState } from "../Context/AuthProvider";
import { sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  const timeIntervalBetweenResend = 30;
  const { auth, user } = AuthState();
  const [timeRemaining, setTimeRemaining] = useState(timeIntervalBetweenResend);
  const toast = useToast();
  const navigate = useNavigate();

  const resendClicked = () => {
    const sendEmailVerificationLink = async () => {
      try {
        await sendEmailVerification(auth.currentUser);
        toast({
          title: `Verification link sent.`,
          description: `Verification link has been sent to your email.`,
          status: "success",
          duration: 3800,
          isClosable: true,
        });
        setTimeRemaining(timeIntervalBetweenResend);
      } catch (error) {
        // console.log(error);
        setTimeRemaining(0);
        toast({
          title: `An error occured.`,
          description: `Try again.`,
          status: "error",
          duration: 3800,
          isClosable: true,
        });
      }
    };

    if (timeRemaining <= 0) {
      sendEmailVerificationLink();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      setTimeRemaining((time) => time - 1);
      await auth.currentUser.reload();
      if (auth?.currentUser?.emailVerified) {
        navigate("/dashboard");
        toast({
          title: `Verification Successful.`,
          status: "success",
          duration: 3800,
          isClosable: true,
        });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      height={"100vh"}
      width={"100vw"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box minHeight={"300px"} width={"100%"} margin={4}>
        <Heading>Account Verification Needed.</Heading>
        <Box marginLeft={3} padding={3} marginTop={5}>
          <Text>
            A verification email have been sent to your email account
            {user ? `: ${user.email}` : "."}
          </Text>
          <Box display={"flex"} justifyContent={"start"} marginTop={3} gap={5}>
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => {
                window.location.href = "https://mail.google.com";
              }}
            >
              Open Gmail
            </Button>
            <Button
              onClick={resendClicked}
              size={"sm"}
              isDisabled={timeRemaining > 0}
              colorScheme="green"
            >
              Resend email {timeRemaining > 0 ? `in ${timeRemaining}` : ""}
            </Button>
          </Box>
          {/* <Box>
            <Text fontSize={"14px"} color={"gray"} marginTop={4}>
              Refresh the page if you have completed verification.
            </Text>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Verification;
