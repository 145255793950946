import React, { useEffect, useRef, useState, useCallback, act } from "react";
import {
  Box,
  Button,
  Flex,
  useDisclosure,
  useToast,
  Text,
  Icon,
  VStack,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import { motion, AnimatePresence } from "framer-motion";
import { UserState } from "../../Context/UserProvider";
import { LayoutState } from "../../Context/LayoutProvider";
import CreateSprintModal from "./Sprints/CreateSprintModal";
import SprintBox from "./Sprints/SprintBox";

export default function Backlog({ viewingProject }) {
  const { getSprints, getAllSprints, updateTaskSprint, createNewSprint } =
    UserState();
  const { isPhone, screenWidth } = LayoutState();
  const {
    isOpen: isCreateSprintModalOpen,
    onOpen: openCreateSprintModal,
    onClose: closeCreateSprintModal,
  } = useDisclosure();
  const [activeSprint, setActiveSprint] = useState(null);
  const [backlogSprint, setBacklogSprint] = useState(null);
  const [nextSprints, setNextSprints] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState({
    activeSprint: false,
    backlogSprint: false,
    nextSprints: false,
  });
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isFetching, setIsFetching] = useState(false);
  const [filteredSprints, setFilteredSprints] = useState([]);

  const scrollContainerRef = useRef(null);
  const autoScrollingRef = useRef(false);
  const scrollSpeedRef = useRef(0);
  const lastScrollTopRef = useRef(0);
  const toast = useToast();

  const bgColor = useColorModeValue("#f0e6f6", "#2a1e2f");
  const headingColor = useColorModeValue("#4a2a5a", "#d4b8e8");
  const buttonBgColor = useColorModeValue("#a67db7", "#8a63a8");
  const buttonHoverBgColor = useColorModeValue("#956ba6", "#7b5696");
  const taskBg = useColorModeValue("white", "gray.700");

  const createSprint = async (name = "", type = "next") => {
    const sprintTypes = {
      next: "nextSprints",
      backlog: "backlogSprint",
      active: "activeSprint",
    };
    const currentSprint = sprintTypes[type];
    setLoading((obj) => ({ ...obj, [currentSprint]: true }));
    await createNewSprint(viewingProject?.id, name, type);
    setLoading((obj) => ({ ...obj, [type]: false }));
  };

  const fetchSprintsData = async () => {
    setLoading({ activeSprint: true, backlogSprint: true, nextSprints: true });

    let allSprintIds = [];

    viewingProject.sprints.activeSprint &&
      viewingProject.sprints.activeSprint !== "None" &&
      allSprintIds.push(viewingProject.sprints.activeSprint);

    viewingProject.sprints.backlogSprint &&
      viewingProject.sprints.backlogSprint !== "None" &&
      allSprintIds.push(viewingProject.sprints.backlogSprint);

    viewingProject.sprints.nextSprints?.length > 0 &&
      allSprintIds.push(...viewingProject.sprints.nextSprints);

    try {
      if (allSprintIds.length === 0) {
        toast({
          title: "No Sprints found.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      const sprintsData = await getSprints(viewingProject.id, allSprintIds);

      if (sprintsData) {
        return sprintsData;
      } else {
        throw new Error("Error fetching sprints");
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Error fetching sprints",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading({
        activeSprint: false,
        backlogSprint: false,
        nextSprints: false,
      });
    }
  };

  const autoScroll = useCallback(() => {
    if (autoScrollingRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      container.scrollTop += scrollSpeedRef.current;

      if (container.scrollTop !== lastScrollTopRef.current) {
        lastScrollTopRef.current = container.scrollTop;
        requestAnimationFrame(autoScroll);
      } else {
        autoScrollingRef.current = false;
      }
    }
  }, []);

  const startAutoScroll = useCallback(
    (speed) => {
      if (!autoScrollingRef.current) {
        autoScrollingRef.current = true;
        scrollSpeedRef.current = speed;
        requestAnimationFrame(autoScroll);
      } else {
        scrollSpeedRef.current = speed;
      }
    },
    [autoScroll]
  );

  const stopAutoScroll = useCallback(() => {
    autoScrollingRef.current = false;
  }, []);

  const onDragUpdate = useCallback(
    (update) => {
      const { destination, draggableId } = update;
      if (!destination) return;

      const container = scrollContainerRef.current;
      const containerRect = container.getBoundingClientRect();
      const scrollSensitivity = 100;
      const maxScrollSpeed = 15;

      const draggable = document.querySelector(
        `[data-rbd-draggable-id="${draggableId}"]`
      );

      if (!draggable) return;

      const draggableRect = draggable.getBoundingClientRect();

      const hoveringOverElements = document.elementsFromPoint(
        draggableRect.x + draggableRect.width / 2,
        draggableRect.y + draggableRect.height / 2
      );

      const sprintBeingHovered = hoveringOverElements.find((element) => {
        return element && element.classList.contains("sprint-box-main");
      });

      const sprintBeingHoveredId = sprintBeingHovered?.id;

      if (
        sprintBeingHoveredId &&
        sprintBeingHoveredId !== destination.droppableId
      ) {
        destination.droppableId = sprintBeingHoveredId;
      }

      if (draggableRect.top < containerRect.top + scrollSensitivity) {
        const speed = Math.min(
          scrollSensitivity - (draggableRect.top - containerRect.top),
          maxScrollSpeed
        );
        startAutoScroll(-speed);
      } else if (
        draggableRect.bottom >
        containerRect.bottom - scrollSensitivity
      ) {
        const speed = Math.min(
          draggableRect.bottom - (containerRect.bottom - scrollSensitivity),
          maxScrollSpeed
        );
        startAutoScroll(speed);
      } else {
        stopAutoScroll();
      }
    },
    [startAutoScroll, stopAutoScroll]
  );

  const onDragEnd = useCallback(
    async (result) => {
      const { draggableId, source, destination } = result;
      stopAutoScroll();

      if (!destination || !source) return;

      const draggable = document.querySelector(
        `[data-rbd-draggable-id="${draggableId}"]`
      );

      if (!draggable) return;

      const draggableRect = draggable.getBoundingClientRect();

      const hoveringOverElements = document.elementsFromPoint(
        draggableRect.x + draggableRect.width / 2,
        draggableRect.y + draggableRect.height / 2
      );

      const sprintBeingHovered = hoveringOverElements.find((element) => {
        return element && element.classList.contains("sprint-box-main");
      });

      const sprintBeingHoveredId = sprintBeingHovered?.id;

      if (
        sprintBeingHoveredId &&
        sprintBeingHoveredId !== destination.droppableId
      ) {
        destination.droppableId = sprintBeingHoveredId;
      }

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      const getSprintAndIndex = (id) => {
        if (activeSprint?.id === id) return { sprint: activeSprint, index: -2 };
        if (backlogSprint?.id === id)
          return { sprint: backlogSprint, index: -3 };
        const index = nextSprints.findIndex((sprint) => sprint.id === id);
        return { sprint: nextSprints[index], index };
      };

      const { sprint: sourceSprint, index: sourceIndex } = getSprintAndIndex(
        source.droppableId
      );
      const { sprint: destSprint, index: destIndex } = getSprintAndIndex(
        destination.droppableId
      );

      if (!sourceSprint || !destSprint) return;

      const newSourceTasks = Array.from(sourceSprint.tasks);
      const [movedTask] = newSourceTasks.splice(source.index, 1);
      const newDestTasks = Array.from(destSprint.tasks);
      newDestTasks.splice(destination.index, 0, movedTask);

      const updateState = () => {
        if (sourceIndex === destIndex) {
          return;
        }
        if (sourceIndex === -2) {
          setActiveSprint((prev) => ({ ...prev, tasks: newSourceTasks }));
        } else if (sourceIndex === -3) {
          setBacklogSprint((prev) => ({ ...prev, tasks: newSourceTasks }));
        } else {
          setNextSprints((prevSprints) =>
            prevSprints.map((sprint) =>
              sprint.id === sourceSprint.id
                ? { ...sprint, tasks: newSourceTasks }
                : sprint
            )
          );
        }

        if (destIndex === -2) {
          setActiveSprint((prev) => ({ ...prev, tasks: newDestTasks }));
        } else if (destIndex === -3) {
          setBacklogSprint((prev) => ({ ...prev, tasks: newDestTasks }));
        } else {
          setNextSprints((prevSprints) =>
            prevSprints.map((sprint) =>
              sprint.id === destSprint.id
                ? { ...sprint, tasks: newDestTasks }
                : sprint
            )
          );
        }
      };

      updateState();

      if (source.droppableId !== destination.droppableId) {
        try {
          const { status } = await updateTaskSprint(
            viewingProject.id,
            destination.droppableId,
            [movedTask.id]
          );

          if (status !== "success") {
            toast({
              title: "Failed to update task sprint",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          console.error("Error updating task sprint:", error);
          toast({
            title: "Error updating task sprint",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    },
    [
      activeSprint,
      backlogSprint,
      nextSprints,
      updateTaskSprint,
      viewingProject.id,
      toast,
      stopAutoScroll,
    ]
  );

  const renderSprint = (sprint) => {
    if (!sprint) {
      return (
        <Box
          width={"100%"}
          height={"300px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text>Sprint Not Found.</Text>
        </Box>
      );
    }

    console.log(sprint);
    return (
      <Droppable droppableId={sprint.id} key={sprint.id}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="sprint-box-main"
            id={sprint.id}
          >
            <SprintBox
              viewingProject={viewingProject}
              isActive={sprint?.isActive}
              isAnySprintActive={activeSprint?.id ? true : false}
              sprint={sprint}
              loading={
                sprint?.id === backlogSprint?.id ? loading.backlogSprint : false
              }
              isBacklog={sprint?.id === backlogSprint?.id}
              setSprint={
                sprint?.isActive
                  ? setActiveSprint
                  : sprint?.id === backlogSprint?.id
                  ? setBacklogSprint
                  : setNextSprints
              }
            >
              <AnimatePresence>
                {sprint.tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided, snapshot) => (
                      <motion.div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.2 }}
                        style={{
                          ...provided.draggableProps.style,
                          transform: snapshot.isDragging
                            ? provided.draggableProps.style.transform
                            : "none",
                        }}
                      >
                        <Box
                          p={2}
                          bg={taskBg}
                          boxShadow="md"
                          borderRadius="md"
                          mb={2}
                        >
                          {task.title}
                        </Box>
                      </motion.div>
                    )}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder}
            </SprintBox>
          </Box>
        )}
      </Droppable>
    );
  };

  const filterSprints = () => {
    if (!searchQuery) return nextSprints;

    let filteredSprintsData = [];

    if (!isFetching && searchQuery && debouncedSearchQuery) {
      setIsFetching(true);
      getAllSprints(viewingProject?.id, debouncedSearchQuery)
        .then((data) => {
          filteredSprintsData.push(...data);
          console.log(data[0]?.taskIds?.length);

          setFilteredSprints(filteredSprintsData);
          return filteredSprintsData;
        })
        .catch(() => console.log(`No sprints found for ${searchQuery}`))
        .finally(() => {
          setIsFetching(false);
          setDebouncedSearchQuery("");

          return [];
        });
    }

    // console.log(filteredSprintsData);

    // if (filteredSprintsData.length === 0) {
    //   filteredSprintsData = [null];
    // }

    // return filteredSprintsData;
  };

  useEffect(() => {
    // this is done to prevent fetching data from the db when the user is typing.

    const timer = setTimeout(() => {
      let query = searchQuery?.toLowerCase() || "";
      if (query.startsWith("sprint")) {
        query = query.slice(6).trim();
      }
      setDebouncedSearchQuery(query);
    }, 600);

    return () => clearTimeout(timer); // Cleanup on component unmount or searchQuery change
  }, [searchQuery]);

  useEffect(() => {
    const filteredSrpintsFromQuery = filterSprints();
    console.log(filteredSrpintsFromQuery);
    // setFilteredSprints(filteredSrpintsFromQuery);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setLoading({ activeSprint: true, nextSprints: true, backlogSprint: true });

    fetchSprintsData()
      .then((allSprints) => {
        let activeSprint = null;
        let backlogSprint = null;
        let nextSprintsArray = [];

        if (!allSprints) {
          throw new Error("allSprints is empty");
        }
        allSprints?.forEach((spr) => {
          if (
            (spr.isActive === "true" || spr.isActive === true) &&
            spr.name !== "Backlog" &&
            spr.num !== 0 &&
            spr.createdAt !== viewingProject.createdAt
          ) {
            activeSprint = spr;
          } else if (spr.name === "Backlog") {
            backlogSprint = spr;
          } else {
            nextSprintsArray.push(spr);
          }
        });

        setNextSprints(nextSprintsArray);
        setActiveSprint(activeSprint);
        setBacklogSprint(backlogSprint);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading({
          activeSprint: false,
          nextSprints: false,
          backlogSprint: false,
        });

        if (viewingProject?.sprints?.backlogSprint === "None") {
          (async () => {
            try {
              const result = await createSprint("Backlog", "backlog");
              if (result.status === "success") {
                setBacklogSprint(result.data);
              }
            } catch (error) {
              console.error(error);
            }
          })();
        }
      });
  }, [viewingProject]);

  return (
    <Box height="100%" display="flex" flexDir="column" bg={"inherit"}>
      {isCreateSprintModalOpen && (
        <CreateSprintModal
          projectId={viewingProject.id}
          isOpen={isCreateSprintModalOpen}
          onClose={closeCreateSprintModal}
        />
      )}
      <Flex
        align="center"
        justifyContent={"space-between"}
        mx={4}
        mb={2}
        gap={3}
      >
        <InputGroup width={"50%"}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search Sprints"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </InputGroup>
        <Button
          leftIcon={<AddIcon />}
          onClick={openCreateSprintModal}
          size="sm"
          colorScheme="purple"
          minWidth={"130px"}
        >
          Create Sprint
        </Button>
      </Flex>
      <Box
        height="100%"
        overflowY="auto"
        mb={2}
        flex={1}
        ref={scrollContainerRef}
        px={2}
      >
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <VStack spacing={4} align="stretch">
            {!searchQuery && activeSprint && renderSprint(activeSprint)}
            {!loading.nextSprints && !isFetching ? (
              !searchQuery ? (
                nextSprints.map((sprint) => renderSprint(sprint))
              ) : filteredSprints &&
                Array.isArray(filteredSprints) &&
                filteredSprints.length > 0 ? (
                filteredSprints.map((sprint) => renderSprint(sprint))
              ) : (
                <Box
                  width={"100%"}
                  height={"300px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  No Sprints Matched Your Search.
                </Box>
              )
            ) : (
              <Box
                width={"100%"}
                height={"300px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Spinner />
              </Box>
            )}
            {!searchQuery && backlogSprint && renderSprint(backlogSprint)}
            <Box height={"30px"} />
          </VStack>
        </DragDropContext>
      </Box>
    </Box>
  );
}
