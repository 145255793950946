import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthState } from "../../../Context/AuthProvider";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { LayoutState } from "../../../Context/LayoutProvider";

const CurrentBandwidth = () => {
  const { user } = AuthState();
  const { screenWidth } = LayoutState();
  const [currentBandwidth, setCurrentBandwidth] = useState(40);
  const [currentBandwidthPathColor, setCurrentBandwidthPathColor] =
    useState("blue");
  const [weeklyHours, setWeeklyHours] = useState(40);

  useEffect(() => {
    if (user?.currentBandwidth >= 0) {
      setCurrentBandwidth(user?.currentBandwidth);
      setWeeklyHours(user?.weeklyHours);

      if (user.currentBandwidth < 5) {
        setCurrentBandwidthPathColor("red");
      } else if (user.currentBandwidth < 15) {
        setCurrentBandwidthPathColor("green");
      } else {
        setCurrentBandwidthPathColor("blue");
      }

      return;
    }
    setCurrentBandwidth(0);
  }, [user]);

  return (
    <Card borderRadius={"25px"} boxShadow={"none"}>
      <CardHeader>
        <Heading fontSize={"17px"}>Current Bandwidth</Heading>
      </CardHeader>

      <CardBody
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          width={
            screenWidth < 470
              ? screenWidth < 350
                ? "100px"
                : "150px"
              : "200px"
          }
          height={
            screenWidth < 470 ? (screenWidth < 350 ? "50px" : "75px") : "100px"
          }
        >
          {currentBandwidth === 0 ? (
            <Box
              color={"red"}
              height={"100%"}
              width={"100%"}
              fontWeight={800}
              display={"flex"}
              flexDir={"column"}
              alignItems={"space-between"}
              textAlign={"center"}
            >
              <Text>0 hours</Text>
              <Text>No bandwidth available</Text>
            </Box>
          ) : (
            <CircularProgressbar
              maxValue={weeklyHours}
              value={currentBandwidth}
              text={`${currentBandwidth} hours`}
              strokeWidth={8}
              circleRatio={0.5}
              styles={buildStyles({
                width: "10px",
                rotation: 0.75,
                pathColor: currentBandwidthPathColor,
                textColor: "black",
                textSize: "12px",
              })}
            />
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export default CurrentBandwidth;
