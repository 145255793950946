import {
  Avatar,
  Box,
  Button,
  Image,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { IoMdDocument } from "react-icons/io";
import ExpandedCommentView from "./ExpandedCommentView";

const AllTaskComments = ({ setTask, task }) => {
  const { user } = AuthState();
  const {
    taskComments,
    deleteTaskComment,
    updateTaskComment,
    loadMoreTaskComments,
  } = UserState();
  const [editingComment, setEditingComment] = useState(null);
  const [updatedCommentContent, setUpdatedCommentContent] = useState("");
  const [expandedComment, setExpandedComment] = useState(null);

  const handleCommentExpansion = (e) => {
    const commentId = e.target.id;
    const commentToExpand = taskComments.find(
      (comment) => comment.id === commentId
    );
    if (commentToExpand?.id) {
      setExpandedComment(commentToExpand);
    } else {
      setExpandedComment(null);
    }
  };

  const handleCloseExpanded = () => {
    setExpandedComment(null);
  };

  const handleCommentDelete = async (e, comment) => {
    try {
      await deleteTaskComment(comment.id);
      setTask((viewingTask) => ({
        ...viewingTask,
        commentsCount: viewingTask.commentsCount - 1,
      }));
    } catch (error) {
      // console.log(error);
    }
  };

  const handleCommentEditClicked = async (e, comment) => {
    if (!editingComment) {
      setUpdatedCommentContent(comment.content);
      setEditingComment(comment.id);
      return;
    }

    if (updatedCommentContent === "") {
      await handleCommentDelete(e, comment);
    } else {
      if (updatedCommentContent !== comment.content) {
        try {
          await updateTaskComment(comment.id, updatedCommentContent);
        } catch (error) {
          // console.log(error);
        }
      }
      setUpdatedCommentContent("");
    }
    setEditingComment(null);
  };

  useEffect(() => {
    if (
      taskComments &&
      Array.isArray(taskComments) &&
      taskComments.length > 0
    ) {
      // update task comments that have attachments with proper url
    }
  }, []);

  return (
    <Box
      marginTop={1}
      paddingTop={0}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      paddingY={2}
      height={"100%"}
      flex={1}
      // minHeight={"300px"}
      // maxHeight={"300px"}
    >
      <Box
        marginTop={0}
        paddingTop={0}
        display={"flex"}
        flexDir={"column"}
        flex={1}
      >
        {!taskComments ||
        (Array.isArray(taskComments) && taskComments.length <= 0) ? (
          "No comments under this task."
        ) : (
          <VStack spacing={1} overflow={"scroll"} alignItems={"start"}>
            {taskComments.map((comment) => {
              if (comment.authorId === user.firebaseUserId) {
                comment.author = user;
              }

              return (
                <Box key={comment.id} display={"flex"} alignItems={"center"}>
                  <Avatar
                    src={comment.author?.imageUrl}
                    name={
                      comment.author && comment.author.firstName
                        ? comment.author.firstName +
                          " " +
                          comment.author.lastName
                        : ""
                    }
                    size={"sm"}
                    marginRight={1}
                  />
                  <Box
                    bgColor={"#d0b9b9"}
                    color={"black"}
                    padding={2}
                    margin={0}
                    borderRadius={"10px"}
                  >
                    <Text fontSize={"10px"} fontWeight={"700"}>
                      {comment.author && comment.author.firstName
                        ? comment.author.firstName +
                          " " +
                          comment.author.lastName
                        : ""}
                    </Text>
                    {editingComment === comment.id ? (
                      <Input
                        type="text"
                        value={updatedCommentContent}
                        onChange={(e) =>
                          setUpdatedCommentContent(e.target.value)
                        }
                      />
                    ) : (
                      <Text>{comment.content}</Text>
                    )}
                    {comment.attachment !== "" ? (
                      !comment.attachment.includes(".pdf") ? (
                        <Image
                          id={comment.id}
                          src={`${comment.attachment}`}
                          onClick={handleCommentExpansion}
                          maxHeight={"50px"}
                          _hover={{ cursor: "pointer" }}
                          alt={`${comment.attachment
                            .substring(
                              comment.attachment.indexOf("_"),
                              comment.attachment.indexOf("?")
                            )
                            .substring(0, 10)}...`}
                        />
                      ) : (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          _hover={{ cursor: "pointer" }}
                        >
                          <IoMdDocument />
                          <Link href={`${comment.attachment}`}>
                            {comment.attachment
                              .substring(
                                comment.attachment.indexOf("_") + 1,
                                comment.attachment.indexOf("?")
                              )
                              .substring(0, 10)}
                            ...
                          </Link>
                        </Box>
                      )
                    ) : (
                      <></>
                    )}
                    {comment.authorId === user.firebaseUserId && (
                      <Box fontSize={"10px"}>
                        <Button
                          padding={1}
                          paddingBottom={0}
                          size={"xxs"}
                          bgColor={"inherit"}
                          marginRight={"2"}
                          _hover={{ color: "blue", bgColor: "inherit" }}
                          onClick={(e) => handleCommentEditClicked(e, comment)}
                        >
                          {editingComment === comment.id ? "Save" : "Edit"}
                        </Button>
                        <Button
                          padding={1}
                          paddingBottom={0}
                          paddingLeft={0}
                          size={"xxs"}
                          bgColor={"inherit"}
                          _hover={{ color: "red", bgColor: "inherit" }}
                          onClick={(e) => handleCommentDelete(e, comment)}
                          display={editingComment ? "none" : "auto"}
                        >
                          Delete
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </VStack>
        )}
      </Box>
      {taskComments &&
        Array.isArray(taskComments) &&
        task &&
        taskComments.length < task.commentsCount && (
          <Box
            color={"blue"}
            fontSize={"12px"}
            fontWeight={"700"}
            marginTop={2}
            onClick={loadMoreTaskComments}
            _hover={{ cursor: "pointer" }}
          >
            Load ({task.commentsCount - taskComments.length}) more comments...
          </Box>
        )}
      {expandedComment && (
        <ExpandedCommentView
          isOpen={!!expandedComment}
          onClose={handleCloseExpanded}
          comment={expandedComment}
        />
      )}
    </Box>
  );
};

export default AllTaskComments;
