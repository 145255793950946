import React, { useEffect, useState } from "react";
import SignupForm from "../Components/Authentication/SignUpForm";
import SigninForm from "../Components/Authentication/SignInForm";

import { useLocation, useNavigate } from "react-router-dom";

const Auth = () => {
  const location = useLocation();
  const [page, setPage] = useState("signin"); // accepts only 3 valid values: "signin", "signup", "dashboard"

  useEffect(() => {
    const component = location.state?.component || "signin";

    setPage(component);
  }, []);

  return (
    <>
      {page === "signin" && <SigninForm setPage={setPage} />}
      {page === "signup" && <SignupForm setPage={setPage} />}
    </>
  );
};

export default Auth;
