import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthState } from "../../../Context/AuthProvider";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";

const TasksSuccessful = () => {
  const { user } = AuthState();
  const [tasksSuccessful, setTasksSuccessful] = useState(100);

  useEffect(() => {
    if (user?.tasksSuccessful >= 0) {
      setTasksSuccessful(user?.tasksSuccessful);
      return;
    }
    setTasksSuccessful(0);
  }, [user]);

  return (
    <Card borderRadius={"25px"} boxShadow={"none"}>
      <CardHeader>
        <Heading size="md">Tasks Successful</Heading>
      </CardHeader>

      <CardBody
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box flex={1}>
          <Text fontSize={"40px"} fontWeight={700}>
            {tasksSuccessful}
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default TasksSuccessful;
