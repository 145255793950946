import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ProjectDropDown from "../Miscellaneous/Dropdowns/ProjectDropDown";
import AssigneeDropDown from "../Miscellaneous/Dropdowns/AssigneeDropDown";

const UserMessage = ({
  message,
  clickedQuery,
  setClickedQuery,
  loading,
  setLoading,
  setConversation,
  conversation,
  context,
  onCancel,
}) => {
  const [requiredFields, setRequiredFields] = useState([]);
  const [inputsNeeded, setInputsNeeded] = useState([]);
  const [currFieldIdx, setCurrFieldIdx] = useState(0);
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const shouldSubmitRef = useRef(true);

  const handleSubmitQuery = async () => {
    try {
      // if (Object.values(fields).some((field) => !field)) {
      //   setErrors(
      //     Object.fromEntries(
      //       Object.entries(fields).map(([key, value]) => [
      //         key,
      //         value ? "" : "Need to fill this field",
      //       ])
      //     )
      //   );
      //   return;
      // }

      setLoading(true);

      const lastMessage = conversation[conversation.length - 1];
      setConversation((prevConversation) =>
        lastMessage && lastMessage.type === "user"
          ? [
              ...prevConversation.slice(0, prevConversation.length - 1),
              { type: "user", text: clickedQuery?.value },
            ]
          : [...prevConversation, { type: "user", text: clickedQuery?.value }]
      );

      // api call here
      const generatedContent = await clickedQuery?.func(fields);

      setClickedQuery(null);
      setIsResponseReceived(true);

      setConversation((convo) => [
        ...(convo || []),
        { type: "assistant", text: generatedContent },
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateCurrentIdx = (direction) => {
    if (currFieldIdx === inputsNeeded.length - 1 && direction === 1) {
      return;
    } else if (currFieldIdx === 0 && direction === -1) {
      return;
    } else {
      setCurrFieldIdx((prevIdx) => (prevIdx + direction) % inputsNeeded.length);
    }
  };

  const updateFieldData = (value) => {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputsNeeded[currFieldIdx]]: "Need to fill this field.",
      }));
      return;
    }

    if (value !== fields[inputsNeeded[currFieldIdx]]) {
      setFields((prevFields) => ({
        ...prevFields,
        [inputsNeeded[currFieldIdx]]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [inputsNeeded[currFieldIdx]]: "",
    }));
  };

  // const verifyAndUpdateFieldIdx = (direction) => {
  //   if (fields[currFieldIdx]) {
  //     updateCurrentIdx(direction);
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [inputsNeeded[currFieldIdx]]: "Need to fill this field.",
  //     }));
  //   }
  // };

  useEffect(() => {
    if (
      shouldSubmitRef.current &&
      requiredFields.length > 0 &&
      inputsNeeded.length === 0
    ) {
      shouldSubmitRef.current = false;
      handleSubmitQuery();
    }
  }, [requiredFields, inputsNeeded]);

  useEffect(() => {
    if (clickedQuery) {
      const reqFields = clickedQuery?.requiredFields;
      setRequiredFields(reqFields);
      setInputsNeeded(
        reqFields.filter((field) => !context.hasOwnProperty(field))
      );
      setFields((prevFields) => ({
        ...prevFields,
        ...reqFields.reduce((acc, field) => {
          acc[field] = context?.[field] || "";
          return acc;
        }, {}),
      }));
    }
  }, [clickedQuery, context]);

  return isResponseReceived || inputsNeeded.length === 0 ? (
    <Text>{`${message.text}`}</Text>
  ) : (
    <Box display={"flex"} flexDir={"column"} gap={2}>
      <Heading size={"sm"}>{clickedQuery?.value}</Heading>
      <Text>Enter required details.</Text>
      <FormControl isInvalid={errors[requiredFields[currFieldIdx]]}>
        {inputsNeeded[currFieldIdx] === "projectId" && (
          <ProjectDropDown setProjectId={updateFieldData} />
        )}
        {inputsNeeded[currFieldIdx] === "assigneeId" && (
          <AssigneeDropDown
            projectId={fields.projectId}
            setAssigneeId={updateFieldData}
          />
        )}
        {errors[inputsNeeded[currFieldIdx]] && (
          <FormErrorMessage>
            {errors[inputsNeeded[currFieldIdx]]}
          </FormErrorMessage>
        )}
        <Box display={"flex"} justifyContent={"space-between"} mt={2}>
          {currFieldIdx !== 0 ? (
            <Button onClick={() => updateCurrentIdx(-1)} size={"sm"}>
              Prev
            </Button>
          ) : (
            <Button colorScheme="red" size={"sm"} onClick={onCancel}>
              Cancel
            </Button>
          )}
          {currFieldIdx === inputsNeeded.length - 1 ? (
            <Button
              size={"sm"}
              onClick={() => handleSubmitQuery(message.text, fields)}
              isLoading={loading}
              colorScheme="green"
            >
              Generate
            </Button>
          ) : (
            <Button size={"sm"} onClick={() => updateCurrentIdx(1)}>
              Next
            </Button>
          )}
        </Box>
      </FormControl>
    </Box>
  );
};

export default UserMessage;
