import { ArrowLeftIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, VStack } from "@chakra-ui/react";
import { ChevronUp } from "lucide-react";

export const taskStatusStyle = {
  done: "#4CAF50", // Green for tasks that are completed
  inProgress: "#FFC107", // Orange for tasks that are in progress
  blocked: "#F44336", // Red for tasks that are blocked
  toDo: "#9E9E9E", // Gray for tasks that are on hold
  review: "#2196F3", // Blue for tasks that are under review
};

export const taskStatusStyleLight = {
  done: "#C8E6C9", // Light green for tasks that are completed
  inProgress: "#F9E79F", // Light orange for tasks that are in progress
  blocked: "#FFC2C7", // Light red for tasks that are blocked
  toDo: "#F5F5F5", // Light gray for tasks that are on hold
  review: "#B3E5FC", // Light blue for tasks that are under review
};

export const taskStatusText = {
  done: "Done",
  inProgress: "In Progress",
  blocked: "Blocked",
  toDo: "To Do",
  review: "Review",
};

export const successStatusStyles = {
  success: { bgColor: "green", textColor: "white" },
  failed: { bgColor: "red", textColor: "white" },
  pending: { bgColor: "blue", textColor: "white" },
};

export const priorityStyle = {
  high: "red.500",
  medium: "orange.400",
  low: "green.500",
};

export const priorityStyleLight = {
  high: "pink.100",
  medium: "orange.200",
  low: "green.200",
};
export const successStatusText = {
  success: "Success",
  failed: "Failed",
  pending: "Pending",
};

const PriorityIcon = ({ priority }) => {
  const config = {
    high: { color: "red.500" },
    medium: { color: "orange.400" },
    low: { color: "green.500" },
  };

  const { color } = config[priority];

  return (
    <Box
      borderRadius="md"
      bg={`${color}.200`}
      // bg={"#eeeeec"}
      width="30px"
      height={"100%"}
      maxHeight={"30px"}
      overflow={"hidden"}
    >
      <Box height={"100%"} width={"30px"} borderRadius={"full"}>
        {priority === "high" ? (
          <ArrowLeftIcon
            ml={3}
            color={color}
            height={"100%"}
            padding={0}
            margin={0}
            transform={"rotate(90deg)"}
            bgSize={8}
          />
        ) : (
          <ChevronUpIcon
            color={color}
            boxSize={8}
            height={"100%"}
            padding={0}
            margin={0}
          />
        )}
      </Box>
    </Box>
  );
};

export const taskPriorityStyles = {
  high: <PriorityIcon priority="high" />,
  medium: <PriorityIcon priority="medium" />,
  low: <PriorityIcon priority="low" />,
};
