import { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { ORG_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";

const useOrganizations = () => {
  const {
    accessToken,
    user,
    isReloadingUserMetrics,
    userMetricsReloadComplete,
  } = AuthState();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(0);

  const generateDNSVerificationCode = async (orgId, domain) => {
    try {
      const response = await axios.post(
        `${baseUrl}/generate_dns_verify_code`,
        { orgId, domain },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response?.data?.verification_code) {
        throw new Error("Error in generating domain verification code");
      }
      return response?.data?.verification_code;
    } catch (error) {
      setError("Error in generating DNS verify code");
    }
  };

  const verifyDomainTXTRecord = async (domain, orgId) => {
    try {
      const response = await axios.post(
        `${baseUrl}/verify_domain`,
        { domain, orgId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response?.data?.message) {
        throw new Error("Error in verifying domain TXT record");
      }
      return response?.data?.message;
    } catch (error) {
      console.error("Error in verifying domain TXT record:", error);
      // setError("Error in verifying domain TXT record");
      return error;
    }
  };

  const createOrganization = async (
    isPersonal = false,
    newUserAccessToken = null,
    organizationData = {}
  ) => {
    try {
      if (isPersonal) {
        organizationData = {
          orgName: "No Organization",
          orgDomain: "none",
          members: [],
          isPersonal: true,
        };
      } else {
        if (!organizationData) {
          setError(
            "organizationData is required to create organizations that are not personal."
          );
        }
      }

      const token = accessToken || newUserAccessToken;

      if (!token) {
        setError("access tokens are falsey. cannot create organization.");
      }

      const response = await axios.post(
        `${baseUrl}/create_organization`,
        organizationData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response?.data?.data) {
        throw new Error("Error in creating organization");
      }
      return response?.data?.data;
    } catch (error) {
      if (error?.response?.data?.code === "already_exists") {
        setError(
          "Organization Already Exists. Please try again with a different name and domain."
        );
        return;
      }
      setError(error.message);
      return false;
    }
  };

  const deleteOrganization = async (orgId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.delete(
        `${baseUrl}/delete_organization/${orgId}`,
        config
      );
    } catch (err) {
      // console.log(err);
      setError(err.response.data.error || "Error updating member role");
      throw new Error("Failed to update member role");
    }
  };

  const updateOrganization = async (orgId, name, domain, pmbotName) => {
    try {
      if (!orgId || !name || !domain || !pmbotName) {
        throw new Error(
          "orgId, name, domain, pmmbotName are needed to update organization"
        );
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.put(
        `${baseUrl}/update_organization`,
        { orgId, name, domain, pmbotName },
        config
      );

      return;
    } catch (err) {
      // console.log(err);
      setError(err.response.data.error || "Error updating organization data");
      throw new Error("Failed to update organization data");
    }
  };

  const reload = () => {
    setReloadFlag((prevValue) => prevValue + 1);
  };

  useEffect(() => {
    if (!accessToken) {
      setOrganizations([]);
      if (accessToken === undefined) {
        setError("Cannot fetch user organizations data without access token");
      }
      return;
    }

    if (isReloadingUserMetrics.current) {
      userMetricsReloadComplete();
      // console.log("here");
      if (organizations?.length > 0) {
        return;
      }
    }

    const getUserOrganizationsData = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/fetch_organizations`,
          config
        );

        const organizationsData = response?.data?.data;

        if (
          organizationsData &&
          Array.isArray(organizationsData) &&
          organizationsData.length > 0
        ) {
          // console.log(organizationsData);
          setOrganizations(organizationsData);
        } else {
          try {
            // create no organization
            const noOrg = createOrganization(true);
            reload();
          } catch (error) {
            throw new Error("Organizations Data not found");
          }
        }
      } catch (err) {
        // console.log(err);
        setError("Error while fetching user organizations");
      } finally {
        setLoading(false);
      }
    };

    getUserOrganizationsData();
  }, [user, reloadFlag]);

  return {
    organizations,
    setOrganizations,
    deleteOrganization,
    updateOrganization,
    loading,
    error,
    setError,
    reload,
    createOrganization,
    generateDNSVerificationCode,
    verifyDomainTXTRecord,
  };
};

export default useOrganizations;
