import { DeleteIcon } from "@chakra-ui/icons";
import {
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import OrganizationMemberRoleDropDown from "../../Miscellaneous/Dropdowns/OrganizationMemberRoleDropDown";

const RolesTable = ({
  errorMessage,
  setErrorMessage,
  setNewMembers,
  newMembers,
  elementRef,
  makeRolesTableActive,
  makeNewMembersFormActive,
}) => {
  const [clickedNewMember, setClickedNewMember] = useState(null);

  const removeFromList = (member) => {
    setNewMembers((members) =>
      members.filter((m) => m.firebaseUserId !== member.firebaseUserId)
    );
  };

  return (
    newMembers.length > 0 && (
      <>
        <Heading marginTop={7} marginBottom={3} size={"md"}>
          Manage Roles:
        </Heading>
        <TableContainer
          marginLeft={2}
          ref={elementRef}
          onClick={makeRolesTableActive}
          border={"1px solid black"}
          borderRadius={"15px"}
          overflowY={"scroll"}
        >
          <Table size="sm">
            <Tbody>
              {newMembers.map((member) => (
                <Tr
                  key={member.firebaseUserId}
                  display={"flex"}
                  onClick={() => {
                    setClickedNewMember(member);
                  }}
                >
                  <Td borderRight={"1px solid black"} flex={1}>
                    {member.email}
                  </Td>
                  <Td
                    borderRight={"1px solid black"}
                    padding={0}
                    borderBottom={"1px solid #EDF2F7"}
                  >
                    <OrganizationMemberRoleDropDown
                      member={member}
                      setMembers={setNewMembers}
                      clickedMember={clickedNewMember}
                    />
                  </Td>
                  <Td>
                    <DeleteIcon
                      onClick={() => removeFromList(member)}
                      _hover={{ cursor: "pointer" }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Text>{errorMessage.message4}</Text>
      </>
    )
  );
};

export default RolesTable;
