import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Badge,
} from "@chakra-ui/react";
import React from "react";
import { CheckIcon, SmallCloseIcon } from "@chakra-ui/icons";

const PricingBox = ({
  heading,
  price,
  period,
  description,
  specials,
  buttonText,
  isRecommended = false,
}) => {
  return (
    <Box
      position="relative"
      borderWidth={1}
      borderRadius="lg"
      p={6}
      shadow="md"
      bg="white"
      flex={1}
      minW={["90%", "300px"]}
      maxW="300px"
    >
      {isRecommended && (
        <Badge
          colorScheme="blue"
          position="absolute"
          top="-10px"
          left="50%"
          transform="translateX(-50%)"
        >
          Recommended
        </Badge>
      )}
      <VStack spacing={4} align="stretch">
        <Heading as="h3" size="lg" fontWeight="500">
          {heading}
        </Heading>
        <Flex align="baseline" justify="center">
          <Text fontSize="4xl" fontWeight="bold">
            {price}
          </Text>
          {period && (
            <Text fontSize="xl" fontWeight="medium" color="gray.500">
              /{period}
            </Text>
          )}
        </Flex>
        <Text fontSize="sm" color="gray.500" textAlign="center">
          {description}
        </Text>
        <VStack spacing={2} align="stretch">
          {specials &&
            specials.map((point, index) => (
              <Flex key={index} align="center">
                {point.present ? (
                  <CheckIcon color="green.500" mr={2} />
                ) : (
                  <SmallCloseIcon color="red.500" mr={2} />
                )}
                <Text fontSize="sm">{point.value}</Text>
              </Flex>
            ))}
        </VStack>
        <Button
          colorScheme={isRecommended ? "blue" : "gray"}
          variant={isRecommended ? "solid" : "outline"}
          size="lg"
          mt={4}
        >
          {buttonText}
        </Button>
      </VStack>
    </Box>
  );
};

export default PricingBox;
