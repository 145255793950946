"use client";

import { Box, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import { FaSignOutAlt } from "react-icons/fa";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebaseconfig";

const Logout = () => {
  const {
    setSelectedOrganization,
    setOrganizations,
    setProjects,
    setMyTasks,
    setReportTasks,
    setOrganizationMembers,
  } = UserState();
  const toast = useToast();
  const [isHovered, setIsHovered] = useState(false);

  const handleLogoutClick = async () => {
    try {
      await signOut(auth);

      setMyTasks(null);
      setReportTasks(null);
      setProjects(null);
      setOrganizationMembers(null);
      // setSelectedOrganization(null);
      setOrganizations(null);

      toast({
        title: "Logged out successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Logout failed",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="logout-container" px={4} pb={2}>
      <Box
        as="button"
        onClick={handleLogoutClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="full"
        py={3}
        px={4}
        borderRadius="full"
        bg={isHovered ? "red.500" : "gray.100"}
        color={isHovered ? "white" : "gray.800"}
        fontWeight="semibold"
        transition="all 0.3s ease"
        _hover={{ transform: "scale(1.05)" }}
        _active={{ transform: "scale(0.95)" }}
        _focus={{ boxShadow: "outline" }}
        boxShadow={"1px 1px 10px #ececec"}
        border={"2px solid #ececec"}
      >
        <FaSignOutAlt style={{ marginRight: "8px" }} />
        <Text>Logout</Text>
      </Box>
    </Box>
  );
};

export default Logout;
