import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";

const ProjectDropDown = ({
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  setProjectId,
  project,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const { projects } = UserState();
  const [filteredProjects, setFilteredProjects] = useState(null);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const [projectName, setProjectName] = useState("");

  const toast = useToast();

  const handleInputBlur = (e) => {
    // toast({
    //   title: "blurred.",
    //   description: "Select an appropriate project.",
    //   status: "error",
    //   duration: 9000,
    //   isClosable: true,
    // });
    // // console.log("handling input element blur");
    // // console.log(e.target);
    // // console.log(e.relatedTarget);
    // // console.log(popoverRef);
    // // console.log(e.relatedTarget == popoverRef.current);
    if (e.relatedTarget == popoverRef.current) {
      // console.log(e.target);
      return;
    }
    onClose();
  };

  const handleProjectClick = (e, obj) => {
    e?.stopPropagation();
    setProjectId(obj.id);
    setQuery(obj.projectName);
    setProjectName(obj.projectName);
    onClose();
  };

  useEffect(() => {
    if (!projects) {
      return;
    }
    if (projectName && query !== projectName) {
      setProjectId("");
      setProjectName("");
    }
    if (query === "") {
      setFilteredProjects(projects);
    } else {
      const projs = projects.filter((proj) =>
        proj.projectName.toLowerCase().includes(query.toLowerCase())
      );
      if (projs) {
        setFilteredProjects(projs);
      } else {
        setFilteredProjects(null);
      }
    }
  }, [query]);

  useEffect(() => {
    if (project) {
      setProjectId(project.id);
      setProjectName(project.projectName);
    }
  }, []);

  return (
    <Popover onOpen={onOpen} isOpen={isOpen} initialFocusRef={inputRef}>
      <PopoverTrigger>
        <Button w={"full"} bg={"gray.100"} _hover={{ opacity: 0.8 }} p={0}>
          <Input
            isDisabled={project ? true : false}
            value={isOpen ? query : projectName}
            placeholder="Project"
            color={project ? "blue" : "black"}
            fontWeight={project ? 600 : "auto"}
            onFocus={project ? () => {} : onOpen}
            onBlur={project ? () => {} : handleInputBlur}
            onChange={
              project
                ? () => {}
                : (e) => {
                    setQuery(e.target.value);
                    if (!isOpen && query) {
                      onOpen();
                    }
                  }
            }
            ref={inputRef}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        width={"100%"}
        border={"1px solid #ececec"}
        boxShadow={"0px 0px 10px 3px rgba(0, 0, 0, 0.2)"}
      >
        <PopoverBody maxHeight={"250px"} width="220px" overflow={"scroll"}>
          <div id="filteredprojectswrapper" ref={popoverRef}>
            {filteredProjects?.length > 0 ? (
              filteredProjects.map((proj) => (
                <Box
                  key={proj.id}
                  marginY={0}
                  borderRadius={"15px"}
                  _hover={{ cursor: "pointer" }}
                  width={"100%"}
                  padding={3}
                  textAlign={"left"}
                  paddingY={1}
                  onClick={(e) => handleProjectClick(e, proj)}
                >
                  {proj.projectName}
                </Box>
              ))
            ) : (
              <Text color={"black"}>
                {projects && projects.length > 0
                  ? "No such project exists."
                  : "No projects under this organization."}
              </Text>
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ProjectDropDown;
