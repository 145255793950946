import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";

const QueryList = ({ queries, onQueryClick }) => {
  return (
    <Box overflowX="auto" width="100%">
      <Flex spacing={2} align="stretch" minWidth="max-content">
        {queries.map((query) => (
          <Button
            key={query.id}
            onClick={() => onQueryClick(query)}
            variant="outline"
            justifyContent="center"
            whiteSpace="normal"
            textAlign="center"
            height="auto"
            maxHeight="75px"
            minWidth="150px"
            py={2}
            px={4}
            mr={2}
            flexShrink={0}
          >
            {query.value}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default QueryList;
