import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import {
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import { AuthState } from "../../Context/AuthProvider";

const ForgotPasswordModal = ({ isOpen, onClose }) => {
  const { auth } = AuthState();
  const [email, setEmail] = useState("");
  const toast = useToast();

  const isValidEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const checkIfUserExists = async () => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async () => {
    try {
      if (!isValidEmail()) {
        throw new Error("Invalid email. Enter a valid email address.");
      }

      //   const emailExists = await checkIfUserExists();
      //   if (!emailExists) {
      //     throw new Error("No user found with this email.");
      //   }

      await sendPasswordResetEmail(auth, email);

      toast({
        title: `Password Reset Email Sent.`,
        description: `An email has been sent to reset your password.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: `Error occured`,
        description: `${error.message}.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth={{ base: "60%", md: "550px" }}>
        <ModalHeader>Reset Password</ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel>Enter your email.</FormLabel>
            <Input
              placeholder="Email that you use to login."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ForgotPasswordModal;
