import React from "react";
import { Avatar, Box, Divider, Text } from "@chakra-ui/react";

const UserAvatar = ({ user, isMe, isSelected }) => {
  return (
    <>
      {user ? (
        <Box
          width={"100%"}
          bgColor={isSelected ? "green !important" : "inherit"}
          // boxShadow={"1px 1px 5px 1px grey"}
          minWidth={"250px"}
          padding={2}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"center"}
          gap={3}
          color={isSelected ? "white" : "black"}
          bg="gray.50"
          borderRadius="md"
          _hover={{
            bg: "blue.50",
            boxShadow: "md",
            transform: "translateY(-2px)",
            cursor: "pointer",
          }}
          transition="all 0.2s"
        >
          <Avatar
            name={`${(user.firstName + " " + user.lastName).toUpperCase()}`}
            size={"sm"}
            src={user?.imageUrl || ""}
          />
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Text fontSize={"18px"}>
              {!isMe
                ? (user.firstName + " " + user.lastName)
                    .substring(0, 18)
                    .toUpperCase()
                : "ME"}
            </Text>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Divider border={"1px solid grey.400"} />
    </>
  );
};

export default UserAvatar;
