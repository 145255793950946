import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MyTasks from "../Miscellaneous/MyTasks/MyTasks";
import { LayoutState } from "../../Context/LayoutProvider";
import MyReports from "../Miscellaneous/MyReports/MyReports";

const Main = ({ isOpen, onClose, myTasksRef, myReportsRef, metricsRef }) => {
  const { isPhone } = LayoutState();
  const [spacingFromTop, setSpacingFromTop] = useState(0);

  useEffect(() => {
    const headerContainer =
      document.getElementsByClassName("header-container")[0];
    setSpacingFromTop(headerContainer.clientHeight);
  }, []);

  return (
    <Box
      overflowY={"auto"}
      flex={1}
      marginRight={0}
      marginLeft={!isPhone ? `210px` : "0px"}
      pointerEvents={isOpen ? "none" : "auto"}
      display={"flex"}
      flexDir={"column"}
      gap={5}
      maxHeight={`calc(100vh - ${spacingFromTop + 10}px)`}
    >
      <Box flex={1}>
        {isPhone && isOpen && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="1"
            bg="transparent"
            pointerEvents="auto"
            bgColor={"#0000002b"}
            onClick={onClose}
          />
        )}
        {/* <Metrics metricsRef={metricsRef} /> */}
        <MyTasks taskRef={myTasksRef} isPhoneSideBarOpen={isOpen} />
        <MyReports reportRef={myReportsRef} isPhoneSideBarOpen={isOpen} />
      </Box>
    </Box>
  );
};

export default Main;
