import { Box, CardFooter, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthState } from "../../../Context/AuthProvider";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";

const CurrentTaskHours = () => {
  const { user } = AuthState();
  const [currentTaskHours, setCurrentTaskHours] = useState(0);

  useEffect(() => {
    if (user?.currentTaskHours >= 0) {
      // console.log(user);
      setCurrentTaskHours(user?.currentTaskHours);
      return;
    }
    setCurrentTaskHours(0);
  }, [user]);

  return (
    <Card borderRadius={"25px"} boxShadow={"none"}>
      <CardHeader>
        <Heading size="md">Task Hours</Heading>
      </CardHeader>

      <CardBody
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <Text fontSize={"40px"} fontWeight={700}>
            {currentTaskHours}
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default CurrentTaskHours;
