import {
  Box,
  Heading,
  List,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

const MarkdownRenderer = ({ content }) => {
  return (
    <Box>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <Heading
              as="h1"
              size="xl"
              mt={6}
              mb={4}
              {...(props ? props : {})}
            />
          ),
          h2: ({ node, ...props }) => (
            <Heading
              as="h2"
              size="lg"
              mt={5}
              mb={3}
              {...(props ? props : {})}
            />
          ),
          h3: ({ node, ...props }) => (
            <Heading
              as="h3"
              size="md"
              mt={4}
              mb={2}
              {...(props ? props : {})}
            />
          ),
          h4: ({ node, ...props }) => (
            <Heading
              as="h4"
              size="sm"
              mt={3}
              mb={2}
              {...(props ? props : {})}
            />
          ),
          p: ({ node, ...props }) => <Text mb={2} {...(props ? props : {})} />,
          ul: ({ node, children, ...props }) => (
            <List as={UnorderedList} mb={4} pl={4} spacing={2} {...props}>
              {children}
            </List>
          ),
          ol: ({ node, children, ...props }) => (
            <List as={OrderedList} mb={4} pl={4} spacing={2} {...props}>
              {children}
            </List>
          ),
          li: ({ node, ...props }) => <ListItem {...(props ? props : {})} />,
          strong: ({ node, ...props }) => (
            <Text as="strong" fontWeight="bold" {...(props ? props : {})} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer;
