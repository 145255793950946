import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { AuthState } from "../../../Context/AuthProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import { AddIcon, CloseIcon, DeleteIcon, StarIcon } from "@chakra-ui/icons";
import SkillItem from "./SkillItem";

const Skills = () => {
  const { screenWidth } = LayoutState();
  const { user, updateUserSkills } = AuthState();
  const toast = useToast();
  const initialFocusRef = useRef(null);

  const [skills, setSkills] = useState(
    user
      ? {
          ...user.skills,
        }
      : {}
  );
  const [formData, setFormData] = useState({
    name: "",
    rating: 0,
  });
  const [errorsNewSkill, setErrorsNewSkill] = useState({
    name: "none",
    rating: "none",
  });
  const [showAddNewForm, setshowAddNewForm] = useState(false);

  const shouldUpdateRef = useRef(false);
  const initialRender = useRef(true);

  const toggleShowAddNewForm = () => {
    let showToast = false;
    setshowAddNewForm((val) => {
      if (!val && Object.keys(skills).length >= 25) {
        showToast = true;
        return val;
      }
      return !val;
    });

    if (showToast) {
      toast({
        title: "You already have 25 skills. Delete some to add new ones.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        size: "sm",
      });
      showToast = false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrorsNewSkill((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const isValidRating = () => {
    if (formData.rating < 1 || formData.rating > 5) {
      // console.log("in");
      return false;
    } else {
      return true;
    }
  };

  const validate = () => {
    const errors = {};

    if (!isValidRating()) errors.rating = "Rating must be between 1 and 5.";
    if (!formData.name) errors.name = "Enter a valid skill.";

    return errors;
  };

  const addSkill = (e) => {
    const validationErrors = validate();

    // console.log(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrorsNewSkill(validationErrors);
      return;
    }

    let isNewSkill = true;

    Object.entries(skills).map(([key, value]) => {
      if (key === formData.name && value === formData.rating) {
        isNewSkill = false;
      }
    });

    if (Object.keys(skills).length >= 25) {
      toast({
        title: `25 skills added already. Remove some to add new ones.`,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    if (isNewSkill) {
      shouldUpdateRef.current = true;
      setSkills((prevSkills) => ({
        ...prevSkills,
        [formData.name.toLowerCase()]: formData.rating,
      }));
    } else {
      setFormData({ name: "", rating: 0 });
      toast({
        title: `Skill already exists.`,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const removeSkill = (e) => {
    const skillName = e.target.name;
    console.log(e.target);

    shouldUpdateRef.current = true;
    console.log(skillName);

    if (!skillName) {
      return;
    }

    setSkills((prevSkills) => {
      const { [skillName]: _, ...remainingSkills } = prevSkills;
      console.log(remainingSkills);

      return remainingSkills;
    });
  };

  useEffect(() => {
    if (!initialRender.current) {
      if (Object.keys(skills).length === 25) {
        toggleShowAddNewForm();
      }
    } else {
      initialRender.current = false;
      return;
    }

    if (!shouldUpdateRef.current) {
      return;
    }

    try {
      updateUserSkills(skills);

      setFormData({
        name: "",
        rating: 0,
      });
    } catch (error) {
      toast({
        title: `Error occured.`,
        description: `Problem in updating user skills`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      // console.log(error);
    } finally {
      shouldUpdateRef.current = false;
    }
  }, [skills]);

  useEffect(() => {
    if (showAddNewForm && initialFocusRef.current) {
      initialFocusRef.current.focus();
    }
  }, [showAddNewForm]);

  return (
    <FormControl
      id="skills"
      isInvalid={
        (errorsNewSkill.name &&
          errorsNewSkill.name !== "none" &&
          errorsNewSkill.name !== "") ||
        (errorsNewSkill.rating &&
          errorsNewSkill.rating !== "none" &&
          errorsNewSkill.rating !== "")
      }
    >
      <Box
        marginTop={3}
        display="flex"
        justifyContent={screenWidth < 615 ? "space-between" : "flex-start"}
        alignItems="center"
        mb={1}
        gap={3}
      >
        <FormLabel margin={0} display="flex" alignItems="center">
          Skills
        </FormLabel>
        <Button
          size="sm"
          colorScheme={showAddNewForm ? "red" : "green"}
          leftIcon={!showAddNewForm && <AddIcon />}
          onClick={toggleShowAddNewForm}
        >
          {showAddNewForm ? "Cancel" : "Add"}
        </Button>
      </Box>
      <Text fontSize={"sm"} color={"gray"}>
        Note: You can add upto 25 skills only.
      </Text>
      {showAddNewForm && (
        <InputGroup
          display={"flex"}
          flexDir={screenWidth < 615 ? "column" : "row"}
          gap={3}
          maxWidth={"800px"}
        >
          <Input
            name="name"
            ref={initialFocusRef}
            type="name"
            placeholder="Skill"
            value={formData.name}
            onChange={handleChange}
          />
          {errorsNewSkill.name && errorsNewSkill.name !== "none" && (
            <FormErrorMessage>{errorsNewSkill.name}</FormErrorMessage>
          )}
          <Select
            color={"gray"}
            name="rating"
            placeholder="Rating"
            value={formData.rating}
            onChange={handleChange}
            width={"150px"}
          >
            <option color="black" value="1">
              1
            </option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </Select>
          {errorsNewSkill.rating && errorsNewSkill.rating !== "none" && (
            <FormErrorMessage>{errorsNewSkill.rating}</FormErrorMessage>
          )}
          <Button onClick={addSkill} colorScheme="blue">
            Add
          </Button>
        </InputGroup>
      )}
      <Box width={"100%"} height={"100%"} display={"flex"} flexWrap={"wrap"}>
        {Object.entries(skills).map(([key, value]) => (
          <SkillItem name={key} value={value} onDelete={removeSkill} />
        ))}
      </Box>
    </FormControl>
  );
};

export default Skills;
