import React, { useEffect, useState } from "react";
import { Avatar, Box, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import AllMembers from "./AllMembers";
import Unverified from "./Unverified";
import { LayoutState } from "../../Context/LayoutProvider";
import Overview from "./Overview";
import Navbar from "./Navbar";
import { UserState } from "../../Context/UserProvider";

const Main = ({ isOpen, viewingOrganization, isVerified }) => {
  const { isPhone } = LayoutState();
  const { selectedOrganization } = UserState();
  const [selectedNav, setSelectedNav] = useState("overview");

  const navs = [
    { id: 1, name: "overview", value: "Overview" },
    { id: 2, name: "members", value: "Members" },
  ];

  const components = {
    overview: <Overview viewingOrganization={viewingOrganization} />,
    members: <AllMembers viewingOrganization={viewingOrganization} />,
  };

  return (
    <Box
      flex={1}
      marginRight={0}
      marginLeft={!isPhone ? `200px` : "0px"}
      pointerEvents={isOpen ? "none" : "auto"}
      display={"flex"}
      flexDir={"column"}
      height={"100vh"}
      maxHeight={"100vh"}
      overflow="hidden"
    >
      <Box>
        <Box
          display={"flex"}
          gap={2}
          my={0}
          py={3}
          ml={4}
          mb={3}
          alignItems={"center"}
        >
          <Avatar
            src={selectedOrganization?.logoUrl}
            name={viewingOrganization?.name}
            size={"md"}
            _hover={{ cursor: "pointer" }}
          />
          <Heading>{viewingOrganization?.name}</Heading>
        </Box>
        <Navbar
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
          navs={navs}
        />
      </Box>
      <Box flex={1} overflowY={"auto"} display={"flex"} width={"100%"}>
        {components[selectedNav]}
      </Box>
    </Box>
  );
};

export default Main;
