import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsFillBuildingFill } from "react-icons/bs";

const OrgItem = ({ org, leaveOrg }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <InputGroup
      size="md"
      borderColor={"gray !important"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      position="relative"
      _hover={{ cursor: "pointer !important" }}
    >
      <InputLeftElement pointerEvents="none">
        <BsFillBuildingFill />
      </InputLeftElement>
      <Input
        borderColor="gray"
        _hover={{ cursor: "pointer !important", borderColor: "gray.400" }}
        value={`${org.name} (${org.userRole})`}
        readOnly
      />
      <InputRightElement width="auto" pr="1">
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => leaveOrg(org)}
          opacity={isHovered ? 1 : 0}
          transition="opacity 0.2s"
          aria-hidden={!isHovered}
          tabIndex={isHovered ? 0 : -1}
        >
          Leave
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default OrgItem;
