import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  Text,
  useDisclosure,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import UserAvatar from "../UserAvatar/UserAvatar";
import { findName } from "../MyTasks/utils";
import { DeleteIcon } from "@chakra-ui/icons";

const MembersDropDown = ({
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  projectId,
  //   memberId,
  // setMemberId = () => {},
  inputPlaceholderText = "",
  members = [],
  setMembers = () => {},
  memberIdsToAvoid = [],
  membersToPickFrom = [],
  forInstantUpdation = false,
  instantUpdateFunction = async () => {},
  shouldCheckForPersonalOrganization = false,
  shouldSelectMultipleMembers = false,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  // Use external or internal state and functions
  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const { projects, selectedOrganization, user } = UserState();
  const [safeMembersToPickFrom, setSafeMembersToPickFrom] =
    useState(membersToPickFrom); // safeMembersToPickFrom = membersToPickFrom - memberIdsToAvoid
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [query, setQuery] = useState("");
  const [memberName, setMemberName] = useState("");
  const [memberId, setMemberId] = useState(null);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const toast = useToast();

  const triggerInstantMemberUpdateFunction = async (memberId) => {
    await instantUpdateFunction(memberId);
  };

  const isPresent = (obj) => {
    return members.find(
      (member) => member.firebaseUserId === obj.firebaseUserId
    );
  };

  const handleInputBlur = (e) => {
    if (e.relatedTarget === popoverRef.current) {
      // console.log(e.target);
      return;
    }
    onClose();
  };

  const handleMemberClick = (e, obj) => {
    e?.stopPropagation();
    if (shouldSelectMultipleMembers) {
      if (
        !members.find((member) => member.firebaseUserId === obj.firebaseUserId)
      ) {
        setMembers((prevMembers) => {
          return [{ ...obj }, ...prevMembers];
        });
      }
    } else {
      setMemberId(obj.firebaseUserId);
      setMemberName(`${obj.firstName + " " + obj.lastName}`);
    }

    setQuery(`${obj.firstName + " " + obj.lastName}`);
    onClose();

    if (forInstantUpdation) {
      triggerInstantMemberUpdateFunction(obj.firebaseUserId);
    }
  };

  const setQueryUsers = async () => {
    try {
      const words = query.split(" ");

      const regexPattern = new RegExp(words.join("|"), "i");

      // Filter data based on regex pattern
      const filteredMembers = safeMembersToPickFrom.filter((member) =>
        Array.isArray(memberIdsToAvoid) && memberIdsToAvoid.length > 0
          ? !memberIdsToAvoid.find(member.firebaseUserId) &&
            regexPattern.test(
              `${member.firstName} ${member.lastName} ${member.email}`
            )
          : regexPattern.test(
              `${member.firstName} ${member.lastName} ${member.email}`
            )
      );

      if (Array.isArray(filteredMembers) && filteredMembers.length > 0) {
        setFilteredMembers(filteredMembers);
      } else {
        setFilteredMembers(null);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      const member = findName(memberId, safeMembersToPickFrom);
      setMemberName(member);
    }

    if (memberName && query !== memberName) {
      setMemberId("");
      setMemberName("");
    }
    if (query === "") {
      setFilteredMembers(null);
    } else {
      setQueryUsers();
    }
  }, [query]);

  useEffect(() => {
    let optionMembers = [];
    if (
      memberIdsToAvoid &&
      Array.isArray(memberIdsToAvoid) &&
      memberIdsToAvoid.length > 0
    ) {
      optionMembers = membersToPickFrom.filter(
        (member) => !memberIdsToAvoid.find(member.firebaseUserId)
      );
    } else {
      optionMembers = [...membersToPickFrom];
    }

    const viewingProject = projects.find((project) => project.id === projectId);

    if (viewingProject) {
      optionMembers = optionMembers.filter(
        (member) =>
          !viewingProject.members.find((m) => m === member.firebaseUserId)
      );
    }

    // console.log(optionMembers);
    setSafeMembersToPickFrom(optionMembers);
  }, []);

  return (
    <Box width={"100%"}>
      <Popover onOpen={onOpen} isOpen={isOpen} initialFocusRef={inputRef}>
        <PopoverTrigger style={{ width: "100%" }}>
          <Button
            style={{ all: "unset" }}
            width={"100% !important"}
            display={"block"}
          >
            <Input
              display={"block"}
              width={"100% !important"}
              isDisabled={
                shouldCheckForPersonalOrganization &&
                selectedOrganization.isPersonal
                  ? true
                  : false
              }
              value={isOpen ? query : memberName}
              placeholder={inputPlaceholderText}
              color={
                shouldCheckForPersonalOrganization &&
                selectedOrganization.isPersonal
                  ? "blue.200"
                  : "black"
              }
              fontWeight={
                shouldCheckForPersonalOrganization &&
                selectedOrganization.isPersonal
                  ? 600
                  : "auto"
              }
              onFocus={onOpen}
              onBlur={handleInputBlur}
              border={"1px solid #E2E8F0"}
              onChange={(e) => {
                setQuery(e.target.value);
                if (!isOpen && query) {
                  onOpen();
                }
              }}
              ref={inputRef}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          ref={popoverRef}
          width={"100%"}
          border={"1px solid #ececec"}
          p={0}
          m={0}
          boxShadow={"0px 0px 10px 3px rgba(0, 0, 0, 0.2)"}
        >
          <PopoverBody width="100%" overflow={"scroll"} p={0} m={0}>
            <div id="filteredprojectswrapper" ref={popoverRef}>
              {filteredMembers?.length > 0 ? (
                filteredMembers.map((obj) => {
                  return (
                    <Box
                      key={obj.id}
                      marginY={0}
                      borderRadius={"15px"}
                      _hover={{ cursor: "pointer" }}
                      width={"100% !important"}
                      textAlign={"left"}
                      paddingBottom={0}
                      onClick={(e) => handleMemberClick(e, obj)}
                      boxShadow="sm"
                      bg={"white"}
                    >
                      {obj.id !== user.id ? (
                        <UserAvatar
                          user={obj}
                          me={false}
                          isSelected={isPresent(obj)}
                        />
                      ) : (
                        <UserAvatar
                          user={obj}
                          isMe={true}
                          isSelected={isPresent(obj)}
                        />
                      )}
                    </Box>
                  );
                })
              ) : (
                <Text color={"black"} padding={3}>
                  {query
                    ? "User doesn't exist under this organization."
                    : "Type Member's name / email."}
                </Text>
              )}
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Box
        id="selected-members-list"
        width={"100%"}
        maxWidth={"100%"}
        overflow={"scroll"}
        bgColor={"transparent"}
      >
        <HStack gap={1}>
          {Array.isArray(members) &&
            members.map((selectedMember) => (
              <Tooltip
                label={`${selectedMember?.firstName} ${selectedMember?.lastName}`}
                key={selectedMember.id}
              >
                <Button
                  key={selectedMember.id}
                  textAlign={"left"}
                  //   width={"100%"}
                  wordBreak={"keep-all"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  bg={"gray.50"}
                  borderRadius="md"
                  border={"1px solid gray"}
                  _hover={{
                    bg: "blue.50",
                    boxShadow: "md",
                    transform: "translateY(-2px)",
                    cursor: "pointer",
                  }}
                  rightIcon={<DeleteIcon color={"red"} />}
                  transition="all 0.2s"
                  p={2}
                  m={1}
                  onClick={() => {
                    setMembers(
                      members.filter(
                        (member) =>
                          member.firebaseUserId !==
                          selectedMember.firebaseUserId
                      )
                    );
                  }}
                >
                  {selectedMember?.firstName} {selectedMember?.lastName}
                </Button>
              </Tooltip>
            ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default MembersDropDown;
