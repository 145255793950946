import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import UserAvatar from "../UserAvatar/UserAvatar";
import { AuthState } from "../../../Context/AuthProvider";
import { findFirstName, findName } from "../MyTasks/utils";

const ReporterDropDown = ({
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  setReviewerId,
  reviewerId,
  projectId,
  assigneeId,
  forUpdation = false,
  isTriggerBgWhite = false,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  // Use external or internal state and functions
  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const {
    projects,
    organizationMembers,
    selectedOrganization,
    updateTaskReviewer,
  } = UserState();
  const { user } = AuthState();

  const [filteredUsers, setFilteredUsers] = useState(null);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const [reviewer, setReviewer] = useState(null);
  const [reviewerName, setReviewerName] = useState("");
  const [projectMembers, setProjectMembers] = useState(null);
  const toast = useToast();

  const changeReporter = async (reviewerId) => {
    await updateTaskReviewer(reviewerId);
  };

  const handleInputBlur = (e) => {
    if (e.relatedTarget === popoverRef.current) {
      // console.log(e.target);
      return;
    }
    onClose();
  };

  const handleUserClick = (e, obj) => {
    e?.stopPropagation();
    setReviewerId(obj.firebaseUserId);
    setQuery(`${obj.firstName + " " + obj.lastName}`);
    setReviewer(obj);
    setReviewerName(`${obj.firstName + " " + obj.lastName}`);

    onClose();

    if (forUpdation) {
      changeReporter(obj.firebaseUserId);
    }
  };

  const setQueryUsers = async () => {
    try {
      if (!projectId || projectId === "") {
        toast({
          title: "You need to select a project first.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        setQuery("");
        return;
      }

      const words = query.split(" ");

      const regexPattern = new RegExp(words.join("|"), "i");

      // Filter data based on regex pattern
      const filteredReporters = projectMembers.filter(
        (member) =>
          (assigneeId === null || member.firebaseUserId !== assigneeId) &&
          regexPattern.test(
            `${member.firstName} ${member.lastName} ${member.designation}`
          )
      );

      if (
        filteredReporters &&
        Array.isArray(filteredReporters) &&
        filteredReporters.length > 0
      ) {
        setFilteredUsers(filteredReporters);
      } else {
        setFilteredUsers(null);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      return;
    }

    if (!isOpen && reviewerId) {
      const reviewer = findName(reviewerId, organizationMembers, user);
      setReviewerName(reviewer);
      setReviewer(reviewer);
      console.log(reviewer);
    }

    if (reviewerName && query !== reviewerName) {
      setReviewerId("");
      setReviewerName("");
      setReviewer(null);
    }
    if (query === "") {
      setFilteredUsers(null);
    } else {
      setQueryUsers();
    }
  }, [query]);

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      return;
    }

    if (!projectId && query) {
      setQuery("");
    }

    if (organizationMembers && projectId && projectId !== "") {
      const projMembers = [];
      // for (const project of projects) {
      //   if (project?.id === projectId) {
      //     for (const memberId of project.members) {
      //       for (const orgMemberData of organizationMembers) {
      //         if (orgMemberData.firebaseUserId === memberId) {
      //           projMembers.push(orgMemberData);
      //         }
      //       }
      //     }
      //     break;
      //   }
      // }

      for (const member of organizationMembers) {
        if (
          member?.projectIds?.includes(projectId) ||
          member?.projectId === projectId
        ) {
          projMembers.push(member);
        }
      }
      setProjectMembers(projMembers);
    }
  }, [projectId]);

  useEffect(() => {
    if (isOpen && !assigneeId && !query) {
      if (projectMembers && projectMembers.length > 0) {
        setFilteredUsers(() => {
          const membersCopy = [...(projectMembers || [])];
          membersCopy?.splice(4);

          return membersCopy.length > 0 ? membersCopy : null;
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      setReviewerId(user?.firebaseUserId);
      setReviewerName(`${user?.firstName} ${user?.lastName}`);
      setReviewer(user);
    }

    if (reviewerId) {
      const reviewer = organizationMembers?.find(
        (member) => member.firebaseUserId === reviewerId
      );
      if (reviewer?.firebaseUserId) {
        setReviewer(reviewer);
      }
    }
  }, []);

  return (
    <Popover onOpen={onOpen} isOpen={isOpen} initialFocusRef={inputRef}>
      <PopoverTrigger>
        <Button
          w={"full"}
          bg={isTriggerBgWhite ? "white" : "gray.100"}
          _hover={{ opacity: 0.8 }}
          p={0}
        >
          <InputGroup w={"full"}>
            {reviewerName && !isOpen && (
              <InputLeftElement>
                <Avatar
                  name={reviewerName}
                  size={"xs"}
                  src={reviewer?.imageUrl}
                />
              </InputLeftElement>
            )}
            <Input
              w={"full"}
              isDisabled={selectedOrganization.isPersonal ? true : false}
              value={isOpen ? query.toUpperCase() : reviewerName.toUpperCase()}
              placeholder="Reviewer"
              color={selectedOrganization.isPersonal ? "blue" : "black"}
              fontWeight={selectedOrganization.isPersonal ? 600 : "auto"}
              onFocus={onOpen}
              onBlur={handleInputBlur}
              onChange={(e) => {
                setQuery(e.target.value);
                if (!isOpen && query) {
                  onOpen();
                }
              }}
              ref={inputRef}
            />
          </InputGroup>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        width={"100%"}
        border={"1px solid #ececec"}
        boxShadow={"0px 0px 10px 3px rgba(0, 0, 0, 0.2)"}
        m={0}
        p={0}
      >
        <PopoverBody
          maxHeight={"250px"}
          width="250px"
          m={0}
          p={0}
          overflow={"scroll"}
        >
          <div id="filteredprojectswrapper" ref={popoverRef}>
            {filteredUsers && filteredUsers?.length > 0 ? (
              filteredUsers.map((obj) => {
                return (
                  <Box
                    key={obj.id}
                    marginY={0}
                    borderRadius={"15px"}
                    _hover={{ cursor: "pointer" }}
                    width={"100% !important"}
                    textAlign={"left"}
                    paddingBottom={0}
                    onClick={(e) => handleUserClick(e, obj)}
                    boxShadow="sm"
                    bg="white"
                  >
                    {obj.id !== user.id ? (
                      <UserAvatar user={obj} me={false} />
                    ) : (
                      <UserAvatar user={obj} isMe={true} />
                    )}
                  </Box>
                );
              })
            ) : (
              <Text color={"black"} padding={3}>
                {query !== "" && query
                  ? "User doesn't exist under this project."
                  : "Enter Reviewer's Name."}
              </Text>
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ReporterDropDown;
