import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthState } from "../../../Context/AuthProvider";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";

const TasksFailed = () => {
  const { user } = AuthState();
  const [tasksFailed, setTasksFailed] = useState(100);

  useEffect(() => {
    if (user?.tasksFailed >= 0) {
      setTasksFailed(user?.tasksFailed);
      return;
    }
    setTasksFailed(0);
  }, [user]);

  return (
    <Card borderRadius={"25px"} boxShadow={"none"}>
      <CardHeader>
        <Heading size="md">Tasks Failed</Heading>
      </CardHeader>

      <CardBody
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box flex={1}>
          <Text fontSize={"40px"} fontWeight={700}>
            {tasksFailed}
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default TasksFailed;
