import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  VStack,
  useColorModeValue,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutState } from "../Context/LayoutProvider";
import Header from "../Components/LandingPageComponents/Header";
import BrandingSection from "../Components/LandingPageComponents/BrandingSection";
import FeaturesSection from "../Components/LandingPageComponents/FeaturesSection";
import PricingSection from "../Components/LandingPageComponents/PricingSection";
import TestimonialsSection from "../Components/LandingPageComponents/TestimonialsSection";
import Footer from "../Components/LandingPageComponents/Footer";

const MotionBox = motion.create(Box);

const LandingPage = () => {
  const [headerHeight, setHeaderHeight] = useState(66);
  const [isNameVisible, setIsNameVisible] = useState(true);
  const { screenWidth, isPhone } = LayoutState();
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const brandNameRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const testimonialsRef = useRef(null);

  const navigate = useNavigate();

  const bgGradient = useColorModeValue(
    "linear(to-br, blue.50, purple.50, green.50)",
    "linear(to-br, blue.900, purple.900, green.900)"
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsNameVisible(entry.isIntersecting);
      },
      { threshold: 1 }
    );

    const brandNameComponent = brandNameRef.current;

    if (brandNameComponent) {
      observer.observe(brandNameComponent);
    }

    return () => {
      if (brandNameComponent) {
        observer.unobserve(brandNameComponent);
      }
    };
  }, [brandNameRef]);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Box
      id="landing-page"
      position="relative"
      minHeight="100vh"
      width="100vw"
      maxWidth="100vw"
      overflow="hidden"
      bgGradient={bgGradient}
      ref={containerRef}
    >
      <Header
        containerRef={containerRef}
        myRef={headerRef}
        isNameVisible={isNameVisible}
        sectionRefs={{ pricingRef, featuresRef }}
      />

      <MotionBox
        as="main"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
        py={10}
        pb={0}
        pt={`${headerHeight + 20}px`}
        overflowY="auto"
        height={`calc(100vh - ${headerHeight}px)`}
      >
        <Container maxW="container.xl" px={4}>
          <VStack spacing={{ base: 16, md: 24 }} align="stretch">
            <MotionBox variants={fadeInUp}>
              <BrandingSection
                headerHeight={headerHeight}
                brandNameRef={brandNameRef}
              />
            </MotionBox>

            <MotionBox variants={fadeInUp}>
              <FeaturesSection
                headerHeight={headerHeight}
                featuresRef={featuresRef}
              />
            </MotionBox>

            <MotionBox variants={fadeInUp}>
              <TestimonialsSection testimonialsRef={testimonialsRef} />
            </MotionBox>

            <MotionBox variants={fadeInUp}>
              <PricingSection
                headerHeight={headerHeight}
                pricingRef={pricingRef}
              />
            </MotionBox>
          </VStack>
        </Container>

        <MotionBox
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.5 }}
        >
          <Footer />
        </MotionBox>
      </MotionBox>
    </Box>
  );
};

export default LandingPage;
