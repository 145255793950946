import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Settings from "./Pages/Settings";
import Auth from "./Pages/Auth";
import Project from "./Pages/Project";
import Organization from "./Pages/Organization";
import Landing from "./Pages/Landing";
import NotFound from "./Pages/NotFound";
import Metrics from "./Pages/Metrics";
import Verification from "./Pages/Verification";
import Assistant from "./Pages/Assistant";

function App() {
  return (
    <div className="App" style={{ margin: 0, padding: 0, position: "fixed" }}>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/auth" element={<Auth />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/metrics" element={<Metrics />}></Route>
        <Route path="/projects/:projectId" element={<Project />}></Route>
        <Route path="/organizations/:orgId" element={<Organization />}></Route>
        <Route path="/assistant/:orgId" element={<Assistant />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/verify" element={<Verification />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
