import { useEffect, useState } from "react";

const useGetAccessToken = (refreshToken, auth) => {
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!auth?.currentUser || !refreshToken) {
      setAccessToken(null);

      if (refreshToken === undefined || auth?.currentUser === undefined) {
        // console.log(refreshToken);
        // console.log(auth?.currentUser);
        setError(
          "user is not logged in or current session has ended. login to continue with the application."
        );
      }
      return;
    }

    const getAccessToken = async (getNew = false) => {
      try {
        const currentUser = auth.currentUser;
        const currentAccessToken = await currentUser.getIdToken(getNew);

        if (!currentAccessToken) {
          //signout user here
          throw new Error("Couldn't refresh access token");
        }

        setAccessToken(currentAccessToken);

        // console.log("Access Token has changed:", currentAccessToken);

        return currentAccessToken;
      } catch (error) {
        // console.log(error);
        throw error;
      }
    };

    const checkAndRefreshAccessToken = async () => {
      try {
        const currentUser = auth.currentUser;
        const expiryTime = currentUser?.stsTokenManager?.expirationTime;
        const now = Date.now();

        if (expiryTime - now <= 310000) {
          // console.log(
          //   "token will be expiring within 5 minutes 10 seconds from now, hence refreshing it."
          // );
          const currentAccessToken = await getAccessToken(true);
          // console.log("Access token refreshed:", currentAccessToken);
        }
      } catch (error) {
        setError(
          "Error is updating access token upon near to expiry, you are being logged out. Kindly login again to continue."
        );
        // console.log("Error in check and refresh access token:", error);
      }
    };

    const fetchInitialAccessToken = async () => {
      try {
        const currentUser = auth.currentUser;
        const expiryTime = currentUser?.stsTokenManager?.expirationTime;
        const now = Date.now();

        const getNew = expiryTime - now <= 310000;
        // console.log("initial access token fetch");
        await getAccessToken(getNew);
      } catch (error) {
        // console.error("Initial access token fetch error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialAccessToken();

    const interval = setInterval(() => {
      checkAndRefreshAccessToken();
    }, 55 * 60 * 1000); // 5 minutes

    return () => clearInterval(interval);
  }, [auth, refreshToken]);

  return { accessToken, loading, error };
};

export default useGetAccessToken;
