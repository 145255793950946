import { Box, Button, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SettingsPanel from "../Components/SettingComponents/SettingsPanel";
import SettingExpanded from "../Components/SettingComponents/SettingExpanded";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { LayoutState } from "../Context/LayoutProvider";

const Settings = () => {
  const navigate = useNavigate();
  const [selectedSetting, setSelectedSetting] = useState("");
  const { screenWidth, isPhone } = LayoutState();

  useEffect(() => {
    !isPhone && setSelectedSetting("profile");
  }, []);

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      width={"100vw"}
      height={"100vh"}
      maxHeight={"100vh"}
      overflow={"hidden"}
      bgColor={"white"}
    >
      <Box id="settings-header">
        <Box
          display={"flex"}
          paddingLeft={2}
          paddingTop={2}
          alignItems={"start"}
          flexDir={"column"}
        >
          {screenWidth > 500 && (
            <>
              <Button
                mt={2}
                color={"blue"}
                leftIcon={<ArrowBackIcon />}
                bgColor={"inherit"}
                onClick={() => navigate("/dashboard")}
              >
                Back to Dashboard
              </Button>
              <Heading marginLeft={5} textAlign={"center"}>
                Settings
              </Heading>
            </>
          )}
          {screenWidth < 500 && !selectedSetting ? (
            <>
              <Button
                mb={2}
                color={"blue"}
                leftIcon={<ArrowBackIcon />}
                bgColor={"inherit"}
                onClick={() => navigate("/dashboard")}
              >
                Back to Dashboard
              </Button>
              <Heading marginLeft={5} textAlign={"center"}>
                Settings
              </Heading>
            </>
          ) : (
            screenWidth < 500 && (
              <Button
                color={"blue"}
                leftIcon={<ArrowBackIcon />}
                bgColor={"inherit"}
                onClick={() => setSelectedSetting(null)}
              >
                Back to Settings
              </Button>
            )
          )}
        </Box>
      </Box>
      <Box
        mt={!isPhone && "5px"}
        width={"100vw"}
        height={"100%"}
        flex={1}
        overflow={"none"}
        display={"flex"}
        flexDir={"row"}
      >
        <SettingsPanel
          setSelectedSetting={setSelectedSetting}
          selectedSetting={selectedSetting}
        />
        <SettingExpanded
          setSelectedSetting={setSelectedSetting}
          selectedSetting={selectedSetting}
        />
      </Box>
    </Box>
  );
};

export default Settings;
