import { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { TASK_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";

const useReportTasks = (selectedOrganization) => {
  const { accessToken } = AuthState();
  const [reportTasks, setReportTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(0);

  const reload = () => {
    setReloadFlag((prevValue) => prevValue + 1);
  };

  useEffect(() => {
    if (!selectedOrganization) {
      setReportTasks([]);
      // if (selectedOrganization === undefined) {
      //   setError(
      //     "Cannot fetch review tasks data without selected organization data"
      //   );
      // }
      return;
    }

    const getReportTasksData = async () => {
      if (selectedOrganization.isPersonal) {
        setReportTasks(null);
        setLoading(false);
        return;
      }
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/reports_tasks?orgId=${selectedOrganization.id}`,
          config
        );

        const reportTasksData = response?.data?.data;

        if (reportTasksData && Array.isArray(reportTasksData)) {
          // console.log(reportTasksData);
          setReportTasks(reportTasksData);
        } else {
          throw new Error("ReportTasks Data not found");
        }
      } catch (err) {
        // console.log(err);
        setError("Error while fetching review tasks");
      } finally {
        setLoading(false);
      }
    };

    getReportTasksData();
  }, [selectedOrganization, reloadFlag]);

  return { reportTasks, setReportTasks, loading, error, setError, reload };
};

export default useReportTasks;
