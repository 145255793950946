import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  VStack,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import AllMembers from "./Members/AllMembers";
import { InfoIcon } from "@chakra-ui/icons";
import { LayoutState } from "../../../Context/LayoutProvider";
import { IoMdPerson } from "react-icons/io";
import Overview from "./Overview/Overview";

const SettingsOption = ({ icon, label, isSelected, onClick }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("blue.100", "blue.700");

  return (
    <Flex
      align="center"
      p={3}
      cursor="pointer"
      bg={isSelected ? selectedBgColor : "transparent"}
      _hover={{ bg: bgColor }}
      onClick={onClick}
    >
      {icon}
      <Text ml={3}>{label}</Text>
    </Flex>
  );
};

const Settings = ({ viewingProject, projectMembers }) => {
  const [selectedSetting, setSelectedSetting] = useState("");
  const { screenWidth } = LayoutState();
  const isSmallScreen = screenWidth < 1000;

  const settingsOptions = [
    {
      id: "info",
      label: "Info",
      icon: <InfoIcon />,
      component: (
        <Overview project={viewingProject} projectMembers={projectMembers} />
      ),
    },
    {
      id: "members",
      label: "Members",
      icon: <IoMdPerson />,
      component: <AllMembers viewingProject={viewingProject} />,
    },
    // Add more settings options as needed
  ];

  useEffect(() => {
    if (!isSmallScreen && !selectedSetting) {
      setSelectedSetting("info");
    }
  }, [isSmallScreen, selectedSetting]);

  const handleSettingClick = (settingId) => {
    setSelectedSetting(settingId);
  };

  const handleBackClick = () => {
    setSelectedSetting("");
  };

  return (
    <Flex h="100%" w="100%" direction={isSmallScreen ? "column" : "row"}>
      {(!isSmallScreen || (isSmallScreen && !selectedSetting)) && (
        <VStack
          overflowY={"scroll"}
          w={isSmallScreen ? "100%" : "200px"}
          h="100%"
          borderRight={isSmallScreen ? "none" : "1px"}
          borderColor="gray.200"
          align="stretch"
        >
          {settingsOptions.map((option) => (
            <SettingsOption
              key={option.id}
              icon={option.icon}
              label={option.label}
              isSelected={selectedSetting === option.id}
              onClick={() => handleSettingClick(option.id)}
            />
          ))}
        </VStack>
      )}
      {(!isSmallScreen || (isSmallScreen && selectedSetting)) && (
        <Box flex={1} overflowY={"scroll"}>
          {isSmallScreen && (
            <Button
              leftIcon={<ChevronLeftIcon />}
              onClick={handleBackClick}
              mb={4}
            >
              Back to Settings
            </Button>
          )}
          {
            settingsOptions.find((option) => option.id === selectedSetting)
              ?.component
          }
        </Box>
      )}
    </Flex>
  );
};

export default Settings;
