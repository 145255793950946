import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import TestimonialItem from "./TestimonialItem";

const TestimonialsSection = ({ testimonialsRef }) => {
  const testimonials = [];
  return (
    <Box
      id="testimonials"
      width={"100%"}
      minHeight={"150px"}
      ref={testimonialsRef}
      marginY={4}
      display={"flex"}
      alignItems={"center"}
      padding={4}
      paddingX={6}
      gap={6}
      overflowX={"scroll"}
    >
      {testimonials.map((testimonial) => (
        <TestimonialItem testimonial={testimonial} key={testimonial.idx} />
      ))}
    </Box>
  );
};

export default TestimonialsSection;
