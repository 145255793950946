import { Box } from "@chakra-ui/react";
import React from "react";

const TestimonialItem = ({ testimonial }) => {
  return (
    <Box
      minWidth={"250px"}
      width={"250px"}
      height={"170px"}
      bgColor={"#f2e5cf"}
      borderRadius={"15px"}
    >
      Testimonial
    </Box>
  );
};

export default TestimonialItem;
