import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../Context/UserProvider";

const MilestoneDropDown = ({
  projectId,
  milestone,
  setMilestone,
  task = null,
  forUpdation = false,
  isTriggerBgWhite = false,
  setTask = () => {},
}) => {
  const { getMilestonesByProject, updateTaskMilestone } = UserState();
  const [projectMilestones, setProjectMilestones] = useState([]);
  const [loadingMilestones, setLoadingMilestones] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeMilestone = async (milestoneId) => {
    await updateTaskMilestone(task.id, milestoneId);
  };

  const handleMilestoneClick = async (e, milestoneObj) => {
    onClose();

    setMilestone({
      id: milestoneObj.id,
      title: milestoneObj.title,
    });

    setTask((prevTask) => ({
      ...prevTask,
      milestone: { id: milestoneObj.id, title: milestoneObj.title },
    }));

    if (forUpdation) {
      await changeMilestone(milestoneObj.id);
    }
  };

  useEffect(() => {
    const getProjectMilestones = async () => {
      try {
        setLoadingMilestones(true);
        setProjectMilestones([]);
        const milestones = await getMilestonesByProject(projectId);
        if (milestone) {
          setProjectMilestones(milestones);
        }
      } catch (error) {
        console.error("error loading milestones", error);
      } finally {
        setLoadingMilestones(false);
      }
    };

    if (projectId) {
      getProjectMilestones();
    } else {
      setProjectMilestones([]);
    }
  }, [projectId]);

  return (
    <Popover onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          w="full"
          bg={isTriggerBgWhite ? "white" : "gray.100"}
          color={!milestone?.title ? "gray.500" : "black"}
          minWidth={"60px !important"}
          _hover={{ opacity: 0.8 }}
          fontWeight={"400"}
          overflow={"scroll"}
          px={3}
        >
          {milestone?.title ? milestone.title : "Select Milestone"}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width={"100%"}
        borderRadius={"md"}
        boxShadow={"lg"}
        overflow={"hidden"}
        mx={2}
      >
        <PopoverBody
          p={0}
          pr={2}
          width={"100%"}
          maxH={"250px"}
          overflowY={"scroll"}
          overflowX={"hidden"}
        >
          {projectId ? (
            !loadingMilestones ? (
              Array.isArray(projectMilestones) &&
              projectMilestones.length > 0 ? (
                projectMilestones.map((obj) => (
                  <Box
                    key={obj.id}
                    overflowX={"scroll"}
                    width={"100%"}
                    whiteSpace={"nowrap"}
                    overflow={"scroll"}
                    bgColor={"gray.200"}
                    _hover={{
                      opacity: 0.8,
                      cursor: "pointer",
                    }}
                    textAlign={"left"}
                    m={"5px"}
                    mb={"2px"}
                    p={"5px"}
                    borderRadius={"md"}
                    onClick={(e) => handleMilestoneClick(e, obj)}
                    // mb={"5px"}
                  >
                    <Text fontSize={"sm"} px={3} py={1} textAlign={"left"}>
                      {obj.title}
                    </Text>
                  </Box>
                ))
              ) : (
                <Box>No Milestone Found</Box>
              )
            ) : (
              <Spinner size={"md"} />
            )
          ) : (
            <Box bgColor={"gray.100"} p={4}>
              No Project Selected
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default MilestoneDropDown;
