import React from "react";
import PhoneSideBar from "../Miscellaneous/Sidebars/PhoneSideBar";
import PermanentSideBar from "../Miscellaneous/Sidebars/PermanentSideBar";
import { LayoutState } from "../../Context/LayoutProvider";

const SideBar = ({ onOpen, isOpen, onClose }) => {
  const { isPhone } = LayoutState();

  return isPhone ? (
    <PhoneSideBar isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
  ) : (
    <PermanentSideBar />
  );
};

export default SideBar;
