import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import { LayoutState } from "../../Context/LayoutProvider";
import PricingBox from "./PricingBox";

const PricingSection = ({ headerHeight }) => {
  const { screenWidth, isPhone } = LayoutState();
  const pricingRef = useRef(null);

  return (
    <Box
      ref={pricingRef}
      minHeight={`calc(100vh - ${headerHeight}px)`}
      bg="gray.50"
      py={16}
    >
      <Heading as="h2" size="2xl" textAlign="center" mb={4}>
        Choose your plan
      </Heading>
      <Text fontSize="lg" textAlign="center" mb={12} color="gray.600">
        Select the plan that best fits your needs
      </Text>
      <Flex
        direction={screenWidth < 1024 ? "column" : "row"}
        justify="center"
        align="stretch"
        maxW="1200px"
        mx="auto"
        px={4}
        gap={8}
      >
        <PricingBox
          heading="Free"
          price="$0"
          period="forever"
          description="Free forever for 10 users"
          specials={[
            {
              value: "Unlimited goals, projects, tasks, and forms",
              present: true,
            },
            { value: "Up to 10 users", present: true },
            { value: "2 GB of storage", present: true },
          ]}
          buttonText="Get it now"
        />
        <PricingBox
          heading="Standard"
          price="$7.16"
          period="per user / month"
          description="Everything you need to get started"
          specials={[
            { value: "Everything in Free", present: true },
            { value: "Unlimited users", present: true },
            { value: "250 GB of storage", present: true },
            { value: "1,700 automations per site per month", present: true },
          ]}
          buttonText="Start free trial"
        />
        <PricingBox
          heading="Premium"
          price="$12.48"
          period="per user / month"
          description="Align multiple teams"
          specials={[
            { value: "Everything in Standard", present: true },
            { value: "Unlimited storage", present: true },
            { value: "Unlimited automations", present: true },
            { value: "24/7 support for critical issues", present: true },
          ]}
          buttonText="Start free trial"
          isRecommended={true}
        />
        <PricingBox
          heading="Enterprise"
          price="Custom"
          description="Advanced analytics, scale and security for enterprises"
          specials={[
            { value: "Everything in Premium", present: true },
            { value: "99.95% uptime SLA", present: true },
            { value: "Unlimited sites", present: true },
            { value: "24/7 support for all issues", present: true },
          ]}
          buttonText="Contact sales"
        />
      </Flex>
    </Box>
  );
};

export default PricingSection;
