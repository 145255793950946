import React, { useRef, useEffect, useState } from "react";
import {
  VStack,
  Text,
  Box,
  IconButton,
  useToast,
  Avatar,
  Image,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { UserState } from "../../Context/UserProvider";
import { LayoutState } from "../../Context/LayoutProvider";
import logo from "../../assets/logo.png";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";

const ChatbotInterface = ({
  conversation,
  setConversation,
  clickedQuery,
  setClickedQuery,
  loading,
  setLoading,
  context,
  setContext,
}) => {
  const { user, selectedOrganization } = UserState();
  const { isPhone } = LayoutState();
  const messagesEndRef = useRef(null);
  const toast = useToast();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [conversation]);

  const cancelQueryClicked = () => {
    const lastConversation = conversation[conversation.length - 1];
    setConversation((prevConversation) =>
      lastConversation && lastConversation.type === "user"
        ? [...prevConversation.slice(0, prevConversation.length - 1)]
        : [...prevConversation]
    );
    setClickedQuery(null);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast({
          title: "Failed to copy",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  useEffect(() => {
    if (conversation?.length === 0) {
      setConversation((prevConversation) => [
        ...(prevConversation || []),
        {
          type: "assistant",
          text: user?.firstName
            ? `Hello ${user?.firstName}. Which project may I assist you with?`
            : `Welcome to PMBot.`,
        },
        {
          type: "assistant",
          headingText: "Select Project.",
          onIgnoredText: "No Project Selected.",
          inputs: ["projectId"],
        },
      ]);
    }
  }, [conversation]);

  return (
    <>
      {!conversation ||
        (conversation.length === 0 && (
          <Box
            position="absolute"
            top={0}
            left={isPhone ? 0 : "150px"}
            right={0}
            bottom={0}
            opacity={0.1}
            zIndex={-1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDir={"column"}
          >
            <Image
              src={logo}
              alt="Background Logo"
              boxSize="250px"
              objectFit="contain"
            />
            <Text color={"black"} fontWeight={"900"} textAlign={"center"}>
              Welcome to PMBot.
            </Text>
          </Box>
        ))}
      <VStack spacing={4} flex={1} overflowY="scroll" width="100%" pr={2}>
        {conversation?.map((message, index) => (
          <Box
            key={index}
            role="group"
            position="relative"
            alignSelf={message.type === "user" ? "flex-end" : "flex-start"}
            maxWidth={isPhone ? "90%" : "75%"}
            display={"flex"}
            gap={2}
            fontSize={"14px"}
          >
            {message.type !== "user" && (
              <Avatar
                name={`${selectedOrganization.pmbotName}`}
                src={selectedOrganization?.logoUrl || ""}
                size={"sm"}
              />
            )}
            <Box
              bg={message.type === "user" ? "blue.100" : "gray.100"}
              p={3}
              borderRadius="md"
              _hover={{ cursor: "default" }}
            >
              {message.type === "user" ? (
                <UserMessage
                  conversation={conversation}
                  setConversation={setConversation}
                  message={message}
                  clickedQuery={clickedQuery}
                  setClickedQuery={setClickedQuery}
                  loading={loading}
                  setLoading={setLoading}
                  context={context}
                  onCancel={cancelQueryClicked}
                />
              ) : (
                <AssistantMessage
                  conversation={conversation}
                  setConversation={setConversation}
                  message={message}
                  context={context}
                  setContext={setContext}
                />
              )}
            </Box>
            {message.type === "user" && (
              <Avatar
                name={`${user?.firstName} ${user?.lastName}`}
                size={"sm"}
                src={user?.imageUrl || ""}
              />
            )}
            {message.type !== "user" && (
              <IconButton
                aria-label="Copy message"
                icon={<CopyIcon />}
                size="sm"
                position="absolute"
                top={1}
                right={1}
                opacity={0}
                _groupHover={{ opacity: 1 }}
                onClick={() => handleCopy(message.text)}
              />
            )}
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </VStack>
    </>
  );
};

export default ChatbotInterface;
