import React, { useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Avatar,
  VStack,
  Input,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { UserState } from "../../Context/UserProvider";

const OrganizationHeading = ({ organization, setOrganization }) => {
  const [isHovering, setIsHovering] = useState(false);
  const fileInputRef = useRef(null);
  const toast = useToast();
  const { uploadOrganizationLogo } = UserState();

  const handleLogoClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const newLogoUrl = await uploadOrganizationLogo(formData);
        setOrganization({ ...organization, logoUrl: newLogoUrl });
        toast({
          title: "Logo updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error uploading logo:", error);
        toast({
          title: "Error uploading logo",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex direction="column" align="center" mb={6}>
      <Box position="relative" mb={4}>
        <Avatar
          size="xl"
          src={organization.logoUrl}
          name={organization.name}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={handleLogoClick}
          cursor="pointer"
        />
        {isHovering && (
          <IconButton
            aria-label="Edit logo"
            icon={<EditIcon />}
            size="sm"
            position="absolute"
            bottom="0"
            right="0"
            onClick={handleLogoClick}
          />
        )}
        <Input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          display="none"
          onChange={handleFileChange}
        />
      </Box>
      <VStack spacing={1}>
        <Text fontSize="2xl" fontWeight="bold">
          {organization.name}
        </Text>
        <Text fontSize="md" color="gray.500">
          {organization.domain}
        </Text>
      </VStack>
    </Flex>
  );
};

export default OrganizationHeading;
