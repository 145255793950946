import { DeleteIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import axios from "axios";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { TASK_BASE_URL as baseUrl } from "../../../baseurls";

const DeleteTask = ({
  selectedMyTasks,
  setSelectedMyTasks,
  allTasks,
  setAllTasks,
  fromProjectPage,
}) => {
  const { myTasks, reportTasks, setMyTasks, setReportTasks, deleteTasks } =
    UserState();
  const { accessToken, reloadUserMetrics } = AuthState();
  const toast = useToast();

  const handleDeleteMyTasks = async () => {
    /**
     * API to delete all selectedMyTasks from DB
     */

    const taskIds = selectedMyTasks?.map((task) => task.id);
    if (taskIds.length === 0) {
      return;
    }

    try {
      await deleteTasks(taskIds);
      const newMyTasks = [];

      if (myTasks && Array.isArray(myTasks) && myTasks.length > 0) {
        // for (const selectedTask of selectedMyTasks) {
        //   if (!myTasks.find((task) => selectedTask.id === task.id)) {
        //     newMyTasks.push(selectedTask);
        //   }
        // }

        for (const task of myTasks) {
          if (
            !selectedMyTasks?.find(
              (selectedTask) => selectedTask.id === task.id
            )
          ) {
            newMyTasks.push(task);
          }
        }
      }

      const newReportTasks = [];

      if (reportTasks && Array.isArray(reportTasks) && reportTasks.length > 0) {
        // for (const selectedTask of selectedMyTasks) {
        //   if (!reportTasks.find((task) => selectedTask.id === task.id)) {
        //     reportTasks.push(selectedTask);
        //   }
        // }

        for (const task of reportTasks) {
          if (
            !selectedMyTasks?.find(
              (selectedTask) => selectedTask.id === task.id
            )
          ) {
            newReportTasks.push(task);
          }
        }
      }

      const newAllTasks = [];

      if (allTasks && Array.isArray(allTasks) && allTasks.length > 0) {
        // for (const selectedTask of selectedMyTasks) {
        //   if (!reportTasks.find((task) => selectedTask.id === task.id)) {
        //     reportTasks.push(selectedTask);
        //   }
        // }

        for (const task of allTasks) {
          if (
            !selectedMyTasks?.find(
              (selectedTask) => selectedTask.id === task.id
            )
          ) {
            newAllTasks.push(task);
          }
        }
      }

      toast({
        title: "Tasks deleted successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      if (newReportTasks?.length === 0) {
        setReportTasks(null);
      } else {
        setReportTasks(newReportTasks);
      }
      if (newMyTasks?.length === 0) {
        setMyTasks(null);
      } else {
        setMyTasks(newMyTasks);
      }

      if (fromProjectPage) {
        if (newAllTasks?.length === 0) {
          setAllTasks(null);
        } else {
          setAllTasks(newMyTasks);
        }
      }
    } catch (err) {
      toast({
        title: "Exception occurred while deleting tasks (none deleted).",
        description: `${err.message}`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setSelectedMyTasks(null);
  };

  useEffect(() => {
    // // console.log(selectedMyTasks);
    // // console.log(selectedMyTasks.length);
  }, [selectedMyTasks]);

  return (
    <Button
      colorScheme="red"
      leftIcon={<DeleteIcon />}
      onClick={handleDeleteMyTasks}
      // borderRadius={"20px"}
      size={"sm"}
    >
      Delete
    </Button>
  );
};

export default DeleteTask;
