import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
// import ReporterDropDown from "../../Miscellaneous/Dropdowns/ReporterDropDown";

const CreateMilestoneModal = ({ isOpen, onClose, project, setMilestones }) => {
  const { selectedOrganization, createMilestone } = UserState();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    // reviewerId: "",
    projectId: "",
    orgId: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  // const {
  //   isOpen: isReporterDropDownOpen,
  //   onOpen: openReporterDropDown,
  //   onClose: closeReporterDropDown,
  // } = useDisclosure();

  const modifiedOnClose = () => {
    onClose();
    setFormData({
      title: "",
      description: "",
      // reviewerId: "",
      projectId: "",
      orgId: "",
    });
  };

  const validate = () => {
    const errors = {};
    if (!formData.title) {
      errors.title = "Title is required";
    } else if (formData.title.length > 50) {
      errors.title = "Title must be less than 50 characters";
    }
    if (!formData.description) errors.description = "Description is required";
    // if (!formData.reviewerId) errors.reviewerId = "Reporter is required";

    return errors;
  };

  // const setReviewerId = (value) => {
  //   setFormData((data) => ({ ...data, reviewerId: value }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const addMilestone = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      if (!formData.projectId || !formData.orgId) {
        toast({
          title: "Couldn't create milestone, try again later.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      const newMilestone = await createMilestone(formData);
      setMilestones((milestones) =>
        milestones && milestones.length > 0
          ? [...milestones, newMilestone]
          : [newMilestone]
      );
      modifiedOnClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project && selectedOrganization) {
      setFormData((data) => ({
        ...data,
        projectId: project.id,
        orgId: selectedOrganization.id,
      }));
    }
  }, [project, selectedOrganization]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>Create New Milestone</ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody display={"flex"} flexDir={"column"} gap={3}>
          <FormControl id="milestone-title" isRequired isInvalid={errors.title}>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Milestone Title"
              name="title"
              onChange={handleChange}
              value={formData.title}
            />
            {errors.title && (
              <FormErrorMessage>{errors.title}</FormErrorMessage>
            )}
            <FormHelperText>
              Title must be less than 50 characters
            </FormHelperText>
          </FormControl>
          <FormControl
            id="milestone-description"
            isRequired
            isInvalid={errors.description}
          >
            <FormLabel>Description</FormLabel>
            <Input
              placeholder="Milestone Description"
              name="description"
              onChange={handleChange}
              value={formData.description}
            />
            {errors.description && (
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            )}
            <FormHelperText>
              Summarize the objective of the milestone
            </FormHelperText>
          </FormControl>
          {/* <FormControl isRequired>
            <FormLabel>Reporter</FormLabel>
            <ReporterDropDown
              onClose={closeReporterDropDown}
              onOpen={openReporterDropDown}
              isOpen={isReporterDropDownOpen}
              setReviewerId={setReviewerId}
              projectId={project?.id}
              assigneeId={null}
            />
          </FormControl> */}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={addMilestone}>
            Add Milestone
          </Button>
          <Button variant="ghost" onClick={modifiedOnClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateMilestoneModal;
