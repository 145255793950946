"use client";

import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SideBarContent from "./SideBarContent";

const PermanentSideBar = () => {
  const [width] = useState(200);
  const [leaveSpaceAbove, setLeaveSpaceAbove] = useState(55);

  const handleResize = () => {
    const space =
      document.getElementsByClassName("header-container")[0]?.clientHeight;
    setLeaveSpaceAbove(space || 55);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      position="fixed"
      top={leaveSpaceAbove}
      left={0}
      width={`${width}px`}
      height={`calc(100% - ${leaveSpaceAbove}px)`}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      className="permanent-side-bar"
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      transition="all 0.3s ease-in-out"
    >
      <Box
        flex={1}
        overflowY="auto"
        pt={4}
        pb={1}
        px={3}
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.300",
            borderRadius: "24px",
          },
        }}
      >
        <SideBarContent />
      </Box>
    </Box>
  );
};

export default PermanentSideBar;
