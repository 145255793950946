import { Box, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import CurrentBandwidth from "./CurrentBandwidth";
import { LayoutState } from "../../../Context/LayoutProvider";
import CurrentTasks from "./CurrentTasks";
import CompletionRate from "./CompletionRate";
import CurrentTaskHours from "./CurrentTaskHours";
import TasksSuccessful from "./TasksSuccessful";
import TasksCompleted from "./TasksCompleted";
import TasksFailed from "./TasksFailed";
import SuccessRate from "./TaskSuccessRate";
import TotalTasks from "./TotalTasks";
import WeeklyHours from "./WeeklyHours";

const MetricSection = ({ metricRef }) => {
  const { isPhone } = LayoutState();
  const [spaceToLeave, setSpaceToLeave] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMetricSectionClick = (e) => {
    if (location.pathname !== "/metrics") {
      navigate("/metrics");
    }
  };

  useEffect(() => {
    if (location.pathname === "/metrics") {
      return;
    }
    const headerContainer =
      document.getElementsByClassName("header-container")[0];
    setSpaceToLeave(headerContainer.clientHeight + 180);
  }, []);

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      boxShadow={"1px 1px 4px 1px gray"}
      bgColor={"#ececec"}
      borderRadius={"10px"}
      margin={2}
      padding={2}
      onClick={handleMetricSectionClick}
      ref={metricRef}
      _hover={{ cursor: "pointer" }}
    >
      <Box
        display={"flex"}
        flexDir={"row"}
        justifyContent={"start"}
        alignContent={"flex-start"}
        // maxHeight={"600px"}
        height={`min(600px, calc(100vh - ${spaceToLeave}px))`}
        // height={"auto"}
      >
        <Box
          // flex={1}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"start"}
          flexDir={"column"}
          flexWrap={"wrap"}
          gap={2}
          overflow={"scroll"}
          height={"100%"}
          maxHeight={"600px"}
        >
          <CurrentBandwidth />
          <CurrentTasks />
          <CompletionRate />
          <TotalTasks />
          <WeeklyHours />
          <SuccessRate />
          <CurrentTaskHours />
          <TasksSuccessful />
          <TasksCompleted />
          <TasksFailed />
        </Box>
      </Box>
      {location.pathname !== "/metrics" && (
        <Text textAlign={"center"} color={"gray"} fontSize={"sm"} marginTop={4}>
          Click here to navigate to metrics page
        </Text>
      )}
    </Box>
  );
};

export default MetricSection;
