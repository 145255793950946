import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  useDisclosure,
  PopoverArrow,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import { IoIosArrowDropdown, IoMdArrowDropdown } from "react-icons/io";
import { Droplet } from "lucide-react";

const OrganizationMemberRoleDropDown = ({
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  member,
  setMembers,
  clickedMember = null,
  forUpdation = false,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  // Use external or internal state and functions
  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const {
    selectedOrganization,
    selectedOrganizationRoles: roles,
    updateMemberRole,
  } = UserState();
  const popoverRef = useRef(null);
  const [role, setRole] = useState({ id: 0, name: "member", value: "Member" });

  const changeMemberRole = async (newRole) => {
    try {
      await updateMemberRole(member.firebaseUserId, newRole?.value);
      setRole({ ...newRole });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRoleClick = (e, obj) => {
    e?.stopPropagation();
    onClose();

    if (role?.id === obj.id) {
      return;
    }

    if (forUpdation) {
      changeMemberRole(obj);
    } else {
      setRole({ ...obj });
    }
  };

  useEffect(() => {
    if (
      clickedMember &&
      clickedMember.firebaseUserId !== member.firebaseUserId
    ) {
      onClose();
    }
  }, [clickedMember]);

  useEffect(() => {
    setMembers((members) => {
      const updated = [];

      for (const obj of members) {
        if (obj.firebaseUserId === member.firebaseUserId) {
          updated.push({ ...member, role: role?.value });
        } else {
          updated.push({ ...obj });
        }
      }

      return updated;
    });
  }, [role]);

  useEffect(() => {
    if (member.orgs) {
      const memberRole = member.orgs[selectedOrganization.id];
      const roleObj = roles.find((role) => role?.value === memberRole);
      setRole(roleObj);
    } else if (member.role) {
      const memberRole = member.role;
      const roleObj = roles.find((role) => role?.value === memberRole);
      setRole(roleObj);
    } else {
      setRole(roles[0]);
    }
  }, []);

  return (
    <Popover onOpen={onOpen} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          width={"90px"}
          borderRadius={0}
          textAlign={"left"}
          justifyContent={"space-between"}
          size={"sm"}
          px={1}
          m={0}
          bgColor={"transparent"}
          rightIcon={<IoMdArrowDropdown size={"1em"} />}
          _hover={{ bg: "transparent" }}
        >
          <Text size={"lg"}>{role?.value}</Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        width={"100%"}
        border={"1px solid #ececec"}
        boxShadow={"0px 0px 10px 3px rgba(0, 0, 0, 0.2)"}
      >
        <PopoverArrow />
        <PopoverBody maxHeight={"250px"} overflow={"scroll"}>
          <div ref={popoverRef}>
            {roles.map((obj) => {
              return (
                <Box
                  key={obj.id}
                  marginY={0}
                  _hover={{ cursor: "pointer", color: "blue" }}
                  textAlign={"left"}
                  paddingY={1}
                  onClick={(e) => handleRoleClick(e, obj)}
                >
                  {obj.value}
                </Box>
              );
            })}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default OrganizationMemberRoleDropDown;
