export const findIndexOfTask = (task, tasks) => {
  let i = 0;
  for (; i < tasks?.length; i++) {
    if (tasks[i]?.id === task?.id) {
      return i;
    }
  }

  return -1;
};
