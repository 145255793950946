import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ProfileSetting from "./ProfileSettings/ProfileSetting";
import { LayoutState } from "../../Context/LayoutProvider";

const SettingExpanded = ({ setSelectedSetting, selectedSetting }) => {
  const { screenWidth } = LayoutState();
  const [height, setHeight] = useState("auto");
  const componentRef = useRef(null);

  const updateHeight = () => {
    if (componentRef.current) {
      const topOffset = componentRef.current.getBoundingClientRect().top;

      const remainingHeight = window.innerHeight - topOffset - 10;

      setHeight(`${remainingHeight}px`);
    }
  };

  const settingWindow = {
    profile: <ProfileSetting />,
  };

  useEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <Box
      ref={componentRef}
      display={screenWidth < 580 && !selectedSetting ? "none" : "flex"}
      flexDir={"column"}
      height={height}
      width={"100% !important"}
      overflow={"scroll !important"}
      flex={1}
      marginTop={screenWidth < 580 ? "15px" : 0}
      marginLeft={screenWidth < 580 ? "10px" : 0}
      marginRight={screenWidth < 500 ? "3px" : 2}
      marginBottom={"10px"}
      paddingLeft={screenWidth < 580 ? "10px" : "25px"}
      paddingRight={screenWidth < 580 ? "10px" : "25px"}
      paddingTop={screenWidth < 580 ? "10px" : "20px"}
      bgColor={"inherit"}
    >
      {selectedSetting ? (
        <Box height={"100%"} flex={1} display={"flex"} flexDir={"column"}>
          {settingWindow[selectedSetting]}
        </Box>
      ) : (
        <Text
          width={"100% !important"}
          height={"100% !important"}
          display={"flex"}
          justifyContent={"center"}
          marginRight={"20px"}
          padding={"90px"}
        >
          Select an Option to Configure
        </Text>
      )}
    </Box>
  );
};

export default SettingExpanded;
