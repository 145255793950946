import React from "react";
import {
  Box,
  Flex,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

export default function Component() {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.100");

  return (
    <Box
      id="loading-component"
      flex={1}
      height="100%"
      width="100%"
      bg={bgColor}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        textAlign="center"
        p={8}
      >
        <VStack spacing={8}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#6aa4ca"
            size="xl"
          />
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={textColor}
            animation={`${pulse} 2s infinite ease-in-out`}
          >
            Loading your data...
          </Text>
          <Box>
            <Text fontSize="md" color="gray.500" mb={2}>
              We're preparing everything for you
            </Text>
            <Flex justify="center" align="center">
              {[0, 1, 2].map((index) => (
                <Box
                  key={index}
                  width="12px"
                  height="12px"
                  borderRadius="full"
                  bg={
                    index === 0
                      ? "#a67db7"
                      : index === 1
                      ? "#a57cb6"
                      : "#6aa4ca"
                  }
                  mx={1}
                  animation={`${pulse} 1.5s infinite ease-in-out ${
                    index * 0.3
                  }s`}
                />
              ))}
            </Flex>
          </Box>
        </VStack>
      </Flex>
    </Box>
  );
}
