import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/DashboardComponents/Header";
import { Box, useDisclosure } from "@chakra-ui/react";
import Main from "../Components/DashboardComponents/Main";
import SideBar from "../Components/DashboardComponents/SideBar";
import { UserState } from "../Context/UserProvider";
import { AuthState } from "../Context/AuthProvider";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { organizations, selectedOrganization, organizationMembers } =
    UserState();
  const { user, reloadUser } = AuthState();
  const myTasksRef = useRef(null);
  const myReportsRef = useRef(null);
  const metricsRef = useRef(null);
  const reloadUserCountRef = useRef(0);

  const scrollToMyTasks = () => {
    if (myTasksRef.current) {
      myTasksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToMyReports = () => {
    if (myReportsRef.current) {
      myReportsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToMetrics = () => {
    if (metricsRef.current) {
      metricsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (
      organizations &&
      user?.orgs &&
      organizations.length !== Object.keys(user.orgs).length
    ) {
      console.log("this");
      if (reloadUserCountRef.current < 1) {
        reloadUser();
        reloadUserCountRef.current++;
        setTimeout(() => {
          reloadUserCountRef.current = 0;
        }, 3000);
      }
    }
  }, [organizations]);

  return (
    <Box
      style={{ all: "unset" }}
      position={"fixed"}
      top={0}
      left={0}
      width={"100vw"}
    >
      {organizations && selectedOrganization && organizationMembers && (
        <Box
          display="flex"
          maxWidth={"100vw"}
          minHeight={"100vh"}
          width={"100vw"}
          flexDirection={"column"}
        >
          <Header
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            scrollToMyTasks={scrollToMyTasks}
            scrollToMyReports={scrollToMyReports}
            scrollToMetrics={scrollToMetrics}
          />
          <Box
            className="post-header"
            maxHeight={`100vh`}
            overflow={"none"}
            flex={1}
            margin={0}
            padding={0}
            display={"flex"}
            flexDirection={"row"}
          >
            <SideBar onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
            <Main
              isOpen={isOpen}
              onClose={onClose}
              myReportsRef={myReportsRef}
              myTasksRef={myTasksRef}
              metricsRef={metricsRef}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
