import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from "@chakra-ui/react";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";

const ViewMilestoneModal = ({
  isOpen,
  onClose,
  milestone,
  setMilestone,
  setMilestones,
}) => {
  const { isPhone } = LayoutState();
  const { modifyMilestone } = UserState();
  const [fields, setFields] = useState({
    title: milestone.title,
    description: milestone.description,
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [id]: value,
    }));
  };

  const updateMilestone = async () => {
    if (
      fields.title === milestone.title &&
      fields.description === milestone.description
    ) {
      return;
    }

    try {
      setLoading(true);
      await modifyMilestone(milestone.id, fields);
      toast({
        title: "Milestone updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error occurred",
        description: "Couldn't update milestone",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setMilestones((currentMilestones) =>
      currentMilestones.map((currentMilestone) =>
        currentMilestone.id === milestone.id
          ? { ...currentMilestone, ...fields }
          : currentMilestone
      )
    );

    updateMilestone();
    onClose();
  };

  // useEffect(() => {
  //   setMilestone((prevMilestone) => ({ ...prevMilestone, ...fields }));
  // }, [fields]);

  useEffect(() => {
    setFields({
      title: milestone.title,
      description: milestone.description,
    });
  }, [milestone]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent
        maxH="90vh"
        boxShadow="xl"
        borderRadius="lg"
        overflow="hidden"
      >
        <ModalHeader p={4} pb={0}>
          <Input
            id="title"
            value={fields.title}
            onChange={handleInputChange}
            fontSize={isPhone ? "2xl" : "3xl"}
            fontWeight="500"
            border="none"
            _focus={{ boxShadow: "none" }}
            placeholder="Milestone Title"
            autoFocus={false}
          />
        </ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody pt={2} display="flex" flexDirection="column" px={4}>
          <Tabs
            isFitted
            variant="enclosed"
            flex={1}
            display="flex"
            flexDirection="column"
          >
            <TabList>
              <Tab
                _selected={{ bg: "gray.100" }}
                _focus={{ boxShadow: "none" }}
              >
                Details
              </Tab>
              {/* <Tab
                _selected={{ bg: "gray.100" }}
                _focus={{ boxShadow: "none" }}
              >
                Tasks
              </Tab> */}
            </TabList>
            <TabPanels
              flex={1}
              overflowY="auto"
              overflowX="hidden"
              bg="gray.100"
            >
              <TabPanel>
                <VStack spacing={6} align="stretch">
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      id="description"
                      value={fields.description}
                      onChange={handleInputChange}
                      minH="150px"
                      bg="white"
                      placeholder="Milestone description"
                    />
                  </FormControl>
                  <HStack justify="space-between">
                    <Text fontSize="sm" color="gray.600">
                      Created:{" "}
                      {new Date(milestone.createdAt).toLocaleDateString()}
                    </Text>
                    {milestone.completedAt && (
                      <Text fontSize="sm" color="green.600">
                        Completed:{" "}
                        {new Date(milestone.completedAt).toLocaleDateString()}
                      </Text>
                    )}
                  </HStack>
                </VStack>
              </TabPanel>
              {/* <TabPanel>
                <VStack spacing={4} align="stretch">
                  <HStack justify="space-between">
                    <Text fontWeight="bold">
                      Tasks: {milestone.tasks.length}
                    </Text>
                    <Text fontWeight="bold" color="green.500">
                      Completed: {milestone.doneTasks}
                    </Text>
                  </HStack>
                  <Box maxH="300px" overflowY="auto">
                    {milestone.tasks.map((task, index) => (
                      <Box
                        key={index}
                        p={2}
                        bg={task.status === "done" ? "green.100" : "white"}
                        borderRadius="md"
                        mb={2}
                      >
                        <Text>{task.title}</Text>
                      </Box>
                    ))}
                  </Box>
                </VStack>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewMilestoneModal;
