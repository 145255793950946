import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const NewMembersEmailForm = ({
  emailsText,
  setEmailsText,
  errorMessage,
  setErrorMessage,
  emailsTextRef,
}) => {
  useEffect(() => {
    if (emailsTextRef?.current) {
      if (!emailsText) {
        emailsTextRef.current.text = "";
      } else {
        emailsTextRef.current.text = emailsText;
      }
    }
  }, [emailsText]);

  return (
    <FormControl
      isInvalid={
        !errorMessage?.message1 &&
        !errorMessage?.message2 &&
        !errorMessage.message3
          ? false
          : true
      }
    >
      <Textarea
        placeholder="Enter new member emails here."
        minHeight={"150px"}
        ref={emailsTextRef}
        value={emailsText}
        onChange={(e) => {
          setEmailsText(e.target.value);
          setErrorMessage({ message1: "", message2: "", message3: "" });
        }}
      />
      <FormErrorMessage>
        <VStack textAlign={"left"} alignItems={"start"}>
          {Object.values(errorMessage).map((message) => {
            if (message) {
              return (
                <Box key={message} textAlign={"left"} overflowX={"scroll"}>
                  {message}
                  <br />
                </Box>
              );
            } else {
              return <></>;
            }
          })}
        </VStack>
      </FormErrorMessage>
      {!(
        errorMessage?.message1 ||
        errorMessage?.message2 ||
        errorMessage?.message3
      ) && (
        <FormHelperText>
          Paste a list of emails seperated by commas (as a string).
          <br />
          <br />
          Example:
          <br /> person1@org.com, person2@org.com, person3@org.com
          <br />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default NewMembersEmailForm;
