import { Box, Text, Divider } from "@chakra-ui/react";
import React, { useState } from "react";
import { ImStatsBars } from "react-icons/im";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleDashboardIconClick = (e) => {
    navigate("/dashboard");
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
        fontSize={"16px"}
        border={"transparent"}
        borderBottom={"1px solid"}
        borderColor={"gray.300"}
        paddingY={2}
        _hover={{ bg: "gray.50", cursor: "pointer" }}
        _expanded={{ bg: "gray.50", fontWeight: "semibold" }}
        borderRadius="md"
        onClick={handleDashboardIconClick}
        transition="all 0.2s"
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          paddingRight={5}
          gap={2}
          paddingLeft={4}
        >
          <ImStatsBars />
          <Text textAlign={"center"}>Dashboard</Text>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
