import React from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoPerson } from "react-icons/io5";
import { LayoutState } from "../../Context/LayoutProvider";

const SettingsPanel = ({ setSelectedSetting, selectedSetting }) => {
  const { screenWidth } = LayoutState();
  const settingOptions = [
    { name: "Profile", value: "profile", icon: IoPerson },
  ];

  const bgColor = "inherit";
  const hoverBgColor = useColorModeValue("white", "gray.800");
  const selectedBgColor = useColorModeValue("gray.200", "gray.700");

  const handleClick = (value) => {
    setSelectedSetting(value);
  };

  return (
    <VStack
      minWidth="200px"
      flex={screenWidth < 580 ? 1 : "none"}
      display={screenWidth < 580 && selectedSetting ? "none" : "flex"}
      spacing={2}
      align="stretch"
      bg={bgColor}
      boxShadow="md"
      p={4}
    >
      {settingOptions.map((option) => (
        <Box
          key={option.value}
          bg={
            option.value === selectedSetting ? selectedBgColor : "transparent"
          }
          borderRadius="md"
          transition="all 0.3s"
          _hover={{
            bg: hoverBgColor,
            transform: "translateY(-2px)",
            boxShadow: "sm",
          }}
        >
          <Button
            width="100%"
            height="auto"
            py={3}
            justifyContent="flex-start"
            bg="transparent"
            _hover={{ bg: "transparent" }}
            onClick={() => handleClick(option.value)}
            leftIcon={
              <Icon
                as={option.icon}
                boxSize={5}
                color={
                  option.value === selectedSetting ? "black.500" : "gray.500"
                }
              />
            }
          >
            <Text
              fontSize="lg"
              fontWeight={option.value === selectedSetting ? "bold" : "medium"}
              color={option.value === selectedSetting ? "black.500" : "inherit"}
            >
              {option.name}
            </Text>
          </Button>
        </Box>
      ))}
    </VStack>
  );
};

export default SettingsPanel;
