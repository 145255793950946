import React from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  useColorModeValue,
  Flex,
  Badge,
  Avatar,
  AvatarGroup,
  Icon,
  SimpleGrid,
  Divider,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import {
  InfoIcon,
  CalendarIcon,
  CheckCircleIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { FaUsers } from "react-icons/fa";
import { UserState } from "../../../../Context/UserProvider";
import { EditIcon } from "lucide-react";

const InfoCard = ({ title, value, icon }) => {
  const cardBg = useColorModeValue("white", "gray.700");
  const cardBorder = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={cardBorder}
      borderRadius="lg"
      p={4}
      shadow="md"
      transition="all 0.3s"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
      flex={1}
    >
      <Flex align="center" mb={2}>
        <Icon as={icon} boxSize={6} mr={2} color="blue.500" />
        <Text fontWeight="bold" fontSize="lg">
          {title}
        </Text>
      </Flex>
      <Text fontSize="md">{value}</Text>
    </Box>
  );
};

const Overview = ({ project, projectMembers }) => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  const { selectedOrganization } = UserState();

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Box
      borderRadius="lg"
      bg={bgColor}
      color={textColor}
      p={6}
      height="100%"
      width="100%"
    >
      <VStack align="stretch" spacing={6}>
        <Flex justify="space-between" align="center">
          <Box width={"100%"}>
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Heading size="lg" _hover={{ cursor: "pointer" }}>
                {project.projectName}
              </Heading>
              <Button
                leftIcon={<EditIcon size={"1em"} />}
                colorScheme="blue"
                size={"sm"}
              >
                Edit Project
              </Button>
            </Flex>
            <Flex align="center" mt={2}>
              <Tooltip label={`Project Acronym`} _hover={{ cursor: "pointer" }}>
                <Badge
                  colorScheme="purple"
                  fontSize="sm"
                  mr={2}
                  _hover={{ cursor: "pointer" }}
                >
                  {project.proAcronym}
                </Badge>
              </Tooltip>
              <Tooltip label={`Latest Task Sequence Number`}>
                <Badge
                  fontSize="sm"
                  color="gray.500"
                  _hover={{ cursor: "pointer" }}
                >
                  #{project.sequenceNumber}
                </Badge>
              </Tooltip>
            </Flex>
          </Box>
        </Flex>

        <Text>{project.description}</Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <InfoCard
            title="Created On"
            value={formatDate(project.createdAt)}
            icon={CalendarIcon}
          />
          <InfoCard
            title="Team Members"
            value={project.members.length}
            icon={FaUsers}
          />
          <InfoCard
            title="Total Sprints"
            value={project.sprintNumber}
            icon={InfoIcon}
          />
        </SimpleGrid>

        <Box>
          <Heading size="md" mb={4}>
            Team
          </Heading>
          <AvatarGroup size="md" _hover={{ cursor: "pointer" }} max={8}>
            {projectMembers.map((member, index) => (
              <Tooltip
                key={index}
                label={`${member?.firstName} ${member?.lastName}`}
                placement="bottom"
              >
                <Avatar
                  key={index}
                  name={`${member?.firstName} ${member?.lastName}`}
                  src={member?.imageUrl || ""}
                  title={`${member?.firstName} ${member?.lastName}`}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        </Box>

        <Divider />

        <Flex justify="space-between" align="center">
          <Text fontSize="sm" color="gray.500"></Text>
          <Tooltip label={`Organization`}>
            <Badge _hover={{ cursor: "pointer" }} bg={"transparent"}>
              <Box display={"flex"} alignItems={"center"} gap={3}>
                <Avatar
                  name={selectedOrganization?.name || ""}
                  src={selectedOrganization?.logoUrl || ""}
                  size="sm"
                />
                <Text colorScheme="blue" fontSize="3xl">
                  {selectedOrganization?.name || ""}
                </Text>
              </Box>
            </Badge>
          </Tooltip>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Overview;
