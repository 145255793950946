import { DeleteIcon, StarIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton, Text } from "@chakra-ui/react";
import React, { useState } from "react";

const SkillItem = ({ name, value, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      id={name}
      key={`${name}-${value}`}
      name={name}
      paddingX={2}
      paddingY={1}
      bgColor="#ececec"
      //   color="white"
      margin={1}
      display="flex"
      flexDir="row"
      width="100%"
      maxWidth="100px"
      height={"30px"}
      borderRadius="md"
      border={"1px solid gray"}
      whiteSpace="nowrap"
      overflowX="hidden"
      textOverflow="ellipsis"
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      tabIndex={0}
      role="listitem"
      aria-label={`Skill: ${name}, Value: ${value}`}
    >
      <Text flex={1} overflow="hidden" textOverflow="ellipsis">
        {name}
      </Text>
      {isHovered ? (
        <Button
          leftIcon={<DeleteIcon />}
          m={0}
          px={1}
          size="xs"
          variant="ghost"
          colorScheme="red"
          aria-label={`Delete ${name} skill`}
          name={name}
          onClick={onDelete}
        >
          Delete
        </Button>
      ) : (
        <Box display="flex" fontSize="xs" alignItems="center" marginLeft={1}>
          <Text fontSize="xs" marginRight={1}>
            {value}
          </Text>
          <StarIcon boxSize={3} />
        </Box>
      )}
    </Box>
  );
};

export default SkillItem;
