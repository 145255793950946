import { Box, Divider, Heading, Image, Text } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { LayoutState } from "../../Context/LayoutProvider";
import { motion } from "framer-motion";

const glowKeyframes = keyframes`
  0% { box-shadow: 0 0 5px #3182ce; }
  50% { box-shadow: 0 0 20px #3182ce; }
  100% { box-shadow: 0 0 5px #3182ce; }
`;

const FeatureBox = ({ isActive, feature, setCurrentItem }) => {
  const { screenWidth } = LayoutState();
  const [progressWidth, setProgressWidth] = useState(0);
  const [isBeingHovered, setIsBeingHovered] = useState(false);

  useEffect(() => {
    if (isActive) {
      setProgressWidth(0);

      const intervalId = setInterval(() => {
        setProgressWidth((prevVal) => {
          if (isBeingHovered) {
            return prevVal;
          }
          if (prevVal < 100) {
            return prevVal + 1;
          } else {
            clearInterval(intervalId);
            return 100;
          }
        });
      }, 25);

      return () => clearInterval(intervalId);
    } else {
      setProgressWidth(0);
    }
  }, [isActive]);

  return (
    <Box
      as={motion.div}
      width="100%"
      minHeight="40px"
      display="flex"
      paddingX={6}
      onClick={() => setCurrentItem(feature.idx)}
      onMouseEnter={() => setIsBeingHovered(true)}
      onMouseLeave={() => setIsBeingHovered(false)}
      _hover={{ cursor: "pointer" }}
      flexDir="column"
      gap={4}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      borderRadius="lg"
      bg={isActive ? "rgba(49, 130, 206, 0.1)" : "transparent"}
      boxShadow={isActive ? "0 0 10px rgba(49, 130, 206, 0.3)" : "none"}
      backdropFilter={isActive ? "blur(5px)" : "none"}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="start"
        position="relative"
        gap={3}
      >
        <Divider
          orientation="vertical"
          background={`linear-gradient(180deg, #3182ce ${progressWidth}%, #A0AEC0 ${progressWidth}%)`}
          backgroundSize="100% 100%"
          backgroundPosition="left"
          width="2px"
          borderRadius="full"
          transition="all 0.3s ease"
        />
        <Box
          flex={1}
          display="flex"
          flexDir="column"
          justifyContent="start"
          alignItems="start"
          width="100%"
          paddingLeft={isActive ? 3 : 0}
          gap={3}
        >
          <Heading
            as={motion.h3}
            fontSize={isActive ? "20px" : "25px"}
            fontWeight="500"
            textShadow="1px 1px 3px rgba(0, 0, 0, 0.2)"
            textAlign="left"
            color={isActive ? "#3182ce" : "inherit"}
            transition="all 0.3s ease"
            whileHover={{ scale: 1.05 }}
          >
            {feature.title}
          </Heading>
          {isActive && (
            <Text
              as={motion.p}
              textAlign="left"
              fontSize="14px"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {feature.description}
            </Text>
          )}
        </Box>
      </Box>
      {screenWidth < 900 && isActive && feature.image && (
        <Box
          as={motion.div}
          display="flex"
          justifyContent="center"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Image
            width="80%"
            borderRadius="20px"
            src={feature.image}
            border="1px solid #3182ce"
            boxShadow="0 4px 20px rgba(49, 130, 206, 0.2)"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0 8px 30px rgba(49, 130, 206, 0.3)",
            }}
            animation={`${glowKeyframes} 2s infinite`}
          />
        </Box>
      )}
    </Box>
  );
};

export default FeatureBox;
