import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tooltip,
  Checkbox,
  Badge,
  Avatar,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, Search2Icon } from "@chakra-ui/icons";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { EllipsisVertical, CalendarDays, FilterIcon } from "lucide-react";
import { UserState } from "../../../Context/UserProvider";
import CreateMyTaskModal from "../../Miscellaneous/MyTasks/CreateMyTaskModal";
import ViewTaskModal from "../../Miscellaneous/MyTasks/ViewTaskModal";
import { LayoutState } from "../../../Context/LayoutProvider";
import { PriorityIcon } from "../../Miscellaneous/Dropdowns/PriorityDropDown";
import { taskStatusStyleLight } from "../../Miscellaneous/MyTasks/styles";
import { findName } from "../../Miscellaneous/MyTasks/utils";

const SprintBox = ({
  viewingProject = null,
  sprint = null,
  setSprint = () => {},
  isActive = false,
  isBacklog = false,
  loading = false,
  isAnySprintActive = false,
}) => {
  const {
    startSprint: onStart,
    stopSprint: onStop,
    deleteSprint: onDelete,
    deleteTasks,
    organizationMembers,
  } = UserState();
  const { screenWidth } = LayoutState();
  const {
    isOpen: isCreateTaskModalOpen,
    onOpen: openCreateTaskModal,
    onClose: closeCreateTaskModal,
  } = useDisclosure();
  const {
    isOpen: isViewModalOpen,
    onOpen: openViewModal,
    onClose: closeViewModal,
  } = useDisclosure();
  const [viewModalTask, setViewModalTask] = useState(null);
  const [totalDuration, setTotalDuration] = useState(0);
  const [sprintTasks, setSprintTasks] = useState(sprint?.tasks || []);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loadingDeleteTasks, setLoadingDeleteTasks] = useState(false);

  // New state for filters
  const [taskNameFilter, setTaskNameFilter] = useState("");
  const [assigneeFilter, setAssigneeFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const taskBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("gray.50", "gray.900");

  const isSelected = (task) =>
    selectedTasks?.some((selectedTask) => selectedTask.id === task.id);

  const handleTaskSelection = (task) => {
    setSelectedTasks((prevSelectedTasks) =>
      isSelected(task)
        ? prevSelectedTasks.filter((t) => t.id !== task.id)
        : [...prevSelectedTasks, task]
    );
  };

  const handleTasksDeletion = async () => {
    const taskIds = selectedTasks?.map((task) => task.id);
    if (taskIds.length === 0) {
      return;
    }

    try {
      setLoadingDeleteTasks(true);
      await deleteTasks(taskIds);

      setSprintTasks((sprintTasks) =>
        sprintTasks.filter((task) => !taskIds.includes(task.id))
      );

      setSelectedTasks([]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDeleteTasks(false);
    }
  };

  useEffect(() => {
    if (sprintTasks?.length > 0) {
      let totalDuration = 0;
      sprintTasks?.forEach((task) => {
        totalDuration += parseFloat(task.duration);
      });
      setTotalDuration(totalDuration);
    }
  }, [sprintTasks]);

  useEffect(() => {
    setSprintTasks(sprint?.tasks || []);
  }, [sprint]);

  // Filter tasks based on the applied filters
  const filteredTasks = sprintTasks?.filter((task) => {
    return (
      (task?.title?.toLowerCase().includes(taskNameFilter?.toLowerCase()) ||
        task?.task_uid
          ?.toLowerCase()
          .includes(taskNameFilter?.toLowerCase())) &&
      (assigneeFilter === "" || task?.assigneeId === assigneeFilter) &&
      (priorityFilter === "" || task?.priority === priorityFilter) &&
      (statusFilter === "" || task?.status === statusFilter)
    );
  });

  const FilterPopover = () => (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button leftIcon={<FilterIcon size="1em" />} size="md" minW="90px">
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <VStack spacing={2}>
            <Select
              key={`sprint-box-${sprint?.id}-task-name-filter`}
              placeholder="Assignee"
              value={assigneeFilter}
              onChange={(e) => setAssigneeFilter(e.target.value)}
            >
              {organizationMembers.map((member) => (
                <option
                  key={`sprint-box-filter-assignee-${member.id}`}
                  value={member.firebaseUserId}
                >
                  {member?.firstName?.toUpperCase()}{" "}
                  {member?.lastName?.toUpperCase()}
                </option>
              ))}
            </Select>
            <Select
              key={`sprint-box-${sprint?.id}-task-priority-filter`}
              placeholder="Priority"
              value={priorityFilter}
              onChange={(e) => setPriorityFilter(e.target.value)}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </Select>
            <Select
              key={`sprint-box-${sprint?.id}-task-status-filter`}
              placeholder="Status"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="toDo">To Do</option>
              <option value="inProgress">In Progress</option>
              <option value="blocked">Blocked</option>
              <option value="review">Review</option>
              <option value="done">Done</option>
            </Select>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return (
    <Box
      id={sprint?.id}
      key={sprint?.id}
      className="sprint-box-main"
      mb={isBacklog && 3}
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      p={4}
      px={2}
      boxShadow="md"
      transition="all 0.3s"
      _hover={{ boxShadow: "lg" }}
    >
      {isCreateTaskModalOpen && (
        <CreateMyTaskModal
          isOpen={isCreateTaskModalOpen}
          onClose={closeCreateTaskModal}
          project={viewingProject}
          setAllTasks={setSprintTasks}
          providedSprintId={sprint?.id}
        />
      )}
      {isViewModalOpen && (
        <ViewTaskModal
          isOpen={isViewModalOpen}
          onClose={() => {
            closeViewModal();
            setViewModalTask(null);
          }}
          task={viewModalTask}
          setTask={setViewModalTask}
          allTasks={sprintTasks}
          setAllTasks={setSprintTasks}
        />
      )}
      {!loading ? (
        <>
          <Flex justify="space-between" alignItems="center" mb={1} px={2}>
            <HStack spacing={2} alignItems="center" display={"flex"}>
              <Heading size="md" color="black">
                {sprint?.name}
              </Heading>
              {!isBacklog && (
                <Text color="gray.600" fontSize="sm">
                  #{sprint?.num}
                </Text>
              )}
            </HStack>
            <HStack spacing={2} align={"center"}>
              {isActive && !isBacklog && (
                <Box
                  width="10px"
                  height="10px"
                  borderRadius="full"
                  bg="green.400"
                />
              )}
              <Tooltip
                label={`Number of Tasks`}
                placement="top"
                _hover={{ cursor: "pointer" }}
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="gray.100"
                  color="black"
                  borderRadius="full"
                  p={2}
                >
                  <Text
                    fontSize="xs"
                    color="black"
                    _hover={{ cursor: "pointer" }}
                  >
                    {filteredTasks.length} {screenWidth > 400 && "tasks"}
                  </Text>
                </Flex>
              </Tooltip>

              <Tooltip
                label={`Total Sprint Task Duration`}
                placement="top"
                _hover={{ cursor: "pointer" }}
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="gray.100"
                  color="black"
                  borderRadius="full"
                  p={2}
                  minWidth="32px"
                >
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    _hover={{ cursor: "pointer" }}
                  >
                    {totalDuration}h
                  </Text>
                </Flex>
              </Tooltip>

              {selectedTasks.length === 0 ? (
                <Tooltip label={"Add Task"} hasArrow>
                  <IconButton
                    aria-label="Create Task"
                    icon={<AddIcon />}
                    size="sm"
                    variant="ghost"
                    color="black"
                    onClick={openCreateTaskModal}
                    _hover={{ bg: "whiteAlpha.200" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip label={"Delete Tasks"} hasArrow>
                  <IconButton
                    p={3}
                    bgColor={"red"}
                    aria-label="Delete Tasks"
                    icon={<DeleteIcon />}
                    size="sm"
                    variant="ghost"
                    color="white"
                    onClick={handleTasksDeletion}
                    _hover={{ bg: "red.600" }}
                    isLoading={loadingDeleteTasks}
                  />
                </Tooltip>
              )}
              {!isBacklog && (
                <Popover placement="bottom-end">
                  <PopoverTrigger>
                    <IconButton
                      aria-label="More options"
                      icon={<EllipsisVertical />}
                      size="sm"
                      variant="ghost"
                      color="black"
                      _hover={{ bg: "whiteAlpha.200" }}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="auto">
                    <PopoverBody>
                      <VStack align="stretch" spacing={2}>
                        {!isBacklog && !isAnySprintActive && (
                          <Button
                            size="sm"
                            colorScheme="blue"
                            onClick={() =>
                              onStart(viewingProject?.id, sprint?.id)
                            }
                          >
                            Start Sprint
                          </Button>
                        )}
                        {isActive && (
                          <Button
                            size="sm"
                            colorScheme="green"
                            onClick={() => {
                              const undoneTasks = sprint?.tasks?.filter(
                                (t) => t.status !== "done"
                              );
                              const undoneTaskIds = undoneTasks?.map(
                                (t) => t.id
                              );
                              onStop(
                                viewingProject?.id,
                                sprint?.id,
                                undoneTaskIds
                              );
                            }}
                          >
                            Complete Sprint
                          </Button>
                        )}
                        {!isActive && (
                          <Button
                            leftIcon={<DeleteIcon />}
                            colorScheme="red"
                            size="sm"
                            onClick={() =>
                              onDelete(viewingProject?.id, sprint?.id)
                            }
                          >
                            Delete Sprint
                          </Button>
                        )}
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </HStack>
          </Flex>
          <HStack>
            {sprint?.startDate && sprint?.startDate !== "None" && (
              <HStack spacing={1} mb={1} color="black" px={2}>
                <CalendarDays size={16} />
                <Text fontSize="sm">{sprint?.startDate}</Text>
              </HStack>
            )}
            {sprint?.endDate && sprint?.endDate !== "None" && (
              <HStack spacing={1} mb={1}>
                <Text>to</Text>
                <HStack mb={1} color="black" px={2} spacing={1}>
                  <CalendarDays size={16} />
                  <Text fontSize="sm">{sprint?.endDate}</Text>
                </HStack>
              </HStack>
            )}
          </HStack>
          <Flex direction="column" mb={1} mx={2} gap={2}>
            <Flex gap={2} justify="space-between">
              <InputGroup width="100%">
                <InputLeftElement pointerEvents="none">
                  <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search task title / id"
                  value={taskNameFilter}
                  onChange={(e) => setTaskNameFilter(e.target.value)}
                />
              </InputGroup>
              <FilterPopover />
            </Flex>
          </Flex>
          <Droppable droppableId={sprint?.id}>
            {(provided, snapshot) => (
              <VStack
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={2}
                overflowY="scroll"
                maxHeight="350px"
                bg={snapshot.isDraggingOver ? "whiteAlpha.200" : "transparent"}
                borderRadius="md"
                transition="background-color 0.2s"
                p={2}
                px={1}
                className={`sprint-tasks ${sprint?.id}`}
              >
                {filteredTasks.map((task, index) => (
                  <Draggable
                    key={task?.id}
                    draggableId={task?.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        px={4}
                        py={3}
                        bg={isSelected(task) ? "purple.100" : taskBgColor}
                        borderWidth="1px"
                        borderColor={borderColor}
                        borderRadius="md"
                        boxShadow={snapshot.isDragging ? "lg" : "sm"}
                        _hover={{ boxShadow: "md" }}
                        transition="all 0.2s"
                        opacity={snapshot.isDragging ? 0.8 : 1}
                        width="100%"
                      >
                        <Flex align="center">
                          <Checkbox
                            isChecked={isSelected(task)}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleTaskSelection(task);
                            }}
                            mr={3}
                            colorScheme="purple"
                          />
                          <Tooltip label="Task ID" placement="top">
                            <Badge
                              fontSize="xs"
                              px={2}
                              py={1}
                              bgColor={taskStatusStyleLight[task?.status]}
                            >
                              {task?.task_uid
                                ? task.task_uid
                                : task.id.slice(-5)}
                            </Badge>
                          </Tooltip>
                          <Text
                            ml={2}
                            flex={1}
                            isTruncated
                            onClick={() => {
                              setViewModalTask(task);
                              openViewModal();
                            }}
                          >
                            {task.title}
                          </Text>

                          <Tooltip
                            label={`Duration: ${task.duration} hour`}
                            placement="top"
                          >
                            <Flex
                              ml={3}
                              alignItems="center"
                              justifyContent="center"
                              bg="gray.100"
                              color="gray.600"
                              borderRadius="full"
                              px={2}
                              py={1}
                              minWidth="32px"
                            >
                              <Text fontSize="xs" fontWeight="medium">
                                {task.duration}h
                              </Text>
                            </Flex>
                          </Tooltip>

                          <Box ml={2}>
                            <Avatar
                              name={findName(
                                task.assigneeId,
                                organizationMembers
                              )}
                              size={"xs"}
                              src={
                                organizationMembers?.find(
                                  (member) =>
                                    member.firebaseUserId === task.assigneeId
                                )?.imageUrl
                              }
                            />
                          </Box>

                          <Box ml={2}>
                            <Tooltip
                              label={
                                task?.priority?.toUpperCase() || "Priority"
                              }
                              placement="top"
                            >
                              <Badge style={{ all: "unset" }} p={2}>
                                <PriorityIcon priority={task.priority} />
                              </Badge>
                            </Tooltip>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </>
      ) : (
        <Text color="black">
          Loading{" "}
          {isActive ? "Active Sprint" : isBacklog ? "Backlog Sprint" : "Sprint"}
          ...
        </Text>
      )}
    </Box>
  );
};

export default SprintBox;
