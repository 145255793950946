import React from "react";
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  VStack,
  Text,
  HStack,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ChevronUpIcon } from "@chakra-ui/icons";

const taskStatusStyleLight = {
  done: "#C8E6C9",
  inProgress: "#F9E79F",
  blocked: "#FFC2C7",
  toDo: "#F5F5F5",
  review: "#B3E5FC",
};

export const PriorityIcon = ({ priority }) => {
  const config = {
    high: { color: "red.500" },
    medium: { color: "orange.400" },
    low: { color: "green.500" },
  };

  let color;
  if (config[priority]) {
    color = config[priority].color;
  } else {
    color = "gray.500";
  }

  return (
    <Box
      borderRadius="md"
      bg={`${color}.200`}
      width="15px"
      height="30px"
      overflow="hidden !important"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {priority === "high" ? (
        <ArrowLeftIcon color={color} transform="rotate(90deg)" boxSize={3} />
      ) : (
        <ChevronUpIcon color={color} boxSize={5} />
      )}
    </Box>
  );
};

const PriorityDropDown = ({
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  priority,
  children,
  setPriority,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const priorityObjs = [
    {
      key: 1,
      name: "high",
      value: "High",
      bgColor: taskStatusStyleLight.blocked,
    },
    {
      key: 2,
      name: "medium",
      value: "Medium",
      bgColor: taskStatusStyleLight.inProgress,
    },
    { key: 3, name: "low", value: "Low", bgColor: taskStatusStyleLight.done },
  ];

  const handlePriorityClick = (e, obj) => {
    e.stopPropagation();
    setPriority(obj.name);
    onClose();
  };

  return (
    <Popover onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        width="auto"
        boxShadow="lg"
        borderRadius="md"
        bg="white"
        mr={2}
      >
        <PopoverBody p={1}>
          <VStack spacing={1} align="stretch">
            {priorityObjs
              ?.filter((obj) => !(priority === obj.name))
              ?.map((obj) => (
                <HStack
                  key={obj.key}
                  px={3}
                  py={1}
                  borderRadius="md"
                  bg={obj.bgColor}
                  cursor="pointer"
                  transition="all 0.2s"
                  width={"117px"}
                  _hover={{ opacity: 0.8 }}
                  onClick={(e) => handlePriorityClick(e, obj)}
                >
                  <PriorityIcon priority={obj.name} />
                  <Text color="gray.800" fontSize={"sm"}>
                    {obj.value}
                  </Text>
                </HStack>
              ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PriorityDropDown;
