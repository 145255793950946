import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthState } from "../../../Context/AuthProvider";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { LayoutState } from "../../../Context/LayoutProvider";

const CompletionRate = () => {
  const { user } = AuthState();
  const { screenWidth } = LayoutState();
  const [completionRate, setCompletionRate] = useState(100);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.totalTasks > 0) {
      if (user.totalTasks < user.tasksCompleted) {
        setCompletionRate(100);
      } else {
        const rate = parseFloat(
          ((user.tasksCompleted * 100) / user.totalTasks).toFixed(2)
        );

        setCompletionRate(rate);
      }
    }
  }, [user]);

  return (
    <Card borderRadius={"25px"} boxShadow={"none"}>
      <CardHeader>
        <Heading size="md">Completion Rate</Heading>
      </CardHeader>

      <CardBody
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          width={
            screenWidth < 470
              ? screenWidth < 350
                ? "100px"
                : "150px"
              : "200px"
          }
          height={
            screenWidth < 470 ? (screenWidth < 350 ? "50px" : "75px") : "100px"
          }
        >
          <CircularProgressbar
            maxValue={100}
            value={completionRate}
            text={`${completionRate}%`}
            strokeWidth={8}
            circleRatio={0.5}
            styles={buildStyles({
              pathColor: "#85ed85",
              width: "10px",
              rotation: 0.75,
              textColor: "black",
              textSize: "12px",
            })}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default CompletionRate;
