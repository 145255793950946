"use client";

import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleSettingsClick = () => {
    navigate("/settings");
  };

  return (
    <Box className="settings-container" px={4} pb={0}>
      <Box
        as="button"
        onClick={handleSettingsClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="full"
        py={3}
        px={4}
        borderRadius="full"
        bg={isHovered ? "blue.500" : "gray.100"}
        color={isHovered ? "white" : "gray.800"}
        fontWeight="semibold"
        transition="all 0.3s ease"
        _hover={{ transform: "scale(1.05)" }}
        _active={{ transform: "scale(0.95)" }}
        _focus={{ boxShadow: "outline" }}
        boxShadow={"1px 1px 10px #ececec"}
        border={"2px solid #ececec"}
      >
        <IoMdSettings style={{ marginRight: "8px", fontSize: "20px" }} />
        <Text>Settings</Text>
      </Box>
    </Box>
  );
};

export default Settings;
