import React, { useEffect, useState } from "react";
import MarkdownRenderer from "./MarkdownRenderer";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
} from "@chakra-ui/react";
import ProjectDropDown from "../Miscellaneous/Dropdowns/ProjectDropDown";
import { findProjectName } from "../Miscellaneous/MyTasks/utils";
import { UserState } from "../../Context/UserProvider";

const AssistantMessage = ({
  message,
  conversation,
  setConversation,
  context,
  setContext,
}) => {
  const { projects } = UserState();
  const [requiredFields, setRequiredFields] = useState([]);
  const [currFieldIdx, setCurrFieldIdx] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmitContext = () => {
    const lastMessage = conversation[conversation.length - 1];
    const projectName = findProjectName(fields.projectId, projects);
    setContext((prevContext) => ({
      ...prevContext,
      projectId: fields.projectId,
      projectName: projectName,
    }));
    setConversation((prevConversation) =>
      lastMessage && lastMessage.type === "assistant"
        ? [
            ...prevConversation.slice(0, prevConversation.length - 1),
            {
              type: "assistant",
              text: `Selected project: ${projectName}`,
            },
          ]
        : [
            ...prevConversation,
            {
              type: "assistant",
              text: `Selected project: ${projectName}`,
            },
          ]
    );
    setIsSubmitted(true);
  };

  const updateFieldData = (value) => {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [requiredFields[currFieldIdx]]: "Need to fill this field.",
      }));
      return;
    }

    if (value !== fields[requiredFields[currFieldIdx]]) {
      setFields((prevFields) => ({
        ...prevFields,
        [requiredFields[currFieldIdx]]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [requiredFields[currFieldIdx]]: "",
    }));
  };

  useEffect(() => {
    setRequiredFields(message?.inputs);
  }, [isSubmitted]);

  useEffect(() => {
    if (requiredFields?.length > 0) {
      setRequiredFields([]);
    }
  }, [conversation]);

  return requiredFields?.length > 0 ? (
    <Box display={"flex"} flexDir={"column"} gap={2}>
      <Heading size={"sm"}>{message.headingText}</Heading>
      <Text>Enter required details.</Text>
      <FormControl isInvalid={errors[requiredFields[currFieldIdx]]}>
        {requiredFields[currFieldIdx] === "projectId" && (
          <ProjectDropDown setProjectId={updateFieldData} />
        )}

        {errors[requiredFields[currFieldIdx]] && (
          <FormErrorMessage>
            {errors[requiredFields[currFieldIdx]]}
          </FormErrorMessage>
        )}
        {/* <Box display={"flex"} justifyContent={"space-between"} mt={2}>
        {currFieldIdx !== 0 ? (
          <Button onClick={() => updateCurrentIdx(-1)} size={"sm"}>
            Prev
          </Button>
        ) : (
          <Button colorScheme="red" size={"sm"} onClick={onCancel}>
            Cancel
          </Button>
        )}
        {currFieldIdx === inputsNeeded.length - 1 ? (
          <Button
            size={"sm"}
            onClick={() => handleSubmitQuery(message.text, fields)}
            isLoading={loading}
            colorScheme="green"
          >
            Generate
          </Button>
        ) : (
          <Button size={"sm"} onClick={() => updateCurrentIdx(1)}>
            Next
          </Button>
        )}
      </Box> */}
        <Button
          size={"sm"}
          width={"full"}
          mt={2}
          colorScheme="blue"
          onClick={handleSubmitContext}
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  ) : (
    <MarkdownRenderer content={message.text || message.onIgnoredText} />
  );
};

export default AssistantMessage;
