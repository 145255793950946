import {
  Box,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  useToast,
  PopoverTrigger,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import UserAvatar from "../UserAvatar/UserAvatar";
import { AuthState } from "../../../Context/AuthProvider";
import { findName } from "../MyTasks/utils";

const AssigneeDropDown = ({
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  assigneeId,
  setAssigneeId,
  projectId,
  reviewerId,
  forUpdation = false,
  isTriggerBgWhite = false,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  // Use external or internal state and functions
  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const {
    projects,
    organizationMembers,
    selectedOrganization,
    updateTaskAssignee,
  } = UserState();
  const { user } = AuthState();
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const [assignee, setAssignee] = useState(null);
  const [assigneeName, setAssigneeName] = useState("");
  const [projectMembers, setProjectMembers] = useState(null);
  const toast = useToast();

  const changeAssignee = async (assigneeId) => {
    await updateTaskAssignee(assigneeId);
  };

  const handleInputBlur = (e) => {
    if (e.relatedTarget === popoverRef.current) {
      // console.log(e.target);
      return;
    }
    onClose();
  };

  const handleUserClick = (e, obj) => {
    e?.stopPropagation();

    setAssignee(obj);
    setAssigneeId(obj.firebaseUserId);
    setQuery(`${obj.firstName + " " + obj.lastName}`);
    setAssigneeName(`${obj.firstName + " " + obj.lastName}`);

    onClose();

    if (forUpdation) {
      changeAssignee(obj.firebaseUserId);
    }
  };

  const setQueryUsers = async () => {
    try {
      if (!projectId || projectId === "") {
        toast({
          title: "You need to select a project first.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        setQuery("");
        return;
      }

      const words = query.split(" ");

      const regexPattern = new RegExp(words.join("|"), "i");

      // Filter data based on regex pattern
      const filteredAssignees = projectMembers.filter(
        (member) =>
          (reviewerId === null || member.firebaseUserId !== reviewerId) &&
          regexPattern.test(
            `${member.firstName} ${member.lastName} ${member.designation}`
          )
      );

      if (
        filteredAssignees &&
        Array.isArray(filteredAssignees) &&
        filteredAssignees.length > 0
      ) {
        setFilteredUsers(filteredAssignees);
      } else {
        setFilteredUsers(null);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      return;
    }

    if (!isOpen && assigneeId) {
      const assignee = findName(assigneeId, organizationMembers);
      setAssigneeName(assignee);
      console.log(assignee);
    }

    if (assigneeName && query !== assigneeName) {
      setAssignee(null);
      setAssigneeId("");
      setAssigneeName("");
    }
    if (query === "") {
      setFilteredUsers(null);
    } else {
      setQueryUsers();
    }
  }, [query]);

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      return;
    }

    if (!projectId && query) {
      setQuery("");
    }

    if (organizationMembers && projectId && projectId !== "") {
      const projMembers = [];
      // for (const project of projects) {
      //   if (project.id === projectId) {
      //     for (const memberId of project.members) {
      //       for (const orgMemberData of organizationMembers) {
      //         if (orgMemberData.firebaseUserId === memberId) {
      //           projMembers.push(orgMemberData);
      //         }
      //       }
      //     }
      //     break;
      //   }
      // }

      for (const member of organizationMembers) {
        if (
          member?.projectIds?.includes(projectId) ||
          member?.projectId === projectId
        ) {
          projMembers.push(member);
        }
      }
      setProjectMembers(projMembers);
    }
  }, [projectId]);

  useEffect(() => {
    if (isOpen && !assigneeId && !query) {
      if (projectMembers && projectMembers.length > 0) {
        setFilteredUsers(() => {
          const membersCopy = [...(projectMembers || [])];
          membersCopy?.splice(4);

          return membersCopy.length > 0 ? membersCopy : null;
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedOrganization.isPersonal) {
      setAssignee(user);
      setAssigneeId(user.firebaseUserId);
      setAssigneeName(`${user.firstName} ${user.lastName}`);
    }

    if (assigneeId) {
      const assignee = organizationMembers?.find(
        (member) => member.firebaseUserId === assigneeId
      );
      if (assignee?.firebaseUserId) {
        setAssignee(assignee);
      }
    }
  }, []);

  return (
    <Popover onOpen={onOpen} isOpen={isOpen} initialFocusRef={inputRef}>
      <PopoverTrigger>
        <Button
          w={"full"}
          bg={isTriggerBgWhite ? "white" : "gray.100"}
          _hover={{ opacity: 0.8 }}
          p={0}
        >
          <InputGroup w={"full"}>
            {assigneeName && !isOpen && (
              <InputLeftElement>
                <Avatar
                  name={assigneeName}
                  size={"xs"}
                  src={assignee?.imageUrl || ""}
                />
              </InputLeftElement>
            )}
            <Input
              w={"full"}
              isDisabled={selectedOrganization.isPersonal ? true : false}
              value={isOpen ? query.toUpperCase() : assigneeName.toUpperCase()}
              placeholder="Assignee"
              color={selectedOrganization.isPersonal ? "blue" : "black"}
              fontWeight={selectedOrganization.isPersonal ? 600 : "auto"}
              onFocus={onOpen}
              onBlur={handleInputBlur}
              onChange={(e) => {
                setQuery(e.target.value);
                if (!isOpen && query) {
                  onOpen();
                }
              }}
              ref={inputRef}
            />
          </InputGroup>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        ref={popoverRef}
        width={"100%"}
        border={"1px solid #ececec"}
        p={0}
        m={0}
        boxShadow={"0px 0px 10px 3px rgba(0, 0, 0, 0.2)"}
      >
        <PopoverBody
          maxHeight={"250px"}
          width={"100%"}
          overflow={"scroll"}
          p={0}
          m={0}
        >
          <div id="filteredmemberswrapper" ref={popoverRef}>
            {filteredUsers?.length > 0 ? (
              filteredUsers.map((obj) => {
                return (
                  <Box
                    key={obj.id}
                    marginY={0}
                    borderRadius={"15px"}
                    _hover={{ cursor: "pointer" }}
                    width={"100% !important"}
                    textAlign={"left"}
                    paddingBottom={0}
                    onClick={(e) => handleUserClick(e, obj)}
                    boxShadow="sm"
                    bg="white"
                  >
                    {obj.id !== user.id ? (
                      <UserAvatar user={obj} me={false} />
                    ) : (
                      <UserAvatar user={obj} isMe={true} />
                    )}
                  </Box>
                );
              })
            ) : (
              <Text color={"black"} padding={3}>
                {query !== "" && query
                  ? "User doesn't exist under this project."
                  : "Enter Assignee's Name."}
              </Text>
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AssigneeDropDown;
