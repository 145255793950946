import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import NewMembersEmailForm from "./NewMembersEmailForm";
import RolesTable from "./RolesTable";
import { LayoutState } from "../../../Context/LayoutProvider";

const InviteMembersModal = ({ isOpen, onClose, organizationMembers }) => {
  const {
    selectedOrganization,
    findMembersDataOnPlatform,
    addMembersToOrganization,
  } = UserState();
  const { screenWidth } = LayoutState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message1: "",
    message2: "",
    message3: "",
    message4: "",
  });
  const [emailsText, setEmailsText] = useState("");
  const [newMembers, setNewMembers] = useState([]);
  const [membersObjects, setMembersObjects] = useState([]);
  const [currentSection, setCurrentSection] = useState({
    name: "",
    submitHandler: () => {},
    buttonText: "",
    elementRef: null,
  });
  const toast = useToast();
  const emailsTextRef = useRef("");
  const rolesTableRef = useRef(null);
  const rolesContentRef = useRef([]);

  useEffect(() => {
    if (emailsTextRef?.current) {
      if (!emailsText) {
        emailsTextRef.current.text = "";
      } else {
        emailsTextRef.current.text = emailsText;
      }
    }
  }, [emailsText]);

  useEffect(() => {
    rolesContentRef.current = newMembers;
  }, [newMembers]);

  const processEmailsText = async () => {
    if (!emailsTextRef?.current?.text) {
      setErrorMessage({
        message1: "",
        message2: "",
        message3: "",
      });
      return;
    }

    if (emailsTextRef.current.text === "") {
      if (newMembers.length < 1) {
        setErrorMessage({
          message1: "Add emails to add roles to.",
          message2: "",
          message3: "",
        });
      } else {
        setErrorMessage({
          message1: "",
          message2: "",
          message3: "",
        });
        makeRolesTableActive();
      }
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Split the emailsText by commas and trim any extra whitespace
    let emailsArray = [];
    if (emailsTextRef.current.text) {
      emailsArray = emailsTextRef.current.text
        .split(",")
        .map((email) => email.trim());
    }

    // Separate valid and invalid emails
    const validEmails = [];
    const invalidEmails = [];

    emailsArray.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        invalidEmails.push(email);
      }
    });

    if (invalidEmails.length > 0) {
      setErrorMessage((obj) => ({
        ...obj,
        message1: `Invalid email addresses: ${invalidEmails.join(", ")}`,
      }));
    } else {
      setErrorMessage({
        message1: "",
        message2: "",
        message3: "",
      });
    }

    if (validEmails.length > 0) {
      const uniqueEmailsSet = new Set(validEmails);
      const uniqueEmails = Array.from(uniqueEmailsSet);

      const presentInOrg = [];
      for (const member of organizationMembers) {
        if (uniqueEmailsSet.has(member.email)) {
          presentInOrg.push(member.email);
        }
      }

      const notPresentInOrg =
        uniqueEmails.filter((email) => !presentInOrg.includes(email)) || [];

      if (presentInOrg.length > 0) {
        setErrorMessage((obj) => {
          const newMessage = `Users already in organization: ${presentInOrg.join(
            ", "
          )}`;
          return { ...obj, message3: newMessage };
        });
      }

      if (notPresentInOrg.length > 0) {
        const membersData =
          (await findMembersDataOnPlatform(notPresentInOrg)) || [];

        if (membersData.length > 0) {
          setNewMembers((addedMembers) => [
            ...(addedMembers || []),
            ...membersData,
          ]);
          makeRolesTableActive();
          setEmailsText("");

          const foundEmailsSet = new Set(
            membersData.map((member) => member.email)
          );
          const emptyEmails = notPresentInOrg.filter(
            (email) => !foundEmailsSet.has(email)
          );

          if (emptyEmails.length > 0) {
            setErrorMessage((obj) => {
              const newMessage = `Users not found for: ${emptyEmails.join(
                ", "
              )}`;
              return { ...obj, message2: newMessage };
            });
          }
        } else {
          setErrorMessage((obj) => {
            const newMessage = `Users not found for: ${notPresentInOrg.join(
              ", "
            )}`;
            return { ...obj, message2: newMessage };
          });
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log(newMembers);
  // }, [newMembers]);

  const addMembers = async () => {
    if (selectedOrganization.isPersonal) {
      toast({
        title: "Restricted.",
        // description: `This is a Personal Organization. You cannot add members.`,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    // console.log(newMembers);

    const requestObjects = rolesContentRef.current.map((member) => ({
      firebaseUserId: member.firebaseUserId,
      role: member.role,
    }));

    // const requestObjects = newMembers.map((member) => ({
    //   firebaseUserId: member.firebaseUserId,
    //   role: member.role,
    // }));

    if (requestObjects.length < 1) {
      toast({ title: "Add atleast 1 member to continue." });
      return;
    }

    try {
      await addMembersToOrganization(requestObjects);
      modifiedOnClose();
    } catch (error) {
      setErrorMessage((obj) => ({ ...obj, message4: "Couldn't add members" }));
    }
  };

  const sections = [
    {
      name: "emailsText",
      submitHandler: () => {
        setTimeout(() => {
          processEmailsText();
        }, 0);
      },
      buttonText: "Add Roles >",
      elementRef: emailsTextRef,
    },
    {
      name: "rolesTable",
      submitHandler: addMembers,
      buttonText: "Invite Members",
      elementsRef: rolesTableRef,
    },
  ];

  const scrollIntoView = (elementRef) => {
    if (elementRef?.current) {
      elementRef?.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
  };

  const modifiedOnClose = () => {
    onClose();
  };

  const makeNewMembersFormActive = () => {
    setCurrentSection(sections[0]);
  };

  const makeRolesTableActive = () => {
    setCurrentSection(sections[1]);
  };

  useEffect(() => {
    if (currentSection?.name) {
      scrollIntoView(currentSection.elementRef);
    }
  }, [currentSection]);

  useEffect(() => {
    if (newMembers.length === 0) {
      makeNewMembersFormActive();
    }
  }, [newMembers]);

  useEffect(() => {
    setCurrentSection({ ...sections[0] });
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={modifiedOnClose}
      isCentered
      initialFocusRef={emailsTextRef}
    >
      <ModalOverlay />
      <ModalContent maxWidth={screenWidth < 700 ? "95%" : "600px"}>
        <ModalHeader>{selectedOrganization.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NewMembersEmailForm
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            emailsText={emailsText}
            elementRef={emailsTextRef}
            setEmailsText={setEmailsText}
            makeNewMembersFormActive={makeNewMembersFormActive}
            makeRolesTableActive={makeRolesTableActive}
          />

          <RolesTable
            newMembers={newMembers}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            setNewMembers={setNewMembers}
            elementRef={rolesTableRef}
            makeRolesTableActive={makeRolesTableActive}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isLoading={loading}
            onClick={currentSection?.submitHandler}
          >
            {currentSection?.buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteMembersModal;
