import { Avatar, Box, Text } from "@chakra-ui/react";
import React from "react";
import ProfilePic from "./ProfilePic";
import Details from "./Details";
import { AuthState } from "../../../Context/AuthProvider";
import { LayoutState } from "../../../Context/LayoutProvider";

const ProfileSetting = () => {
  const { user } = AuthState();
  const { screenWidth } = LayoutState();

  return (
    <Box height={"100%"} flex={1} mb={2} id="profile-setting-page">
      <Box display={"flex"} gap={7} alignItems={"center"}>
        <ProfilePic>
          <Avatar
            src={user.imageUrl}
            name={user.firstName + " " + user.lastName}
            size={"lg"}
            _hover={{ cursor: "pointer" }}
          />
        </ProfilePic>
        <Text
          fontSize={screenWidth > 580 ? "40px" : "30px"}
          textAlign={"center"}
        >
          {(user.firstName + " " + user.lastName).length > 18
            ? (user.firstName + " " + user.lastName).substring(0, 18) + ".."
            : user.firstName + " " + user.lastName}
        </Text>
      </Box>
      <Details />
    </Box>
  );
};

export default ProfileSetting;
