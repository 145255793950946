import React, { useState, useCallback, useRef } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Image,
  Text,
  Box,
  IconButton,
  Flex,
  ModalOverlay,
} from "@chakra-ui/react";
import { ZoomIn, ZoomOut, X } from "lucide-react";
import { LayoutState } from "../../../Context/LayoutProvider";

export default function ExpandedCommentView({ isOpen, onClose, comment }) {
  const { screenWidth, isPhone } = LayoutState();
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleZoomIn = useCallback(() => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  }, []);

  const handleZoomOut = useCallback(() => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  }, []);

  const handleWheel = useCallback((e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY > 0 ? -0.1 : 0.1;
      setScale((prevScale) => Math.max(0.5, Math.min(prevScale + delta, 3)));
    }
  }, []);

  const handleTouchStart = useCallback((e) => {
    if (e.touches.length === 2) {
      e.preventDefault();
    }
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (e.touches.length === 2) {
      e.preventDefault();
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );

      setScale((prevScale) => {
        const newScale = prevScale * (1 + (distance - prevScale) / 200);
        return Math.max(0.5, Math.min(newScale, 3));
      });
    }
  }, []);

  const handleMouseDown = useCallback(
    (e) => {
      const startX = e.clientX - position.x;
      const startY = e.clientY - position.y;

      const handleMouseMove = (e) => {
        setPosition({
          x: e.clientX - startX,
          y: e.clientY - startY,
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [position]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="rgba(0, 0, 0, 0.8)"
        maxH={"70vh"}
        maxW={`${Math.min(0.9 * screenWidth, 900)}px`}
      >
        <ModalHeader color="white">
          {comment?.createdAt?.split("T")[0]}
        </ModalHeader>
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          onWheel={handleWheel}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <Box
            position="relative"
            width="100%"
            height={isPhone ? "100%" : "50vh"}
            overflow="hidden"
            onMouseDown={handleMouseDown}
          >
            <Image
              ref={imageRef}
              src={comment.attachment}
              alt="Expanded comment attachment"
              objectFit="contain"
              width="100%"
              height="100%"
              transform={`scale(${scale}) translate(${position.x}px, ${position.y}px)`}
              transition="transform 0.2s"
            />
          </Box>
          <Flex position="absolute" top="4" right="4" alignItems="center">
            <IconButton
              icon={<ZoomIn />}
              aria-label="Zoom in"
              onClick={handleZoomIn}
              mr={2}
              colorScheme="whiteAlpha"
            />
            <IconButton
              icon={<ZoomOut />}
              aria-label="Zoom out"
              onClick={handleZoomOut}
              mr={2}
              colorScheme="whiteAlpha"
            />
            <IconButton
              icon={<X />}
              aria-label="Close"
              onClick={onClose}
              colorScheme="whiteAlpha"
            />
          </Flex>
        </ModalBody>
        <ModalFooter
          bg="rgba(0, 0, 0, 0.6)"
          borderTopRadius="md"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Text color="white" fontWeight="bold">
            {comment.author.firstName} {comment.author.lastName}
          </Text>
          <Text color="white">{comment.content}</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
