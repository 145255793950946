import { createContext, useContext, useEffect, useState } from "react";
import { AuthState } from "./AuthProvider";
import useOrganizations from "../hooks/useOrganizations";
import { Box, useToast } from "@chakra-ui/react";
import useSetSelectedOrganization from "../hooks/useSetSelectedOrganization";
import useProjects from "../hooks/useProjects";
import useMyTasks from "../hooks/useMyTasks";
import { useLocation, useNavigate } from "react-router-dom";
import useReportTasks from "../hooks/useReportTasks";
import useOrganizationMembers from "../hooks/useOrganizationMembers";
import Loading from "../Components/Miscellaneous/Loading/Loading";
import useTasks from "../hooks/useTasks";
import useAssistant from "../hooks/useAssistant";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const {
    refreshToken,
    auth,
    findMembersDataOnPlatform,
    user,
    leaveOrganization,
    reloadUser,
  } = AuthState();

  const {
    organizations,
    setOrganizations,
    deleteOrganization,
    updateOrganization,
    loading: organizationsLoading,
    error: organizationsError,
    setError: setOrganizationsError,
    reload: reloadOrganizations,
    createOrganization,
    generateDNSVerificationCode,
    verifyDomainTXTRecord,
  } = useOrganizations();

  const {
    selectedOrganization,
    setSelectedOrganization,
    loading: selectedOrganizationLoading,
    roles: selectedOrganizationRoles,
    error: selectedOrganizationError,
    setError: setSelectedOrganizationError,
    selectOrganization,
    uploadOrganizationLogo,
  } = useSetSelectedOrganization(organizations);

  const {
    projects,
    setProjects,
    inviteMembersToProject,
    removeMembersFromProject,
    createNewSprint,
    getSprints,
    getAllSprints,
    startSprint,
    stopSprint,
    deleteSprint,
    updateTaskSprint,
    loading: projectsLoading,
    error: projectsError,
    setError: setProjectsError,
    reload: reloadProjects,
  } = useProjects(selectedOrganization);

  const {
    myTasks,
    setMyTasks,
    loading: myTasksLoading,
    error: myTasksError,
    setError: setMyTasksError,
    reload: reloadMyTasks,
  } = useMyTasks(selectedOrganization);

  const {
    reportTasks,
    setReportTasks,
    loading: reportTasksLoading,
    error: reportTasksError,
    setError: setReportTasksError,
    reload: reloadReportTasks,
  } = useReportTasks(selectedOrganization);

  const {
    organizationMembers,
    setOrganizationMembers,
    getOrganizationMembersData,
    addMembersToOrganization,
    removeMembersFromOrganization,
    updateMemberRole,
    loading: organizationMembersLoading,
    error: organizationMembersError,
    setError: setOrganizationMembersError,
    reload: reloadOrganizationMembers,
  } = useOrganizationMembers(
    selectedOrganization,
    reloadOrganizations,
    projects
  );

  const {
    setTaskIdRef,
    resetTaskIdRef,
    addTaskComment,
    deleteTaskComment,
    deleteTasks,
    updateTaskComment,
    taskComments,
    setTaskComments,
    reloadTaskComments,
    loadMoreTaskComments,
    getProjectTasks,
    createTask,
    uploadCommentAttachment,
    removeTaskAttachment,
    updateTaskSuccessStatus,
    updateTaskPriority,
    updateTaskStatus,
    getMilestonesByProject,
    createMilestone,
    modifyMilestone,
    deleteMilestones,
    updateTask,
    updateTaskMilestone,
    updateTaskReviewer,
    updateTaskAssignee,
    getProjectActiveSprintTasks,
    getTasksByMilestone,
    error: tasksError,
    setError: setTasksError,
  } = useTasks(
    myTasks,
    reportTasks,
    setMyTasks,
    setReportTasks,
    selectedOrganization
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (
      !organizationsLoading &&
      !selectedOrganizationLoading &&
      !projectsLoading &&
      !myTasksLoading &&
      !reportTasksLoading &&
      !organizationMembersLoading
    ) {
      setLoading(false);
    }
  }, [
    organizationsLoading,
    selectedOrganizationLoading,
    projectsLoading,
    myTasksLoading,
    reportTasksLoading,
    organizationMembersLoading,
  ]);

  useEffect(() => {
    if (!refreshToken && !auth?.currentUser) {
      setLoading(false);
    }
  }, [refreshToken, auth]);

  useEffect(() => {
    const resetErrors = () => {
      if (organizationsError) {
        setOrganizationsError(null);
      }
      if (selectedOrganizationError) {
        setSelectedOrganizationError(null);
      }
      if (organizationMembersError) {
        setOrganizationMembersError(null);
      }
      if (projectsError) {
        setProjectsError(null);
      }
      if (myTasksError) {
        setMyTasksError(null);
      }
      if (reportTasksError) {
        setReportTasksError(null);
      }
      if (tasksError) {
        setTasksError(null);
      }
    };

    if (
      organizationsError ||
      selectedOrganizationError ||
      projectsError ||
      myTasksError ||
      reportTasksError ||
      organizationMembersError ||
      tasksError
    ) {
      setError(
        organizationsError ||
          selectedOrganizationError ||
          projectsError ||
          myTasksError ||
          reportTasksError ||
          organizationMembersError ||
          tasksError
      );
      resetErrors();
    }
  }, [
    organizationsError,
    selectedOrganizationError,
    projectsError,
    myTasksError,
    reportTasksError,
    organizationMembersError,
    tasksError,
  ]);

  useEffect(() => {
    if (error) {
      toast({
        description: `${error}`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [error]);

  return (
    <UserContext.Provider
      value={{
        organizations,
        setOrganizations,
        reloadOrganizations,
        createOrganization,
        selectedOrganization,
        setSelectedOrganization,
        selectOrganization,
        projects,
        setProjects,
        inviteMembersToProject,
        removeMembersFromProject,
        reloadProjects,
        myTasks,
        setMyTasks,
        reloadMyTasks,
        reportTasks,
        setReportTasks,
        reloadReportTasks,
        organizationMembers,
        setOrganizationMembers,
        reloadOrganizationMembers,
        addMembersToOrganization,
        uploadOrganizationLogo,
        getProjectTasks,
        getMilestonesByProject,
        createTask,
        setTaskIdRef,
        resetTaskIdRef,
        addTaskComment,
        deleteTaskComment,
        updateTaskComment,
        deleteTasks,
        taskComments,
        setTaskComments,
        reloadTaskComments,
        loadMoreTaskComments,
        uploadCommentAttachment,
        removeTaskAttachment,
        updateTaskSuccessStatus,
        updateTaskPriority,
        updateTaskStatus,
        updateTask,
        createMilestone,
        modifyMilestone,
        deleteMilestones,
        updateTaskMilestone,
        updateTaskAssignee,
        updateTaskReviewer,
        updateMemberRole,
        selectedOrganizationRoles,
        findMembersDataOnPlatform,
        removeMembersFromOrganization,
        getOrganizationMembersData,
        deleteOrganization,
        updateOrganization,
        leaveOrganization,
        createNewSprint,
        getSprints,
        getAllSprints,
        startSprint,
        stopSprint,
        deleteSprint,
        getProjectActiveSprintTasks,
        updateTaskSprint,
        generateDNSVerificationCode,
        verifyDomainTXTRecord,
        getTasksByMilestone,
        reloadUser,
        user,
      }}
    >
      {!loading ? (
        children
      ) : (
        <Box display={"flex"} height={"100vh"} width={"100vw"}>
          <Loading />
        </Box>
      )}
    </UserContext.Provider>
  );
};

export const UserState = () => {
  return useContext(UserContext);
};

export default UserProvider;
