import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  useToast,
  Box,
  FormHelperText,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { UserState } from "../../../Context/UserProvider";
import { useNavigate } from "react-router-dom";
import { AuthState } from "../../../Context/AuthProvider";

const CreateNewOrganizationModal = ({ isOpen, onClose }) => {
  const [orgName, setOrgName] = useState("");
  const [orgDomain, setOrgDomain] = useState("");
  const [pmbotName, setPmbotName] = useState("");
  // const [isVerified, setIsVerified] = useState(false);
  const {
    selectOrganization,
    setOrganizations,
    setOrganizationMembers,
    createOrganization,
  } = UserState();

  const toast = useToast();
  const navigate = useNavigate();

  const closeCreateNewModal = (e) => {
    setOrgDomain("");
    setOrgName("");
    onClose();
  };

  const isValidDomain = () => {
    const domainRegex = /^(?!\-)[A-Za-z0-9\-]{1,63}(?<!\-)\.[A-Za-z]{2,}$/i;

    return domainRegex.test(orgDomain);
  };

  // const verifyOrganizationClicked = async () => {};

  const handleCreateNewOrgClicked = async (e) => {
    if (!orgName || !orgDomain) {
      toast({
        title: "Insufficient data",
        description: `Both organization name and domain are required fields to create a new organization.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else if (!isValidDomain()) {
      toast({
        title: "Invalid domain.",
        description: orgDomain,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const newOrganization = await createOrganization(false, null, {
        orgName,
        orgDomain,
        pmbotName,
      });

      if (!newOrganization) {
        throw new Error("error in creating organization");
      }
      // setOrganizationMembers(null);
      // setSelectedOrganization({ ...newOrganization });
      // localStorage.setItem("selected_organization", newOrganization.id);
      selectOrganization(newOrganization.id);
      setOrganizations((orgs) =>
        orgs ? [...orgs, { ...newOrganization }] : [{ ...newOrganization }]
      );

      // navigate(`/organizations/${newOrganization.id}`);
      toast({
        title: "Organization created successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      closeCreateNewModal();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeCreateNewModal} isCentered={true}>
      <ModalOverlay />
      <ModalContent margin={"20px"}>
        <ModalHeader display={"flex"} alignItems={"center"}>
          <ArrowBackIcon
            onClick={closeCreateNewModal}
            _hover={{ cursor: "pointer" }}
            marginRight={2}
          />
          <Box flex={1} textAlign={"center"}>
            Create New Organization
          </Box>
        </ModalHeader>

        <ModalBody>
          <VStack gap={3}>
            <FormControl>
              <FormLabel>Organization Name</FormLabel>
              <Input
                value={orgName}
                type="text"
                onChange={(e) => setOrgName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Organization Domain</FormLabel>
              <Input
                value={orgDomain}
                type="text"
                onChange={(e) => setOrgDomain(e.target.value)}
              />
              <FormHelperText>
                Example: hello.com, pmbot.app, lumiorc.com
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>PM-Bot Name</FormLabel>
              <Input
                value={pmbotName}
                type="text"
                onChange={(e) => setPmbotName(e.target.value)}
              />
              <FormHelperText>
                You can choose a custom name for your Orgnaization's PM-Bot.
              </FormHelperText>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter display={"flex"} flexDir={"column"}>
          <hr style={{ width: "95%" }} />
          <Button
            marginTop={"10px"}
            onClick={handleCreateNewOrgClicked}
            // onClick={
            //   isVerified ? handleCreateNewOrgClicked : verifyOrganizationClicked
            // }
            width={"80%"}
            // colorScheme={isVerified ? "green" : "blue"}
            colorScheme="green"
          >
            {/* {isVerified ? "Create" : "Verify"} */}
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateNewOrganizationModal;
