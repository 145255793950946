import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Flex,
  useColorModeValue,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import CreateMilestoneModal from "./CreateMilestoneModal";
import ViewMilestoneModal from "./ViewMilestoneModal";
import { ChevronDown } from "lucide-react";
import ViewTaskModal from "../../Miscellaneous/MyTasks/ViewTaskModal";

const MilestoneTasksDropDown = ({ milestone }) => {
  const { getTasksByMilestone } = UserState();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [viewingTask, setViewingTask] = useState(null);
  const {
    isOpen: isViewTaskModalOpen,
    onOpen: openViewTaskModal,
    onClose: closeViewTaskModal,
  } = useDisclosure();
  const {
    isOpen: isMilestoneTaskModalOpen,
    onOpen: openMilestoneTaskModal,
    onClose: closeMilestoneTaskModal,
  } = useDisclosure();

  const modifiedCloseViewTaskModal = () => {
    setViewingTask(null);
    closeViewTaskModal();
  };

  const handleTaskClick = (task) => {
    setViewingTask(task);
    openViewTaskModal();
    closeMilestoneTaskModal();
  };

  const getMilestoneTasks = async () => {
    try {
      setLoading(true);
      const response = await getTasksByMilestone(milestone.id);
      setTasks(response.tasks);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isMilestoneTaskModalOpen) {
      return;
    }

    try {
      getMilestoneTasks();
    } catch (error) {
      console.log(error);
    }
  }, [isMilestoneTaskModalOpen]);

  return (
    <>
      {isViewTaskModalOpen && viewingTask && (
        <ViewTaskModal
          isOpen={isViewTaskModalOpen}
          onClose={modifiedCloseViewTaskModal}
          task={viewingTask}
        />
      )}
      <Popover
        isOpen={isMilestoneTaskModalOpen}
        onClose={closeMilestoneTaskModal}
        onOpen={openMilestoneTaskModal}
      >
        <PopoverTrigger>
          <Button
            size="sm"
            variant="link"
            rightIcon={<ChevronDown size={"1em"} />}
          >
            {milestone.tasks.length} tasks
          </Button>
        </PopoverTrigger>
        <PopoverContent width="300px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold">{`Tasks ${
            milestone?.doneTasks ? `(${milestone.doneTasks} completed)` : ``
          }`}</PopoverHeader>
          <PopoverBody maxHeight="300px" overflowY="auto">
            {loading ? (
              <Spinner size={"sm"} />
            ) : tasks && Array.isArray(tasks) && tasks.length > 0 ? (
              <VStack align="stretch" spacing={1}>
                {tasks.map((task, index) => (
                  <Box
                    onClick={() => handleTaskClick(task)}
                    key={index}
                    p={2}
                    borderRadius="md"
                    bg={task.status === "done" ? "green.100" : "gray.200"}
                    _hover={{
                      bg: task.status === "done" ? "green.200" : "gray.100",
                    }}
                  >
                    <Text
                      fontSize="sm"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {task.title}
                    </Text>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Text>No tasks found</Text>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

const Milestones = ({ viewingProject }) => {
  const { getMilestonesByProject, deleteMilestones } = UserState();
  const [loading, setLoading] = useState(true);
  const [milestones, setMilestones] = useState([]);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [clickedMilestone, setClickedMilestone] = useState(null);
  const [resetValues, setResetValues] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const {
    isOpen: isViewMilestoneModalOpen,
    onOpen: openViewMilestoneModal,
    onClose: closeViewMilestoneModal,
  } = useDisclosure();
  const {
    isOpen: isCreateMilestoneOpen,
    onOpen: openCreateMilestoneModal,
    onClose: closeCreateMilestoneModal,
  } = useDisclosure();
  const toast = useToast();

  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("purple.50", "purple.900");
  const textColor = useColorModeValue("gray.800", "white");
  const headerBgColor = useColorModeValue("gray.50", "gray.700");

  const removeMilestones = async () => {
    const milestoneIds = selectedMilestones.map(
      (selectedMilestone) => selectedMilestone.id
    );

    const response = await deleteMilestones(milestoneIds);

    if (response === "error") {
      toast({
        title: "Error deleting Milestones",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    const filteredMilestones = milestones?.filter(
      (milestone) =>
        !selectedMilestones.some(
          (selectedMilestone) => selectedMilestone.id === milestone.id
        )
    );

    setMilestones(filteredMilestones || []);

    setSelectedMilestones([]);
  };

  const isSelected = (milestone) =>
    selectedMilestones.some(
      (selectedMilestone) => milestone?.id === selectedMilestone?.id
    );

  const selectUnselectAll = () => {
    setSelectedMilestones(allSelected ? [] : [...(milestones || [])]);
  };

  useEffect(() => {
    setAllSelected(
      selectedMilestones?.length === milestones?.length &&
        milestones?.length > 0
    );
  }, [selectedMilestones, milestones]);

  useEffect(() => {
    if (resetValues) {
      setClickedMilestone(null);
      setResetValues(false);
    }
  }, [resetValues]);

  useEffect(() => {
    if (!viewingProject?.id) {
      return;
    }

    const fetchMilestones = async () => {
      const projectMilestones = await getMilestonesByProject(viewingProject.id);
      setMilestones(projectMilestones || []);
      setLoading(false);

      const projectMilestonesWithDoneTasks = await getMilestonesByProject(
        viewingProject.id,
        true
      );
      setMilestones(projectMilestonesWithDoneTasks || []);
    };

    fetchMilestones();
  }, [viewingProject]);

  const renderProgressBar = (milestone) => {
    let completedPercentage = 0;

    if (milestone.tasks.length === 0) {
      completedPercentage = 100;
    } else {
      completedPercentage =
        (milestone.doneTasks / milestone.tasks.length) * 100;
    }

    // Define color stops
    const colorStops = [
      { percent: 0, color: [255, 0, 0] }, // Red
      { percent: 33, color: [255, 165, 0] }, // Orange
      { percent: 66, color: [255, 255, 0] }, // Yellow
      { percent: 100, color: [0, 255, 0] }, // Green
    ];

    // Function to interpolate between two colors
    const interpolateColor = (color1, color2, factor) => {
      const result = color1?.slice();
      for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
      }
      return result;
    };

    // Find the appropriate color range and interpolate
    let startColor, endColor, rangeFactor;
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (completedPercentage <= colorStops[i + 1].percent) {
        startColor = colorStops[i].color;
        endColor = colorStops[i + 1].color;
        rangeFactor =
          (completedPercentage - colorStops[i].percent) /
          (colorStops[i + 1].percent - colorStops[i].percent);
        break;
      }
    }

    let barColor;
    if (startColor && endColor && rangeFactor) {
      const interpolatedColor = interpolateColor(
        startColor,
        endColor,
        rangeFactor
      );
      barColor = `rgb(${interpolatedColor.join(",")})`;
    }

    return (
      <Box w="100%" h="2px" bg="gray.200" borderRadius="full" overflow="hidden">
        <Box
          w={`${completedPercentage}%`}
          h="100%"
          bg={milestone?.tasks?.length === 0 ? "gray.200" : barColor}
          transition="width 0.3s ease-in-out, background-color 0.3s ease-in-out"
        />
      </Box>
    );
  };
  return (
    <Box
      bg={bgColor}
      px={1}
      pt={0}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {isCreateMilestoneOpen && (
        <CreateMilestoneModal
          isOpen={isCreateMilestoneOpen}
          onClose={closeCreateMilestoneModal}
          project={viewingProject}
          setMilestones={setMilestones}
        />
      )}
      {isViewMilestoneModalOpen && (
        <ViewMilestoneModal
          isOpen={isViewMilestoneModalOpen}
          onOpen={openViewMilestoneModal}
          onClose={closeViewMilestoneModal}
          milestone={clickedMilestone}
          setMilestone={setClickedMilestone}
          setMilestones={setMilestones}
        />
      )}
      <Flex justifyContent="right" alignItems="center" mb={2}>
        {selectedMilestones.length > 0 ? (
          <Button
            leftIcon={<DeleteIcon />}
            bg="red.300"
            color="white"
            size="sm"
            onClick={removeMilestones}
            _hover={{ bg: "red" }}
          >
            Remove Milestones
          </Button>
        ) : (
          <Button
            leftIcon={<AddIcon />}
            bg="#a67db7"
            color="white"
            size="sm"
            onClick={openCreateMilestoneModal}
            _hover={{ bg: "#956ba6" }}
          >
            Add Milestones
          </Button>
        )}
      </Flex>
      {!loading ? (
        milestones?.length > 0 ? (
          <TableContainer overflowY="auto" flex={1} height={"100%"}>
            <Table variant="simple">
              <Thead bg={headerBgColor} position="sticky" top={0} zIndex={1}>
                <Tr>
                  <Th width="40px">
                    <Checkbox
                      isChecked={allSelected}
                      onChange={selectUnselectAll}
                      colorScheme="purple"
                    />
                  </Th>
                  <Th>Title</Th>
                  <Th>Tasks</Th>
                  <Th>Created At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {milestones?.map((milestone) => (
                  <React.Fragment key={milestone.id}>
                    <Tr
                      onClick={() => setClickedMilestone(milestone)}
                      bg={
                        isSelected(milestone) ? selectedBgColor : "transparent"
                      }
                      _hover={{ bg: hoverBgColor, cursor: "pointer" }}
                      transition="background-color 0.2s"
                    >
                      <Td>
                        <Checkbox
                          isChecked={isSelected(milestone)}
                          colorScheme="purple"
                          onChange={(e) => {
                            e.stopPropagation();
                            setSelectedMilestones(
                              isSelected(milestone)
                                ? selectedMilestones.filter(
                                    (m) => m.id !== milestone.id
                                  )
                                : [...selectedMilestones, milestone]
                            );
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Td>
                      <Td onClick={openViewMilestoneModal}>
                        {milestone.title}
                      </Td>
                      <Td>
                        <MilestoneTasksDropDown milestone={milestone} />
                      </Td>
                      <Td onClick={openViewMilestoneModal}>
                        {milestone.createdAt.split(" ")[0]}
                      </Td>
                    </Tr>
                    <Tr p={0} m={0}>
                      <Td colSpan={4} p={0} m={0}>
                        {renderProgressBar(milestone)}
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Text textAlign="center" color={textColor} fontSize="lg" mt={8}>
            No Milestones under this Project
          </Text>
        )
      ) : (
        <Flex
          alignItems="center"
          justifyContent={"center"}
          height={"300px"}
          width={"100%"}
        >
          <Spinner />
        </Flex>
      )}
    </Box>
  );
};

export default Milestones;
