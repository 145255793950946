"use client";

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaInstagram, FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLocationOutline, IoMail } from "react-icons/io5";
import { LayoutState } from "../../Context/LayoutProvider";
import lumiorc from "../../assets/lumiorc.png";

const Footer = () => {
  const { screenWidth } = LayoutState();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const iconColor = useColorModeValue("gray.600", "gray.400");
  const iconHoverColor = "blue.500";

  const socialLinks = [
    { icon: FaInstagram, href: "https://www.instagram.com/lumiorc" },
    { icon: FaXTwitter, href: "https://www.x.com/lumiorccs" },
    { icon: FaLinkedinIn, href: "https://www.linkedin.com/company/lumiorc" },
  ];

  return (
    <Box
      as="footer"
      bg={bgColor}
      color={textColor}
      borderTop="1px"
      borderColor={borderColor}
    >
      <Container maxW="6xl" py={10}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
          <VStack align="start" spacing={6}>
            <Image src={lumiorc} alt="Lumiorc Logo" maxW="200px" />
            <Text fontSize="sm" maxW="400px">
              Experience seamless business integrations with DevOps, Cloud and
              Agile technologies.
            </Text>
            <HStack spacing={4}>
              {socialLinks.map((item, index) => (
                <Link key={index} href={item.href} isExternal>
                  <Icon
                    as={item.icon}
                    boxSize={6}
                    color={iconColor}
                    _hover={{ color: iconHoverColor }}
                  />
                </Link>
              ))}
            </HStack>
          </VStack>

          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={8}
            justify="space-between"
          >
            <VStack align="start" spacing={3}>
              <Heading as="h4" size="md">
                Company
              </Heading>
              <Button
                as={Link}
                href="https://www.lumiorc.com/careers"
                variant="link"
                color={textColor}
              >
                Careers
              </Button>
              <Button
                as={Link}
                href="https://www.lumiorc.com/vision"
                variant="link"
                color={textColor}
              >
                Vision
              </Button>
            </VStack>

            <VStack align="start" spacing={3}>
              <Heading as="h4" size="md">
                Contact Us
              </Heading>
              <HStack>
                <Icon as={IoLocationOutline} />
                <Text fontSize="sm">Business Bay, Dubai, UAE</Text>
              </HStack>
              <Text fontSize="sm" pl={6}>
                New Delhi, India
              </Text>
              <Text fontSize="sm" pl={6}>
                Toronto, Ontario, Canada
              </Text>
              <Button
                as={Link}
                href="mailto:info@lumiorc.com"
                leftIcon={<IoMail />}
                variant="link"
                color={textColor}
              >
                info@lumiorc.com
              </Button>
              <Button
                as={Link}
                href="tel:+971544941974"
                leftIcon={<FaPhoneAlt />}
                variant="link"
                color={textColor}
              >
                +971 544 941 974
              </Button>
            </VStack>
          </Stack>
        </Grid>
      </Container>

      <Box borderTop="1px" borderColor={borderColor}>
        <Container maxW="6xl" py={4}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
          >
            <Text fontSize="sm">&copy; 2024 Lumiorc. All rights reserved.</Text>
            <HStack spacing={4} mt={{ base: 4, md: 0 }}>
              <Button
                as={Link}
                href="https://www.lumiorc.com/terms-and-conditions"
                variant="link"
                fontSize="sm"
                color={textColor}
              >
                Terms & Conditions
              </Button>
              <Button
                as={Link}
                href="https://www.lumiorc.com/privacy-policy"
                variant="link"
                fontSize="sm"
                color={textColor}
              >
                Privacy Policy
              </Button>
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
