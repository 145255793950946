import React from "react";
import { Flex, Text, Icon, Button } from "@chakra-ui/react";
import { FaRobot } from "react-icons/fa";
import { RefreshCwIcon } from "lucide-react";

const ChatbotHeader = ({ pmbotName, refreshChat }) => {
  return (
    <Flex
      align={"center"}
      justifyContent={"space-between"}
      p={3}
      borderBottom="1px solid"
      borderColor="gray.200"
      id="chatbot-header"
      bg={"gray.100"}
      width={"100%"}
    >
      <Flex align="center">
        <Icon as={FaRobot} boxSize={6} color="blue.500" mr={2} />
        <Text fontSize="lg" fontWeight="bold">
          {pmbotName}
        </Text>
      </Flex>
      <Button
        colorScheme="blue"
        size={"sm"}
        onClick={refreshChat}
        leftIcon={<RefreshCwIcon size={"1rem"} />}
      >
        Refresh Chat
      </Button>
    </Flex>
  );
};

export default ChatbotHeader;
