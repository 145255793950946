import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  VStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { taskStatusStyleLight } from "../MyTasks/styles";

const StatusDropDown = ({
  onClose: externalOnClose,
  onOpen: externalOnOpen,
  isOpen: externalIsOpen,
  status,
  setStatus,
  children,
}) => {
  const {
    isOpen: internalIsOpen,
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  } = useDisclosure();

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalOnOpen ?? internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const statusObjs = [
    { name: "inProgress", value: "In Progress", key: 1 },
    { name: "blocked", value: "Blocked", key: 2 },
    { name: "review", value: "Review", key: 3 },
    { name: "toDo", value: "To Do", key: 4 },
    { name: "done", value: "Done", key: 5 },
  ];

  const handleStatusClick = (e, obj) => {
    e.stopPropagation();
    setStatus(obj.name);
    onClose();
  };

  return (
    <Popover onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent width="auto" boxShadow="xl" borderRadius="md" bg="white">
        <PopoverBody p={1}>
          <VStack spacing={1} align="stretch">
            {statusObjs
              ?.filter((obj) => obj.name !== status)
              ?.map((obj) => (
                <Box
                  key={obj.key}
                  px={3}
                  py={2}
                  borderRadius="md"
                  width={"117px"}
                  bg={taskStatusStyleLight[obj.name]}
                  color="black"
                  cursor="pointer"
                  transition="all 0.2s"
                  _hover={{ opacity: 0.8 }}
                  onClick={(e) => handleStatusClick(e, obj)}
                >
                  <Text width={"100%"}>{obj.value}</Text>
                </Box>
              ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default StatusDropDown;
