import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { LayoutState } from "../../../../Context/LayoutProvider";
import NewMembersEmailForm from "./NewMembersEmailForm";
import MembersDropDown from "../../../Miscellaneous/Dropdowns/MembersDropDown";
import { UserState } from "../../../../Context/UserProvider";
import { DeleteIcon } from "@chakra-ui/icons";

const InviteMemberModal = ({ isOpen, onClose, viewingProject }) => {
  const { isPhone } = LayoutState();
  const {
    inviteMembersToProject,
    projects,
    getOrganizationMembersData,
    selectedOrganization,
  } = UserState();
  const [members, setMembers] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [emailsText, setEmailsText] = useState("");
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [currentSection, setCurrentSection] = useState({
    name: "",
    submitHandler: () => {},
    buttonText: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    message1: "",
    message2: "",
    message3: "",
    message4: "",
  });
  const [loadingMembersData, setLoadingMembersData] = useState(true);
  const emailsTextRef = useRef("");
  const membersDataRef = useRef([]);

  const fetchAllOrganizationMembersData = async () => {
    try {
      const allMembersData = await getOrganizationMembersData(false);
      setOrganizationMembers(allMembersData);
    } catch (error) {
      console.error(`Couldn't fetch all orgnaization members data`, error);
    } finally {
      setLoadingMembersData(false);
    }
  };

  const addMembers = async () => {
    await inviteMembersToProject(membersDataRef.current, viewingProject.id);
    onClose();
  };

  const viewMembersList = () => {
    while (loadingMembersData);
    processEmailsText();

    const membersSet = new Set(membersDataRef.current);
    const uniqueMembers = Array.from(membersSet);

    setMembers(uniqueMembers);
    membersDataRef.current = uniqueMembers;

    if (membersDataRef.current.length === 0) {
      setErrorMessage({
        message1: "Add members to view added members list.",
        message2: "",
        message3: "",
        message4: "",
      });
      return;
    }
    setCurrentSection(sections[1]);
  };

  const processEmailsText = () => {
    if (!emailsTextRef?.current?.text) {
      setErrorMessage({
        message1: "",
        message2: "",
        message3: "",
      });
      return;
    }

    if (emailsTextRef.current.text === "") {
      if (membersDataRef.current.length < 1) {
        setErrorMessage({
          message1: "Add members to view added members list.",
          message2: "",
          message3: "",
        });
      } else {
        setErrorMessage({
          message1: "",
          message2: "",
          message3: "",
        });
      }
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Split the emailsTextRef.current.text by commas and trim any extra whitespace
    let emailsArray = [];
    if (emailsTextRef.current.text) {
      emailsArray = emailsTextRef.current.text
        .split(",")
        .map((email) => email.trim());
    }

    // Separate valid and invalid emails
    const validEmails = [];
    const invalidEmails = [];

    emailsArray.forEach((email) => {
      if (emailRegex.test(email)) {
        validEmails.push(email);
      } else {
        invalidEmails.push(email);
      }
    });

    if (invalidEmails.length > 0) {
      setErrorMessage((obj) => ({
        ...obj,
        message1: `Invalid email addresses: ${invalidEmails.join(", ")}`,
      }));
    } else {
      setErrorMessage({
        message1: "",
        message2: "",
        message3: "",
      });
    }

    if (validEmails.length > 0) {
      const uniqueEmailsSet = new Set(validEmails);
      const uniqueEmails = Array.from(uniqueEmailsSet);

      const presentInProject = [];
      for (const member of projectMembers) {
        if (uniqueEmailsSet.has(member.email)) {
          presentInProject.push(member.email);
        }
      }

      const notPresentInProject =
        uniqueEmails.filter((email) => !presentInProject.includes(email)) || [];

      if (presentInProject.length > 0) {
        setErrorMessage((obj) => {
          const newMessage = `Users already in organization: ${presentInProject.join(
            ", "
          )}`;
          return { ...obj, message3: newMessage };
        });
      }

      if (notPresentInProject.length > 0) {
        const membersData = organizationMembers.filter((member) => {
          return notPresentInProject.find(
            (memberEmail) => member.email === memberEmail
          );
        });

        if (membersData.length > 0) {
          const newMembers = [...membersData, ...(members || [])];
          setMembers(newMembers);
          membersDataRef.current = newMembers;
          setEmailsText("");

          const foundEmailsSet = new Set(
            membersData.map((member) => member.email)
          );

          const foundEmails = Array.from(foundEmailsSet);

          const emptyEmails = notPresentInProject.filter(
            (email) => !foundEmailsSet.has(email)
          );

          if (emptyEmails.length > 0) {
            setErrorMessage((obj) => {
              const newMessage = `Users not found for: ${emptyEmails.join(
                ", "
              )}`;
              return { ...obj, message2: newMessage };
            });
          }
        } else {
          setErrorMessage((obj) => {
            const newMessage = `Users not found for: ${notPresentInProject.join(
              ", "
            )}`;
            return { ...obj, message2: newMessage };
          });
        }
      }
    }
  };

  const sections = [
    {
      name: "selectMembers",
      submitHandler: viewMembersList,
      buttonText: "View Members List >",
    },
    {
      name: "addMembers",
      submitHandler: addMembers,
      buttonText: "Add Members",
    },
  ];

  useEffect(() => {
    membersDataRef.current = members;
  }, [members]);

  useEffect(() => {
    if (currentSection.name === "addMembers" && members.length === 0) {
      setCurrentSection(sections[0]);
    }
  }, [currentSection]);

  useEffect(() => {
    setProjectMembers(() => {
      const objs = [];
      const projectId = viewingProject?.id;

      for (const member of organizationMembers) {
        if (
          member?.projectIds?.includes(projectId) ||
          member?.projectId === projectId
        ) {
          objs.push(member);
        }
      }
      return [...objs];
    });

    setCurrentSection(sections[0]);
  }, [projects, viewingProject, organizationMembers]);

  useEffect(() => {
    if (selectedOrganization && viewingProject) {
      fetchAllOrganizationMembersData();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={isPhone ? "95%" : "500px"}
        margin="10px"
        borderRadius="xl"
        boxShadow="xl"
      >
        <ModalHeader
          bg="gray.50"
          borderTopRadius="xl"
          borderBottom="1px"
          borderColor="gray.200"
          py={4}
        >
          <Text fontSize="xl" fontWeight="bold">
            Add Members
          </Text>
        </ModalHeader>
        <ModalCloseButton zIndex={2} />
        {currentSection?.name === "selectMembers" ? (
          <ModalBody py={6} maxH={"60%"} overflow={"scroll"}>
            <MembersDropDown
              projectId={viewingProject.id}
              members={members}
              setMembers={setMembers}
              membersToPickFrom={organizationMembers}
              inputPlaceholderText="Search Organization Members"
              shouldSelectMultipleMembers={true}
            />
            <Box display={"block"} position={"relative"} my={6}>
              <Divider
                mx={"auto"}
                border={"1px solid"}
                borderColor={"gray.400"}
                width={"90%"}
                alignSelf={"left"}
              />
              <Text
                as="span"
                position="absolute"
                top={-3}
                left="50%"
                transform="translateX(-55%)"
                fontSize="md"
                bg="white"
                px={2}
              >
                OR
              </Text>
            </Box>
            <NewMembersEmailForm
              emailsText={emailsText}
              setEmailsText={setEmailsText}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              emailsTextRef={emailsTextRef}
            />
          </ModalBody>
        ) : (
          <ModalBody
            py={6}
            maxH={"60%"}
            overflow={"scroll"}
            minHeight={"400px"}
            borderRadius={"md"}
            px={0}
          >
            <Text fontSize={"lg"} fontWeight={"700"} p={3}>
              Verify Members to Add
            </Text>
            <TableContainer
              mx={1}
              border={"1px solid black"}
              overflowY={"scroll"}
            >
              <Table size="sm">
                <Tbody>
                  {members.map((member) => (
                    <Tr key={member.firebaseUserId} display={"flex"}>
                      <Td borderRight={"1px solid black"} flex={1}>
                        {`${member.firstName} ${member.lastName}`}
                      </Td>
                      <Td
                        borderRight={"1px solid black"}
                        borderBottom={"1px solid #EDF2F7"}
                      >
                        {member.email}
                      </Td>
                      <Td>
                        <DeleteIcon
                          onClick={() => {
                            const newMembers = members.filter(
                              (obj) =>
                                obj.firebaseUserId !== member.firebaseUserId
                            );

                            setMembers([...newMembers]);

                            if (newMembers.length === 0) {
                              setCurrentSection(sections[0]);
                            }
                          }}
                          _hover={{ cursor: "pointer" }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        )}

        <ModalFooter
          borderTop="1px"
          borderColor="gray.200"
          py={4}
          display={"flex"}
          justifyContent={"space-between"}
        >
          {currentSection.name === "addMembers" && (
            <Button
              size={"sm"}
              onClick={() => setCurrentSection(sections[0])}
              borderRadius={"md"}
              px={3}
            >
              {"<"} Select More
            </Button>
          )}

          {/* empty box for spacing */}
          <Box></Box>

          <Button
            colorScheme="blue"
            onClick={currentSection?.submitHandler}
            borderRadius="md"
            px={3}
            size={"sm"}
          >
            {currentSection.buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteMemberModal;
