import { Box, CardFooter, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthState } from "../../../Context/AuthProvider";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";

const TotalTasks = () => {
  const { user } = AuthState();
  const [totalTasks, setTotalTasks] = useState(0);

  useEffect(() => {
    if (user?.totalTasks >= 0) {
      setTotalTasks(user?.totalTasks);
      return;
    }
    setTotalTasks(0);
  }, [user]);

  return (
    <Card borderRadius={"25px"} boxShadow={"none"}>
      <CardHeader>
        <Heading size="md">Total Tasks</Heading>
      </CardHeader>

      <CardBody
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box flex={1}>
          <Text fontSize={"40px"} fontWeight={700}>
            {totalTasks}
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default TotalTasks;
